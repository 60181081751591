<template>
  <div id="app">
    <div class="nav" v-if="path!='/signin'">
      <div class="menu-link" v-on:click="linkSwitch()">
        <img src="@/assets/renewal/Home.png" class="menu_image">
      </div>
      <div class="page-name" v-on:click="reloadPage()">
        <img src="@/assets/renewal/NGR_Icon_W.png" class="icon_image">
        <img src="@/assets/renewal/NGR_Logo_W.png" class="logo_image">
      </div>
    </div>
    <div class="float-clear"></div>
    <slide left :burgerIcon="false" :crossIcon="false" :isOpen="menu_active" disableOutsideClick :width="getWindowWidth()">
      <div class="navlink" v-on:click="linkClose()">メニューを閉じる<img src="@/assets/renewal/menu_close.png"></div>
      <div class="navlink" v-on:click="reloadPage();linkSwitch()">ホーム</div>
      <div class="navlink" v-on:click="pagePushMulti(['/battle']);linkSwitch()">スコア入力</div>
      <div class="navlink" v-on:click="pagePushMulti(['/results']);linkSwitch()">戦績&ハンデ確認</div>
      <div class="navlink" v-on:click="pagePushMulti(['/friend_list']);linkSwitch()">フレンド</div>
      <div class="navlink" v-on:click="pagePushMulti(['/profile']);linkSwitch()">ユーザー設定</div>
      <div class="navlink" v-on:click="pagePushMulti(['/signin']);linkSwitch()">ログアウト</div>
    </slide>
    <div v-on:click="linkClose()" class="main">
      <router-view />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'

import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API
import { getNickName } from "@/graphql/queries"

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

export default {
  name: 'Home',
  data () {
    return {
      path: "/signin",
      nickname: "",
      pagename: "",
      menu_active: false,
    }
  },
  watch: {
    '$route': async function (to, from) {
      this.getNickName();
      this.path = this.$route.path;
      switch(this.path) {
        case "/signin":
          this.pagename = "サインイン/アウト";
          break;
        case "/":
          this.pagename = "ホーム";
          break;
        case "/battle":
          this.pagename = "スコア入力";
          break;
        case "/results":
          this.pagename = "戦績";
          break;
        case "/friend_Search":
          this.pagename = "フレンド検索";
          break;
        case "/friend_List":
          this.pagename = "フレンドリスト";
          break;
        case "/requests":
          this.pagename = "フレンド申請";
          break;
        case "/s_requests":
          this.pagename = "入力中スコア";
          break;
        case "/profile":
          this.pagename = "ユーザー情報";
          break;
      }
    }
  },
  mounted: function () {
    document.title = "Nifty Golf Recorder";
    if (store.state.path.option && !this.$route.query.option && 
        store.state.path.uid && !this.$route.query.uid) {
      const path = {
        option: store.state.path.option,
        uid: store.state.path.uid
      }
      this.pageChange("/signin", path)
    }
  },
  methods: {
    getNickName: async function () {
      if (store.state.user) {
        const nN = await API.graphql(graphqlOperation(getNickName, {HashKey: store.state.user.username, RangeKey: "MST"}));
        if (nN.data.getGolfHandicapTableRenewal.NickName) {
          this.nickname = nN.data.getGolfHandicapTableRenewal.NickName;
        }
        else {
          this.nickname = "名無し";
        }
        store.commit('setMailAdress', nN.data.getGolfHandicapTableRenewal.MailAddress);
        store.commit('setUserDB', nN.data.getGolfHandicapTableRenewal);
      }
      else {
        this.nickname = "";
      }
      store.commit('setNickname', this.nickname);
    },
    reloadPage: async function () {
      await router.push({path: "/ "});
      await router.push({path: "/"});
    },
    pagePushMulti: async function (pagename) {
      for (let i = 0; i < pagename.length; i++)
      {
        await router.push({path: pagename[i]});
      }
    },
    async pageChange(path, query) {
      if (query) {
        await router.push({path: "/ "});
        store.commit('setPath', query);
        await router.push({path: path, query: query});
      }
      else {
        await router.push({path: "/ "});
        store.commit('setPath', null);
        await router.push({path: path, query: null});
      }
    },
    linkSwitch: function() {
      this.menu_active = !this.menu_active;
    },
    linkClose: function () {
      this.menu_active = false;
    },
    getWindowWidth() {
      return (document.body.clientWidth * 0.9).toString(10);
    }
  },
  async beforeCreate() {	// サインイン済みかどうか確認
    if (location.href.indexOf('?') != -1) {
      const regexp = (/[?&=]/g);
      const hrefArray = location.href.split(regexp);
      var queries = {};
      for (let i = 1; i < hrefArray.length; i+=2) {
        queries[hrefArray[i]] = hrefArray[i+1];
      }
      console.log("path = ", queries);
      store.commit('setPath', queries);
    }
    try {
      console.log("store.state.user = ", store.state.user);
      if (!store.state.user) {
        const user = await Auth.currentAuthenticatedUser()
        store.commit('setUser', user);
      }
      console.log("store.state.user = ", store.state.user);
    } catch (err) {
      console.log(err);
    }
    console.log("store.state.path = ", store.state.path)
  },
}
</script>

<style>
html {
  background-color: #FAFAFA;
}
body {
  margin: 0;
  height: 100%;
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FAFAFA;
  background-color: #FAFAFA;
  border: solid 1px #FAFAFA;
  height: 100%;
  width: 100%;
}
.main {
  height: 100%;
  width: 100%;
}
.nav {
  background-color: #bbdd99;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 0;
  z-index: 2;
  width:100%;
  text-align: left;
}
.page-name {
  background-color: rgba(82, 148, 209, 0.75);
  height: 14vw;
}
.bm-menu {
  background-color: #E6E6E6 !important;
  padding-top: 5% !important;
  left: 0px !important;
  right: auto !important;
}
.bm-item-list {
  color: #212121 !important;
  margin-right: 10%;
  margin-left: 0 !important;
}
.emptyLink {
  color: #0000ff;
  cursor: pointer;
  text-decoration: underline;
}
.menu-link {
  display: inline-block;
  text-align: left;
  width: 17%;
  float: left;
}
.menu-link:hover {
  cursor: pointer;
}
.logo_image {
  text-align: left;
  vertical-align: -35%;
  display: inline-block;
  font-weight: bold;
  width:35%;
  margin: 0% 0 0% 0;
  filter: drop-shadow(0px 0px 1vw rgba(0, 0, 0, 0.5));
}
.home_button {
  text-align: left;
  float: left;
  height:100%;
  width:15%;
}
.menu_image {
  vertical-align: bottom;
  width: 35%;
  height: 35%;
  margin: 25%;
  filter: drop-shadow(0px 0px 1vw rgba(0, 0, 0, 0.5));
}
.icon_image {
  vertical-align: middle;
  width: 8vw;
  height: 8vw;
  margin: 3% 2% 3% 12%;
  filter: drop-shadow(0px 0px 1vw rgba(0, 0, 0, 0.5));
}
.float-clear {
  clear:
}
.fixed_button {
  position: fixed;
  right: 0px;
  left: 0px;
  bottom:0px;
}
.advertise_image {
  width: 100%;
}
.navlink {
  width: 60vw;
  background-color:#FAFAFA;
  margin-bottom: 5%;
  padding-left: 25% !important;
  color: #212121;
  cursor: pointer;
  text-align: right;
  font-weight: bold;
  font-size: 5vw;
}
.navlink img {
  width: 7vw;
  position: absolute;
  left: 7%;
}
.advertise_space {
  height: 20vh;
}
input[type=text],input[type=password], select, button {
  -webkit-appearance: none;
  appearance: none;
}
.title_fonts {
  font-size: 8vw;
}
.main_fonts {
  font-size: 5vw;
}
.button_fonts {
  font-size: 5vw;
}
.buttonicon_fonts {
  font-size: 4vw;
}
@media screen and (min-width: 750px) {
  body {
    width: 750px;
    margin-right: auto;
    margin-left: auto;
  }
  .nav {
    width: 750px;
    margin-right: auto;
    margin-left: auto;
  }
  .icon_image {
    width: 60px;
    height: 60px;
  }
  .logo_image {
    vertical-align: -17px;
  }
  .page-name {
    height: 105px;
  }
  .navlink {
    width: 450px;
    font-size: 37.5px;
  }
  .navlink img {
    width: 52.5px;
  }
  .title_fonts {
    font-size: 60px;
  }
  .main_fonts {
    font-size: 33.75px;
  }
  .button_fonts {
    font-size: 30px;
  }
  .buttonicon_fonts {
    font-size: 30px;;
  }
}
</style>
