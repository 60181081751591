<template>
  <div>
    <div v-if="loading">読み込み中...</div>
    <div class="friends_caption">
        <p><img src="@/assets/renewal/edit.png"></p>
        <h2 class="title_fonts">ラウンド修正</h2>
      </div>
      <div class="result_game">
        <h1 class="title_fonts">{{ friend_Record.FriendNickName }}</h1>
        <h4 class="main_fonts">ユーザーID：{{ friend_Record.FriendUserID }}</h4>
      </div>
      <div class="result_game_caption main_fonts">
        <h3>日付</h3>
      </div>
      <div class="result_game_value main_fonts" v-on:mouseup="switchHamburger('Date')">
        <h3>{{ year }}</h3><p>年</p><h3>{{ month }}</h3><p>月</p><h3>{{ day }}</h3><p>日</p>
      </div>
      <div class="result_game_caption main_fonts">
        <h3>ゴルフ場</h3>
      </div>
      <div class="result_game_value main_fonts" v-on:mouseup="switchHamburger('Golf')">
        <h3 class="game_course">{{ (golf_course) ? golf_course.GolfCourseName : "未選択" }}</h3>
      </div>
      <div class="gray_line"></div>
      <div class="result_edit_list main_fonts">
        <div class="result_score_line">
          <div class="result_edit_rule">{{ result_Record.Rule>=2 ? '3' : '1' }}<span>勝制</span></div>
          <div class="result_edit_half">前半</div>
          <div class="result_edit_half">後半</div>
          <div class="result_edit_half">合計</div>
        </div>
        <div class="result_score_line">
          <div class="result_score_caption">自分</div>
          <div class="result_edit_input">
            <input type="number" min="0" @change="onScoreChange(false)" v-model="s_out" inputmode="numeric" class="main_fonts"><br/>
            <span v-if="result_Record.Handicap > 0 && result_Record.Rule >= 2">({{ (s_out) ? (parseFloat(s_out) - result_Record.Handicap) : "-" }})</span>
          </div>
          <div class="result_edit_input">
            <input type="number" min="0" @change="onScoreChange(false)" v-model="s_in" inputmode="numeric" class="main_fonts"><br/>
            <span v-if="result_Record.Handicap > 0 && result_Record.Rule >= 2">({{ (s_in) ? (parseFloat(s_in) - result_Record.Handicap) : "-" }})</span>
          </div>
          <div class="result_edit_input">
            <p>{{ s_total }}</p>
              <span v-if="result_Record.Handicap > 0">
                <span v-if="result_Record.Rule >= 2">
                  ({{ (s_out && s_in) ? (parseFloat(s_total) - result_Record.Handicap*2) : "-" }})
                </span>
                <span v-else>
                  ({{ (s_out && s_in) ? (parseFloat(s_total) - result_Record.Handicap) : "-" }})
                </span>
              </span>
          </div>
        </div>
        <div class="result_score_line">
          <div class="result_score_caption">相手</div>
          <div class="result_edit_input">
            <input type="number" min="0" @change="onScoreChange(true)" v-model="f_out" inputmode="numeric" class="main_fonts"><br/>
            <span v-if="result_Record.Handicap < 0 && result_Record.Rule >= 2">({{  (f_out) ? (parseFloat(f_out) + result_Record.Handicap) : "-" }})</span>
          </div>
          <div class="result_edit_input">
            <input type="number" min="0" @change="onScoreChange(true)" v-model="f_in" inputmode="numeric" class="main_fonts"><br/>
            <span v-if="result_Record.Handicap < 0 && result_Record.Rule >= 2">({{ (f_in) ? (parseFloat(f_in) + result_Record.Handicap) : "-" }})</span>
          </div>
          <div class="result_edit_input">
            <p>{{ f_total }}</p>
              <span v-if="result_Record.Handicap < 0">
                <span v-if="result_Record.Rule >= 2">
                  ({{ (f_out && f_in) ? (parseFloat(f_total) + result_Record.Handicap*2) : "-" }})
                </span>
                <span v-else>
                  ({{ (f_out && f_in) ? (parseFloat(f_total) + result_Record.Handicap) : "-" }})
                </span>
              </span>
          </div>
        </div>
        <div class="white_space"></div>
        <div class="result_score_line_bottom">
          <div class="result_score_caption_small"><span>今回</span>HC</div>
          <div class="result_score_handicap"><span>{{ (result_Record.Handicap>0) ? "自分に" : "相手に" }}</span>-{{ Math.abs(result_Record.Handicap).toFixed(1) }}</div>
        </div>
        <div class="result_score_line_bottom">
          <div class="result_score_caption_small">HC<span>増減値</span></div>
          <div class="result_score_handicap">{{ result_Record.HC_Value.toFixed(1) }}</div>
        </div>
        <div class="result_score_line_bottom">
          <div class="result_score_caption_small"><span>次回</span>HC</div>
          <div class="result_score_handicap"><span>{{ (checkChangedHandicapDetail()>0) ? "自分に" : "相手に" }}</span>-{{ Math.abs(checkChangedHandicapDetail()).toFixed(1) }}</div>
        </div>
      </div>
      <button class="result_button button_fonts" v-on:mouseup="editRecord(result_Record)" v-bind:disabled="loading">保存</button>
      <button class="result_back_button button_fonts" v-on:mouseup="openResultDetail(result_Record)">戻る</button>
      <div class="advertise_space"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API

import _ from 'lodash'  // 配列を扱うモジュール

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

export default {
  name: 'Result_Edit',
  props: ['friend_Record', 'result_Record', 'year', 'month', 'day', 'golf_course'],
  data() {
    return {
      s_out: 0,
      s_in: 0,
      s_total: 0,
      f_out: 0,
      f_in: 0,
      f_total: 0,
      rate: 0,
      loading: false,
    }
  },
  mounted: function () {
  console.log(this.result_Record)
    this.s_out = this.result_Record.MyFirstScore;
    this.s_in = this.result_Record.MySecondScore;
    this.s_total = this.result_Record.MyTotalScore;
    this.f_out = this.result_Record.FriendsFirstScore;
    this.f_in = this.result_Record.FriendsSecondScore;
    this.f_total = this.result_Record.FriendsTotalScore;
  },
  methods: {
    pageMove: function(pagename) {
      this.$emit('pageMove', pagename);
    },
    openResultList: function (friend) {
      this.$emit('openResultList', friend);
    },
    openResultDetail: function (result) {
      this.$emit('openResultDetail', result);
    },
    moveResultDetail: function (next) {
      this.$emit('moveResultDetail', next);
    },
    pageMoveResultEdit: function () {
      this.$emit('pageMoveResultEdit');
    },
    removeRecords: function (result) {
      this.$emit('removeRecords', result);
    },
    switchHamburger: function (menu) {
      this.$emit('switchHamburger', menu);
    },
    editRecord: async function (record) {
      if (!this.loading) {
        this.loading = true;
        await this.$emit('editRecord', record, [this.s_out, this.s_in, this.s_total], [this.f_out, this.f_in, this.f_total]);
        this.loading = false;
      }
    },
    checkChangedHandicapDetail() {
      let myNetScore = [];
      let friNetScore = [];
      let win = false;
      let lose = false;
      if (this.result_Record.Rule >= 2) {
        if (this.result_Record.Handicap >= 0) {
          myNetScore = [this.s_out - this.result_Record.Handicap, this.s_in - this.result_Record.Handicap, this.s_total - this.result_Record.Handicap*2];
          friNetScore = [this.f_out, this.f_in, this.f_total];
        }
        else {
          myNetScore = [this.s_out, this.s_in, this.s_total];
          friNetScore = [this.f_out + this.result_Record.Handicap, this.f_in + this.result_Record.Handicap, this.f_total + this.result_Record.Handicap*2];
        }
        let winCount = 0;
        let loseCount = 0;
        for (let i = 0; i < myNetScore.length; i++) {
          if (myNetScore[i] < friNetScore[i]) {
            winCount ++;
          }
          else if (myNetScore[i] > friNetScore[i]) {
            loseCount ++;
          }
        }
        if (winCount == 3) {
          win = true;
        }
        else if (loseCount == 3) {
          lose = true;
        }
      }
      else {
        if (this.result_Record.Handicap >= 0) {
          myNetScore = this.s_total - this.result_Record.Handicap;
          friNetScore = this.f_total;
        }
        else {
          myNetScore = this.s_total;
          friNetScore = this.f_total + this.result_Record.Handicap;
        }
        if (myNetScore < friNetScore) {
          win = true;
        }
        else if (myNetScore > friNetScore) {
          lose = true;
        }
      }
      if (win) {
        return this.result_Record.Handicap - this.result_Record.HC_Value;
      }
      else if (lose) {
        return this.result_Record.Handicap + this.result_Record.HC_Value;
      }
      else {
        return this.result_Record.Handicap;
      }
    },
    onScoreChange(user) {  // 3勝制の場合、前後半に変更を加えると合計が自動で変更される
      this.s_out = (this.s_out) ? ((this.s_out >= 0) ? this.s_out : -this.s_out) : 0;
      this.s_in = (this.s_in) ? ((this.s_in >= 0) ? this.s_in : -this.s_in) : 0;
      this.f_out = (this.f_out) ? ((this.f_out >= 0) ? this.f_out : -this.f_out) : 0;
      this.f_in = (this.f_in) ? ((this.f_in >= 0) ? this.f_in : -this.f_in) : 0;
      this.s_out = parseInt(this.s_out);
      this.s_in = parseInt(this.s_in);
      this.f_out = parseInt(this.f_out);
      this.f_in = parseInt(this.f_in);
      if (!user) {
        this.s_total = parseInt(this.s_out) + parseInt(this.s_in);
        if (this.s_total < 0) {
          this.s_total < 0
        }
      }
      else {
        this.f_total = parseInt(this.f_out) + parseInt(this.f_in);
        if (this.f_total < 0) {
          this.f_total < 0
        }
      }
    },
  }
}
</script>