<template>
  <div class="profile">
    <div v-if="loading">読み込み中...</div>
    <div class="profile_top" v-if="page==''">
      <div class="profile_caption">
        <p><img src="@/assets/renewal/option.png"></p>
        <h2 class="title_fonts">ユーザー設定</h2>
      </div>
      <div class="profile_userid">
        <h2 class="main_fonts">ユーザーID：{{ username }}</h2>
      </div>
      <div class="gray_line"></div>
      <div class="profile_container">
        <h3 class="main_fonts">ニックネーム</h3>
      </div>
      <div :class="(edit) ? 'profile_edit' : 'profile_value'">
        <input type="text" class="nickname input_text main_fonts" placeholder="ニックネーム" v-model="nickname" v-bind:disabled="!edit">
      </div>
      <div class="profile_container">
        <h3 class="main_fonts">メールアドレス</h3>
      </div>
      <div :class="(edit) ? 'profile_edit' : 'profile_value'">
        <input type="text" class="email input_text main_fonts" placeholder="メールアドレス" v-model="email" v-bind:disabled="!edit">
      </div>
      <button class="profile_button button_fonts" v-on:mouseup="switchEdit()" v-bind:disabled="loading" v-if="!edit" type="button">編集</button>
      <button class="profile_button button_fonts" v-on:mouseup="sendData()" v-bind:disabled="loading" v-else type="button">保存</button>
      <button class="profile_back_button button_fonts" v-on:mouseup="pagePush('/')" v-if="!edit" type="button">戻る</button>
      <button class="profile_back_button button_fonts" v-on:mouseup="switchEdit()" v-else type="button">キャンセル</button>
      <div class="advertise_space"></div>
    </div>
    <div class="profile_activate" v-if="page=='activate'">
      <div class="profile_caption">
        <p><img src="@/assets/renewal/option.png"></p>
        <h2 class="title_fonts">ユーザー設定</h2>
      </div>
      <div class="profile_activate_info">
        <h3 class="main_fonts">認証コードを送信しました。</h3>
        <h4 class="main_fonts">メールアドレスの認証をするため、<br/>以下に認証コードを入力してください。</h4>
      </div>
      <div class="gray_line"></div>
      <div class="profile_container">
        <h3 class="main_fonts">認証コード</h3>
      </div>
      <div class="profile_value">
        <input type="text" class="email input_text main_fonts" placeholder="6桁のコードを入力" v-model="activate_code">
      </div>
      <button class="profile_button button_fonts" v-on:mouseup="sendActivateCode()" v-bind:disabled="loading" type="button">認証する</button>
      <button class="profile_back_button button_fonts" v-on:mouseup="pagePush('/')" type="button">キャンセル</button>
      <p class="profile_resend button_fonts" v-on:mouseup="sendEmail()">認証コードを再送信する</p>
      <div class="advertise_space"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
/* メニュー画面 */
import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API
import { Auth } from 'aws-amplify'

import { updateGolfHandicap } from "../graphql/mutations"	// レコードを更新するmutation
import { updateGolfHandicapBatch } from "../graphql/mutations"
import { getFriendRecord } from "@/graphql/queries"	// スコアレコードを取得するquery
import { getRecordInFriendID } from "../graphql/queries"
import { listKumiawaseRecord } from "../graphql/queries"

import _ from 'lodash'  // 配列を扱うモジュール

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

export default {
  name: 'Home',
  data () {
    return {
      page:"",
      username: "",
      nickname: "",
      email: "",
      edit: false,
      activate_code: "",
      loading: false,
      vertical_layout: false,
      hamburger: {
        email: false,
        nickname: false,
      },
    }
  },
  mounted: function () {
    this.page = "";
    this.username = store.state.user.username;
    this.nickname = store.state.nickname;
    this.email = store.state.email;
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    pagePush: function(pagename) {	// ページ遷移
      router.push({path: pagename})
    },
    getRecords: async function(query) {
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      try {
        const index = "\"FriendUserID-RangeKey-index\"";
        const searchData = query;
        do {
          try {
            if (nextToken == "") {
              result = await API.graphql(graphqlOperation(getRecordInFriendID, {index: index, query: searchData}));
              recordsArr = result.data.listGolfHandicapTableRenewalsIndex.items;
            } else {
              result = await API.graphql(graphqlOperation(getRecordInFriendID, {index: index, query: searchData, nextToken: nextToken}));
              recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsIndex.items);
            }
            nextToken = result.data.listGolfHandicapTableRenewalsIndex.nextToken;
          }catch(err) {
            console.log(err);
          }
        } while (nextToken != null)
      } catch(err) {
        console.log(err);
      }
      return recordsArr;
    },
    getFriendRecord: async function(id) {  // 組み合わせレコード取得
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const searchData = {
        HashKey: {
          eq: id,  // 完全一致
        },
        RangeKey: {
          beginsWith: "K01"  // 前方一致
        }
      }
      do {
        try {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(getFriendRecord, {query: searchData}));
            recordsArr = result.data.listGolfHandicapTableRenewalsQuery.items;
          } else {
            result = await API.graphql(graphqlOperation(getFriendRecord, {query: searchData, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsQuery.items);
          }
          nextToken = result.data.listGolfHandicapTableRenewalsQuery.nextToken;
        }catch(err) {
          console.log(err);
        }
      } while (nextToken != null)
      return recordsArr;
    },
    sendData: async function() {
      this.loading = true;
      if (this.nickname != store.state.nickname) {
        await this.sendNickname();
      }
      if (this.email != store.state.email) {
        await this.sendEmail();
      }
      this.loading = false;
    },
    sendNickname: async function() {	// ニックネーム設定
      // const nameByte = encodeURI(this.nickname).replace(/%../g, "*").length;
      if (!this.nickname || this.nickname.length < 1 || this.nickname.length > 9)
      {
        alert("ニックネームは1文字以上9文字以下で入力してください。。");
        return;
      }
      try {
        const input_nickname = {
          HashKey: store.state.user.username,
          RangeKey: "MST",
          NickName: this.nickname,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
        };
        API.graphql (graphqlOperation(updateGolfHandicap, {input: input_nickname}));
        
        const uid_data = await this.getFriendRecord(store.state.user.username);
        console.log(uid_data);
        for(let i = 0; i < uid_data.length; i++) {
          const input_data = {
            HashKey: uid_data[i].UserID,
            RangeKey: uid_data[i].KumiawaseID,
            NickName: this.nickname,
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
          };
          console.log(input_data);
          API.graphql (graphqlOperation(updateGolfHandicap, {input: input_data}));
        }
        
        const fid_input = {
          FriendUserID: {
            eq: store.state.user.username,
          }
        }
        const fid_data = await this.getRecords(fid_input);
        const data = _.filter(fid_data, (item) => {return item.RangeKey.indexOf('K01') != -1});
        for(let i = 0; i < data.length; i++) {
          const input_data = {
            HashKey: data[i].UserID,
            RangeKey: data[i].KumiawaseID,
            FriendNickName: this.nickname,
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
          };
          console.log(input_data);
          API.graphql (graphqlOperation(updateGolfHandicap, {input: input_data}));
        }
        let k_data = [];
        for (let i = 0; i < data.length; i++) {
          let nextToken = "";
          let result = null;
          const query_data = {
            HashKey: {
              eq: data[0].KumiawaseID,  // 完全一致
            },
          }
          do {
            try {
              if (nextToken == "") {
                result = await API.graphql(graphqlOperation(listKumiawaseRecord, {query: query_data}));
                k_data = k_data.concat(result.data.listGolfHandicapTableRenewalsReverse.items);
              }
              else {
                result = await API.graphql(graphqlOperation(listKumiawaseRecord, {query: query_data, nextToken:nextToken}));
                k_data = k_data.concat(result.data.listGolfHandicapTableRenewalsReverse.items);
              }
              nextToken = result.data.listGolfHandicapTableRenewalsReverse.nextToken;
            } catch(err) {
              console.log(err);
            }
          } while(nextToken != null);
        }
        let input_arr = []
        for (let i = 0; i < k_data.length; i++) {
          let input_data = null;
          if (k_data[i].Player1UserID == store.state.user.username) {
            input_data = {
              HashKey: k_data[i].HashKey,
              RangeKey: k_data[i].RangeKey,
              Player1NickName: this.nickname,
              UpdatedAt: this.getDateString(),
              UpdateUserID: store.state.user.username,
            }
          }
          else {
            input_data = {
              HashKey: k_data[i].HashKey,
              RangeKey: k_data[i].RangeKey,
              Player2NickName: this.nickname,
              UpdatedAt: this.getDateString(),
              UpdateUserID: store.state.user.username,
            }
          }
          input_arr.push(input_data)
        }
        for(let i = 0; i < input_arr.length; i++) {
          API.graphql (graphqlOperation(updateGolfHandicap, {input: input_arr[i]}));
        }
        store.commit('setNickname', this.nickname);
        this.edit = false;
      } catch (err) {
        console.log(err);
      }
      this.switchHamburger('nickname');
    },
    sendEmail: async function() {
      if (!this.email) {
        alert ("メールアドレスを入力してください。");
        return;
      }
      this.loading = true;
      try {
        await Auth.updateUserAttributes(store.state.user, {
          email: this.email,
          'custom:validated_email': store.state.email,
        });
        this.page = "activate";
      } catch (err) {
        console.log(err);
        if (err.message.includes("登録済み")) {
          alert("登録済みのメールアドレスです。");
        }
        else
        {
          switch(err.code)
          {
            case "InvalidParameterException":
              alert("無効なメールアドレスです。");
              break;
            default:
              alert("予期せぬエラーが発生しました。");
              break;
          }
        }
      }
      this.loading = false;
    },
    sendActivateCode: async function() {
      if (!this.activate_code) {
        alert ("認証コードを入力してください。");
        return;
      }
      this.loading = true;
      try {
        const result = await Auth.verifyUserAttributeSubmit(store.state.user, 'email', this.activate_code);
        if (result === 'SUCCESS') {
          try {
            const result2 = await Auth.updateUserAttributes(store.state.user, {
              email: this.email,
              'custom:validated_email': this.email,
            });
          } catch (err) {
            console.log(err);
          }
          const input_data = {
            HashKey: store.state.user.username,
            RangeKey: "MST",
            MailAddress: this.email,
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
          }
          await API.graphql (graphqlOperation(updateGolfHandicap, {input: input_data}));
          store.commit('setMailAdress', this.email);
          this.page = "";
        }
      } catch (err) {
        console.log(err);
        switch(err.code)
        {
          case "CodeMismatchException":
            alert("認証コードが正しくありません、");
            break;
          case "ExpiredCodeException":
            alert("認証コードが期限切れです。");
            break;
          case "UserNotFoundException":
            alert("ユーザー登録されていないメールアドレスです。");
            break;
          default:
            alert("予期せぬエラーが発生しました。");
            break;
        }
      }
      this.loading = false;
    },
    getDateString () {
      const date = new Date();
      const date_str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getHours() + "-" + date.getMinutes();
      return date_str;
    },
    switchEdit: function() {
      this.edit = !this.edit;
    },
    handleResize: function() {
      if (document.body.clientWidth <= 750) {
        this.vertical_layout = true;
      }
      else {
        this.vertical_layout = false;
      }
    },
    switchHamburger: function (menu) {
      this.hamburger[menu] = !this.hamburger[menu];
    },
    getWindowWidth() {
      return (document.body.clientWidth+20).toString(10);
    }
  }
}
</script>

<style scoped>
.profile {
  background-color: #FAFAFA;
  color: #212121;
  height: 150%;
  text-shadow: none;
}
.profile .profile_top,.profile .profile_activate{
  padding-top: 20%;
  background-size: cover;
  position: relative;
  z-index: 0;
}
.profile .profile_caption{
  text-align: center;
  width: 90%;
  margin: 5% auto 0 auto;
}
.profile .profile_caption * {
  z-index: 2;
}
.profile .profile_caption p, .profile .profile_caption h2 {
  display: inline-block;
  text-align: center;
  margin: 0 0 0 0;
}
.profile .profile_caption p {
  width: 12.5%;
}
.profile .profile_caption img {
  width: 100%;
}
.profile .profile_caption h2 {
  text-align: left;
  vertical-align: 35%;
  padding-left: 4%;
}
.profile .gray_line {
  width: 95%;
  height: 2px;
  background-color: #E6E6E6;
  margin: 5% auto 5% auto;
}
.profile .profile_userid {
  font-weight: bold;
  background-color: #BFBFBF;
  width: 85%;
  margin-top: 6%;
  margin-bottom: 2%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2%;
  padding-bottom: 3%;
}
.profile .profile_userid h2 {
  line-height: 7vw;
  margin: 1% auto 0 auto;
}
.profile .profile_container {
  text-align: center;
  background-color: #BFBFBF;
  width: 85%;
  margin: 5% auto 0% auto;
  border-bottom: solid 1px #FAFAFA;
  border-radius: 2vw 2vw 0 0;
}
.profile .profile_container h3 {
  text-align: left;
  vertical-align: 30%;
  padding-left: 4%;
  display: inline-block;
  margin: 1% 0 1% 0;
}
.profile .profile_value {
  background-color: #F1F1F1;
  width: 85%;
  margin: 0% auto auto auto;
}
.profile .profile_value input {
  background-color: #F1F1F1;
  text-align: center;
  font-weight: bold;
  width: 95%;
  height: 10vw;
  margin: 3% auto 3% auto;
  border: none;
  display: inline-block;
}
.profile .profile_value input:focus {
  outline: none;
}
.profile .profile_edit {
  background-color: #F1F1F1;
  width: 85%;
  margin: 0% auto auto auto;
  border-radius: 0 0 2vw 2vw;
}
.profile .profile_edit input {
  background-color: #FAFAFA;
  text-align: center;
  font-weight: bold;
  width: 95%;
  height: 10vw;
  margin: 3% auto 3% auto;
  border: solid 1px #212121;
  border-radius: 2vw;
  display: inline-block;
}
.profile .profile_edit input:focus {
  outline: none;
}
.profile .profile_button {
  background-color: #5294D1;
  color: #F1F1F1;
  font-weight: bold;
  width: 85%;
  height: 10vw;
  display: block;
  margin: 5% auto 0 auto;
  border: solid 0px #5294D1;
  border-radius: 1vw;
}
.profile .profile_back_button {
  background-color: #FAFAFA;
  color: #5294D1;
  font-weight: bold;
  width: 85%;
  height: 10vw;
  display: block;
  margin: 5% auto 0 auto;
  border: solid 1px #5294D1;
  border-radius: 1vw;
}
.profile .profile_resend {
  color: #5294D1;
  margin-top: 10%;
}
@media screen and (min-width: 750px) {
  .buttons {
    width: 250px;
  }
  .profile .profile_userid h2 {
    line-height: 52.5px;
  }
  .profile .profile_container {
    border-radius: 15px 15px 0 0;
  }
  .profile .profile_edit {
    border-radius: 0 0 15px 15px;
  }
  .profile .profile_edit input {
    border-radius: 15px;
  }
  .profile .profile_button {
    height: 75px;
    border-radius: 7.5px;
  }
  .profile .profile_back_button {
    height: 75px;
    border-radius: 7.5px;
  }
}
</style>
