/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNickName = /* GraphQL */ `
  query GetGolfHandicapTableRenewal(
  $HashKey: String!
  $RangeKey: String!
  ) {
    getGolfHandicapTableRenewal(HashKey: $HashKey, RangeKey: $RangeKey) {
      UserID
      NickName
      MailAddress
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      Notice
      MatchesThisYear
      WinThisYear
      LoseThisYear
      DrawThisYear
      RateThisYear
      AdvantageUserID
      AdvantageKumiawaseID
      AdvantageNickName
      AdvantagePoints
      AdvantageRate
      DisadvantageUserID
      DisadvantageKumiawaseID
      DisadvantageNickName
      DisadvantagePoints
      DisadvantageRate
      PreTaisenID
      PreHandicap
      PreMyScore
      PreResult
      PreTaisenDate
      PreFriendUserID
      PreFriendNickName
      PreFriendsScore
    }
  }
`;
export const getSerialNumber = /* GraphQL */ `
  query GetGolfHandicapTableRenewal(
  $HashKey: String!
  $RangeKey: String!
  ) {
    getGolfHandicapTableRenewal(HashKey: $HashKey, RangeKey: $RangeKey) {
      Number
    }
  }
`;
export const getGolfCourseID = /* GraphQL */ `
  query GetGolfHandicapTableRenewal(
  $HashKey: String!
  $RangeKey: String!
  ) {
    getGolfHandicapTableRenewal(HashKey: $HashKey, RangeKey: $RangeKey) {
      GolfCourseID
      GolfCourseName
      GolfCourseYomigana
    }
  }
`;
export const getGolfCourses = /* GraphQL */ `
  query GetGolfHandicapTableRenewal(
  $input: [GolfHandicapTableRenewalInput]!
  ) {
    getGolfHandicapTableRenewalBatch(input: $input) {
      GolfCourseID
      GolfCourseName
      GolfCourseYomigana
    }
  }
`;
export const getTaisenRecord = /* GraphQL */ `
  query ListGolfHandicapTableRenewalsQuery(
    $query: TableGolfHandicapTableRenewalFilterInput
    $limit: Int
    $nextToken: String
    $index: String
    $ask: Boolean
  ) {
    listGolfHandicapTableRenewalsReverse(query: $query, limit: $limit, nextToken: $nextToken, index:$index, ask: $ask) {
      items {
        CreatedAt
        CreateUserID
        UpdatedAt
        UpdateUserID
        TaisenID
        KumiawaseID
        Date
        Player1UserID
        Player1NickName
        Player2UserID
        Player2NickName
        Handicap
        HC_Value
        Rule
        Player1FirstScore
        Player1SecondScore
        Player1TotalScore
        Player2FirstScore
        Player2SecondScore
        Player2TotalScore
        Result
        Player1Win
        Player1Lose
        Player1Points
        Player2Win
        Player2Lose
        Player2Points
        Draw
        GolfCourseID
        GolfCourseName
      }
      nextToken
    }
  }
`;
export const getFriendRecord = /* GraphQL */ `
  query ListGolfHandicapsTableRenewalQuery(
    $query: TableGolfHandicapTableRenewalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGolfHandicapTableRenewalsQuery(query: $query, limit: $limit, nextToken: $nextToken) {
      items {
        UserID
        NickName
        CreatedAt
        CreateUserID
        UpdatedAt
        UpdateUserID
        KumiawaseID
        FriendUserID
        FriendNickName
        ShinseiKBN
        ShinseiDate
        Handicap
        HC_Value
        Rule
        Matches
        Win
        Lose
        Draw
        Rate
        Points
        MatchesThisYear
        WinThisYear
        LoseThisYear
        DrawThisYear
        RateThisYear
        PointsThisYear
        
      }
      nextToken
    }
  }
`;
export const getFriendRecordUnit = /* GraphQL */ `
  query GetGolfHandicapTableRenewal(
  $HashKey: String!
  $RangeKey: String!
  ) {
    getGolfHandicapTableRenewal(HashKey: $HashKey, RangeKey: $RangeKey) {
      UserID
      NickName
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      KumiawaseID
      FriendUserID
      FriendNickName
      ShinseiKBN
      ShinseiDate
      Handicap
      HC_Value
      Rule
      Matches
      Win
      Lose
      Draw
      Rate
      Points
      MatchesThisYear
      WinThisYear
      LoseThisYear
      DrawThisYear
      RateThisYear
      PointsThisYear
    }
  }
`;
export const getKumiawaseRecord = /* GraphQL */ `
  query GetGolfHandicapTableRenewal(
  $HashKey: String!
  $RangeKey: String!
  ) {
    getGolfHandicapTableRenewal(HashKey: $HashKey, RangeKey: $RangeKey) {
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      KumiawaseID
      Player1UserID
      Player1NickName
      Player2UserID
      Player2NickName
      ShinseiKBN
      ShinseiDate
      Handicap
      HC_Value
      Rule
      Matches
      Player1Win
      Player1Lose
      Player1Points
      Player1Rate
      Player2Win
      Player2Lose
      Player2Points
      Player2Rate
      Draw
      MatchesThisYear
      Player1WinThisYear
      Player1LoseThisYear
      Player1PointsThisYear
      Player1RateThisYear
      Player2WinThisYear
      Player2LoseThisYear
      Player2PointsThisYear
      Player2RateThisYear
      DrawThisYear
      SensekiID
    }
  }
`;
export const getScoreRecord = /* GraphQL */ `
  query ListGolfHandicapTableRenewalsReverse(
    $query: TableGolfHandicapTableRenewalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGolfHandicapTableRenewalsReverse(query: $query, limit: $limit, nextToken: $nextToken) {
      items {
        UserID
        NickName
        CreatedAt
        CreateUserID
        UpdatedAt
        UpdateUserID
        KumiawaseID
        FriendUserID
        FriendNickName
        ShinseiKBN
        ShinseiDate
        TaisenID
        Handicap
        HC_Value
        Rule
        MyFirstScore
        MySecondScore
        MyTotalScore
        FriendsFirstScore
        FriendsSecondScore
        FriendsTotalScore
        Result
        Win
        Lose
        Draw
        Points
        GolfCourseID
        GolfCourseName
        Date
      }
      nextToken
    }
  }
`;
export const getScoreRecordUnit = /* GraphQL */ `
  query GetGolfHandicapTableRenewal(
  $HashKey: String!
  $RangeKey: String!
  ) {
    getGolfHandicapTableRenewal(HashKey: $HashKey, RangeKey: $RangeKey) {
      UserID
      NickName
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      KumiawaseID
      FriendUserID
      FriendNickName
      ShinseiKBN
      ShinseiDate
      TaisenID
      Handicap
      HC_Value
      Rule
      MyFirstScore
      MySecondScore
      MyTotalScore
      FriendsFirstScore
      FriendsSecondScore
      FriendsTotalScore
      Result
      Win
      Lose
      Draw
      Points
      GolfCourseID
      GolfCourseName
      Date
    }
  }
`;
export const listKumiawaseRecord = /* GraphQL */ `
  query ListGolfHandicapTableRenewalsReverse(
    $query: TableGolfHandicapTableRenewalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGolfHandicapTableRenewalsReverse(query: $query, limit: $limit, nextToken: $nextToken) {
      items {
        HashKey
        RangeKey
        CreatedAt
        CreateUserID
        UpdatedAt
        UpdateUserID
        KumiawaseID
        Player1UserID
        Player1NickName
        Player2UserID
        Player2NickName
        ShinseiKBN
        ShinseiDate
        Handicap
        HC_Value
        Rule
        Matches
        Player1Win
        Player1Lose
        Player1Points
        Player1Rate
        Player2Win
        Player2Lose
        Player2Points
        Player2Rate
        Draw
        MatchesThisYear
        Player1WinThisYear
        Player1LoseThisYear
        Player1PointsThisYear
        Player1RateThisYear
        Player2WinThisYear
        Player2LoseThisYear
        Player2PointsThisYear
        Player2RateThisYear
        DrawThisYear
        SensekiID
      }
      nextToken
    }
  }
`;
export const getRecordInShinseiKBN = /* GraphQL */ `
  query ListGolfHandicapTableRenewalsQuery(
    $index: String!
    $query: TableGolfHandicapTableRenewalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGolfHandicapTableRenewalsIndex(index: $index, query: $query, limit: $limit, nextToken: $nextToken) {
      items {
        UserID
        NickName
        CreatedAt
        CreateUserID
        UpdatedAt
        UpdateUserID
        KumiawaseID
        FriendUserID
        FriendNickName
        ShinseiKBN
        ShinseiDate
        Handicap
        HC_Value
        Rule
        Matches
        Win
        Lose
        Draw
        Rate
        Points
        MatchesThisYear
        WinThisYear
        LoseThisYear
        DrawThisYear
        RateThisYear
        PointsThisYear
      }
      nextToken
    }
  }
`;
export const getRecordInFriendID = /* GraphQL */ `
  query ListGolfHandicapTableRenewalsQuery(
    $index: String!
    $query: TableGolfHandicapTableRenewalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGolfHandicapTableRenewalsIndex(index: $index, query: $query, limit: $limit, nextToken: $nextToken) {
      items {
        HashKey
        RangeKey
        UserID
        NickName
        CreatedAt
        CreateUserID
        UpdatedAt
        UpdateUserID
        KumiawaseID
        FriendUserID
        FriendNickName
        ShinseiKBN
        ShinseiDate
        Handicap
        HC_Value
        Rule
        Matches
        Win
        Lose
        Draw
        Rate
        Points
        MatchesThisYear
        WinThisYear
        LoseThisYear
        DrawThisYear
        RateThisYear
        PointsThisYear
      }
      nextToken
    }
  }
`;
export const listRecordOfUser = /* GraphQL */ `
  query ListGolfHandicapTableRenewalsQuery(
    $query: TableGolfHandicapTableRenewalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGolfHandicapTableRenewalsQuery(query: $query, limit: $limit, nextToken: $nextToken) {
      items {
        UserID
        NickName
        CreatedAt
        CreateUserID
        UpdatedAt
        UpdateUserID
        KumiawaseID
        FriendUserID
        FriendNickName
        ShinseiKBN
        ShinseiDate
        Handicap
        HC_Value
        Rule
        Matches
        Win
        Lose
        Draw
        Rate
        Points
        MatchesThisYear
        WinThisYear
        LoseThisYear
        DrawThisYear
        RateThisYear
        PointsThisYear
      }
      nextToken
    }
  }
`;
export const listRecordOfGolfCourse = /* GraphQL */ `
  query ListGolfHandicapTableRenewalsQuery(
    $query: TableGolfHandicapTableRenewalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGolfHandicapTableRenewalsQuery(query: $query, limit: $limit, nextToken: $nextToken) {
      items {
        GolfCourseID
        GolfCourseName
        GolfCourseYomigana
      }
      nextToken
    }
  }
`;