<template>
  <div class="signin">
    <div v-if="loading">読み込み中...</div>
    <p class="app_icon"><img src="@/assets/renewal/NGR_Icon.png"></p>
    <p class="app_logo"><img src="@/assets/renewal/NGR_Logo_W.png"></p>
    <div v-if="!signedIn" class="forms">
      <div id="signin" v-if="page==''">
        <form name="form-signin">
          <input type="text" class="username input_text main_fonts" placeholder="ユーザーIDを入力" v-model="username" autocomplete="on">
          <input type="password" class="password input_text main_fonts" placeholder="パスワードを入力" v-model="password">
          <div v-if="error" v-html="error" class="error_text main_fonts"></div>
          <button class="signin_button button_fonts" v-on:mouseup="signIn()" v-bind:disabled="loading" type="button">ログイン</button>
        </form>
        <p class="blue_link buttonicon_fonts" v-on:mouseup="pageMove('forgot')">パスワードをお忘れの方はこちら</p>
        <div class="white_line"></div>
        <p class="signup_guide buttonicon_fonts">初めてのご利用の際は<br/>以下よりユーザー登録を<br/>行ってください。</p>
        <button class="signin_button buttonicon_fonts" v-on:mouseup="pageMove('signup')" v-bind:disabled="loading" type="button">新規ユーザー登録（無料）</button>
      </div>
      <div id="signup" v-if="page=='signup'">
        <h2 class="signin_caption title_fonts">ユーザー登録</h2>
        <form name="form-signup">
          <input type="text" class="username input_text main_fonts" placeholder="ユーザーID（登録後は変更できません）" v-model="username" autocomplete="on">
          <input type="text" class="email input_text main_fonts" placeholder="ﾒｰﾙｱﾄﾞﾚｽ" v-model="email">
          <input type="password" class="password input_text main_fonts" placeholder="ﾊﾟｽﾜｰﾄﾞ(半角6文字以上)" v-model="password">
          <input type="password" class="password input_text main_fonts" placeholder="ﾊﾟｽﾜｰﾄﾞ(確認用)" v-model="password_r">
          <Policy></Policy>
          <p class="agree_policy buttonicon_fonts"><input type="checkbox" class="checkbox" v-model="isChecked" id="policy"><span id="signup_nav"><label for="policy">私は上記<a href="https://htsapl.com/site_policy/" target="_blank" class="link_active">利用規約</a>と<br/><a href="http://www.htsapl.com/privacy_policy.html" target="_blank" class="link_active">個人情報保護ポリシー</a>に<br/>同意します。</label></span></p>
          <div v-if="error" v-html="error" class="error_text main_fonts"></div>
          <button class="signin_button buttonicon_fonts" v-on:mouseup="createAccount()" v-bind:disabled="loading || !isChecked" type="button">新規ユーザー登録（無料）</button>
        </form>
        <div class="white_line"></div>
        <p class="signin_guide buttonicon_fonts">NGRユーザーの方は以下より<br/>ログインを行ってください。</p>
        <button class="signin_button button_fonts" v-on:mouseup="pageMove('')" v-bind:disabled="loading" type="button">ログイン</button>
      </div>
      <div id="activate" v-if="page=='activate'">
        <h2 class="signin_caption title_fonts">メールアドレス認証</h2>
        <form name="form-activate">
          <input type="text" class="username input_text main_fonts" placeholder="ユーザーID" v-model="username" readonly>
          <input type="text" class="activationKey input_text main_fonts" placeholder="認証コード" v-model="activationKey">
          <div v-if="error" v-html="error" class="error_text main_fonts"></div>
          <button class="signin_button buttons_fonts" v-on:mouseup="activationAccount()" v-bind:disabled="loading" type="button">認証する</button>
          <p class="blue_link button_fonts" v-on:mouseup="reSignUp()">認証コードを再送信する</p>
        </form>
      </div>
      <div id="forgot" v-if="page=='forgot'">
        <h2 class="signin_caption title_fonts">認証メール送信</h2>
        <form name="form-forgot">
          <input type="text" class="email input_text main_fonts" placeholder="メールアドレス" v-model="email">
          <div v-if="error" v-html="error" class="error_text main_fonts"></div>
          <button class="signin_button button_fonts" v-on:mouseup="forgotPassword()" v-bind:disabled="loading" type="button">送信</button>
          <button class="signin_button button_fonts" v-on:mouseup="pageMove('')" v-bind:disabled="loading" type="button">戻る</button>
        </form>
      </div>
      <div id="forgot" v-if="page=='forgot_submit'">
        <h2 class="signin_caption title_fonts">認証コード入力</h2>
        <form name="form-activate">
          <input type="text" class="email input_text main_fonts" placeholder="メールアドレス" v-model="email">
          <input type="text" class="activationKey input_text main_fonts" placeholder="認証コード" v-model="activationKey">
          <input type="password" class="password input_text main_fonts" placeholder="新しいパスワード" v-model="password">
          <input type="password" class="password input_text main_fonts" placeholder="新しいパスワード(確認用)" v-model="password_r">
          <div v-if="error" v-html="error" class="error_text main_fonts"></div>
          <button class="signin_button button_fonts" v-on:mouseup="forgotPasswordSubmit()" v-bind:disabled="loading" type="button">認証する</button>
          <button class="signin_button button_fonts" v-on:mouseup="pageMove('')" v-bind:disabled="loading" type="button">戻る</button>
        </form>
      </div>
      <div id="nickname" v-if="page=='nickname'">
        <h2 class="signin_caption title_fonts">ニックネーム設定</h2>
        <form name="form-nickname">
          <input type="text" class="nickname input_text main_fonts" placeholder="ニックネーム" v-model="nickname">
          <div v-if="error" v-html="error" class="error_text main_fonts"></div>
          <button class="signin_button button_fonts" v-on:mouseup="sendNickname()" v-bind:disabled="!nickname || loading" type="button">送信</button>
        </form>
      </div>
    </div>
    <div class="signedIn" v-if="signedIn">
      <button class="submit main_fonts" type="button" disabled>{{ signOut() }}サインアウト中</button>
    </div>
    <div class="advertise_space"></div>
  </div>
</template>
<script>
/* eslint-disable */
/*サインイン/サインアップ/認証/ニックネーム設定/サインアウト画面*/

import Policy from '@/components/Policy.vue'	//	規約

import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API
import { AmplifyEventBus } from 'aws-amplify-vue'

import { getNickName } from "../graphql/queries"	// ニックネームのレコードを取得するquery
import { updateGolfHandicap } from "../graphql/mutations"	// レコードを更新するmutation

import { Auth } from 'aws-amplify'

import ja from "@/localize.js"

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

export default {
  name: 'SignIn',
  components: {
    Policy
  },
  data () {
    return {
      signedIn: false,	// サインインしているか否か
      page: "",	// どのdivを表示するか
      email: "",
      username: "",	// ユーザーID(Cognito上ではusername)
      password: "",
      password_r: "",
      isChecked: false,
      activationKey: "",
      error: "",
      nickname: "",
      loading: false,
    }
  },
  mounted: async function () {
    this.page = "";
    if (localStorage.username) {
      this.username = localStorage.username;
    }
    console.log("query = ", this.$route.query);
    //	URLを確認して認証コード入力画面に直接遷移
    if (this.$route.query.option == "activate_signup") {
      this.page = "activate";
      this.username = this.$route.query.uid;
      console.log("page = ", this.page)
      this.$route.query = null;
    }
    else if (this.$route.query.option == "activate_forgot") {
      this.page = "forgot_submit";
      console.log("page = ", this.page)
      this.$route.query = null;
    }
  },
  watch: {
    username(newName) {
      localStorage.username = newName;
    }
  },
  methods: {
    createAccount: async function() {	// サインアップ
      this.loading = true;
      const password = this.password;
      const email = this.email;
      // パスワードポリシーを満たしているかチェック
      var regexp = new RegExp(/^[!-~]{6,100}$/);
      const pass_policy = regexp.test(this.password);
      const pass_match = this.password == this.password_r;
      var regexp_username = new RegExp(/^(?!t01|k01)(?!.*(admin|ngr))[0-9a-z_]{5,15}$/);
      const username_policy = regexp_username.test(this.username);
      // 満たしていればサインアップ
      if (pass_policy && pass_match && username_policy && email && this.isChecked) {
        try {
          const { user } = await Auth.signUp({
            username: this.username,
            password: this.password,
            attributes: {
              email: email,
            }
          });
          // 認証ページに遷移
          this.pageMove("activate");
        } catch (error) {
          if (error.message.includes("登録済み")) {
            this.setErrorMessage("登録済みのメールアドレスです。");
          }
          else {
            this.setErrorMessage(error.message);
          }
          console.log(error);
        }
      }
      else {
        if (!pass_policy) {
          this.setErrorMessage("パスワードは半角で6文字以上である必要があります。");
        } else if (!pass_match) {
          this.setErrorMessage("パスワードが一致していません。");
        } else if (!username_policy) {
          var regexp_username_reserved = new RegExp(/^(?!(t01|k01))(?!.*(admin|ngr)).*$/);
          var regexp_username_count = new RegExp(/^.{5,15}$/);
          if (!regexp_username_reserved.test(this.username)) {
            this.setErrorMessage("ユーザーIDに使用できない文字列が含まれています。");
          }
          else if (!regexp_username_count.test(this.username)) {
            this.setErrorMessage("ユーザーIDは5文字以上15文字以内で入力してください。");
          }
          else {
            this.setErrorMessage("ユーザーIDは半角のアルファベット小文字と数字、_のみ使用可能です。");
          }
        } else {
          this.setErrorMessage("メールアドレスを入力してください。");
        }
      }
      this.loading = false;
    },
    activationAccount: async function() {	// アカウント認証
      this.loading = true;
      try {
        await Auth.confirmSignUp(this.username, this.activationKey);
        this.page = "";
        this.setErrorMessage("");
      } catch (error) {
        this.setErrorMessage(error.message);
        console.log(error);
      }
      this.loading = false;
    },
    reSignUp: async function() {
      this.loading = true;
      try {
        const result = await Auth.resendSignUp(this.username);
        this.setErrorMessage("");
      } catch (error) {
        this.setErrorMessage(error.message);
        console.log(error);
      }
      this.loading = false;
    },
    signIn: async function() {	// サインイン
      var regexp_username = new RegExp(/^(?!T01|K01)[0-9a-z_]{5,15}$/);
      const username_policy = regexp_username.test(this.username);
      if (!username_policy) {
        this.setErrorMessage("ユーザー名またはパスワードが異なります")
        return;
      }
      this.loading = true;
      try {
        // サインインしてユーザー情報取得
        const user = await Auth.signIn(this.username, this.password);
        // storeにユーザー情報格納
        store.commit('setUser', user);
        // ニックネームが設定されているか確認
        const nN = await API.graphql(graphqlOperation(getNickName, {HashKey: store.state.user.username, RangeKey: "MST"}));
        if (nN.data.getGolfHandicapTableRenewal.NickName) {
          // メニューに遷移
          router.push({path: '/'})
        }
        else {
          // ニックネーム設定画面を表示
          this.page = "nickname";
        }
      } catch (error) {
        this.setErrorMessage(error.message);
        console.log(error);
        if (error.message.includes("Require Change Password")) {
          alert("メールアドレス認証を行い、パスワードを変更してください。");
          this.pageMove('forgot')
        }
      }
      this.loading = false;
    },
    signOut: async function() {	// サインアウト
      this.loading = true;
      try {
        await Auth.signOut();
        store.commit('setUser', null);
        this.signedIn = false;
        this.setErrorMessage("");
      } catch (error) {
        this.setErrorMessage(error.message);
        console.log(error);
      }
      this.loading = false;
    },
    forgotPassword: async function(){
      this.loading = true;
      try {
        await Auth.forgotPassword(this.email)
                  .then(data => console.log(data));
        this.pageMove('forgot_submit');
      } catch (error) {
        this.setErrorMessage(error.message);
        console.log(error);
      }
      this.loading = false;
    },
    forgotPasswordSubmit: async function() {
      this.loading = true;
      if (this.password == this.password_r) {
        try {
          await Auth.forgotPasswordSubmit(this.email, this.activationKey, this.password)
                    .then(data => console.log(data));
          // サインインしてユーザー情報取得
          const user = await Auth.signIn(this.email, this.password);
          // storeにユーザー情報格納
          store.commit('setUser', user);
          // ニックネームが設定されているか確認
          const nN = await API.graphql(graphqlOperation(getNickName, {HashKey: store.state.user.username, RangeKey: "MST"}));
          console.log(nN);
          if (nN.data.getGolfHandicapTableRenewal.NickName) {
            // メニューに遷移
            router.push({path: '/'})
          }
          else {
            // ニックネーム設定画面を表示
            this.page = "nickname";
          }
        } catch (error) {
          this.setErrorMessage(error.message);
          console.log(error);
        }
      }
      else {
        alert("パスワードが一致していません。");
      }
      this.loading = false;
    },
    pageMove: function(pagename) {	// 表示切替
      this.page = pagename;
      this.setErrorMessage("");
    },
    sendNickname: async function() {	// ニックネーム設定
      this.loading = true;
      if (!this.nickname || this.nickname.length < 1 || this.nickname.length > 9)
      {
        alert("ニックネームは1文字以上9文字以下で入力してください。。");
        return;
      }
      try {
        const input_data = {
          HashKey: this.username,
          RangeKey: "MST",
          NickName: this.nickname
        };
        await API.graphql (graphqlOperation(updateGolfHandicap, {input: input_data}));
        router.push({path: '/'})
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    setErrorMessage: function (message) {
      if (ja.ja[message]) {
        this.error = ja.ja[message];
      }
      else {
        this.error = message;
      }
      if (this.page=="forgot" || this.page=="forgot_submit") {
        this.error = this.error.replace("ユーザーID", "メールアドレス");
      }
    },
  },
  async beforeCreate() {	// サインイン済みかどうか確認
    try {
      await Auth.currentAuthenticatedUser()
      this.signedIn = true
    } catch (err) {
      this.signedIn = false
    }
    AmplifyEventBus.$on('authState', info => {
      if (info === 'signedIn') {
        this.signedIn = true
      } else {
        this.signedIn = false
      }
    })
  }
}
</script>
<style>
.signin {
  padding-top: 10%;
  background-image: url('../assets/renewal/ngr_signin_background.png');
  background-size: cover;
  text-shadow: 0px 0px 2vw rgb(0, 0, 0),
  0px 0px 1vw rgb(0, 0, 0);
}
.signin .app_icon {
  width: 30%;
  margin: auto;
}
.signin .app_icon img{
  width: 100%;
}
.signin .app_logo{
  width: 60%;
  margin: auto;
}
.signin .app_logo img {
  width: 100%;
}
.signin .forms {
  margin-top: 4%;
}
.signin #signin {
  margin-top: 10%;
}
.signin .input_text {
  text-align: center;
  width: 60%;
  height: 10vw;
  display: block;
  margin: auto;
  border: 1px solid gray;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-text-size-adjust: 100%;
}
.signin .input_text:read-only {
  background-color: #BBBBBB;
}
.signin .error_text {
  background-color: rgba(34, 34, 34, 0.22);
  color: #FF3333;
  width: 60%;
  margin: auto;
}
.signin .signin_button {
  background-color: #00CAA1;
  color: #FAFAFA;
  width: 60%;
  height: 10vw;
  display: block;
  margin: 7% auto 0 auto;
  border: none;
  border-radius: 1vw;
}
.signin .signin_button:disabled {
  background-color: #555555;
}
.signin .blue_link {
  background-color: rgba(34, 34, 34, 0.22);
  color: #5294D1;
  text-decoration: underline;
  width: 60%;
  margin: 4% auto 0 auto;
  padding: 1% 0 1% 0;
}
.signin .white_line {
  background-color: #FAFAFA;
  width: 85%;
  height: 2px;
  margin: 5% auto 0 auto;
}
.signin .signup_guide,.signin .signin_guide {
  background-color: rgba(34, 34, 34, 0.22);
  color: #FAFAFA;
  width: 60%;
  margin: 4% auto 0 auto;
  padding: 1.5% 0 1.5% 0;
}
.signin .signin_caption {
  font-size: 7vw;
  background-color: rgba(34, 34, 34, 0.22);
  color: #FAFAFA;
  width: 60%;
  margin: 0 auto 0 auto;
  padding: 1.5% 0 1.5% 0;
}
.signin .agree_policy {
  background-color: rgba(34, 34, 34, 0.22);
  width: 60%;
  margin-top: 4%;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-right: auto;
  margin-left: auto;
}
.signin .checkbox {
  transform: scale(1.5);
  vertical-align: 35%;
  display: inline-block;
  margin: 0 5% 0 5%;
}
.signin .agree_policy span{
  text-align: center;
  display: inline-block;
}
.signin .agree_policy a {
  color: #5294D1;
}
.signin #signup .signin_button {
  margin-top: 3%;
}
.signin #signup .signin_button:disabled {
  margin-top: 3%;
}
.signin .advertise {
  width: 60%;
  height: 3.25%;
  background-color: #0088FF;
  color: #212121;
  border: 1px solid #212121;
  margin: 25% auto 0 auto;
  line-height: 275%;
  font-size: 2vw;
  text-shadow: 0px 0px 0px rgb(0, 0, 0);
}
#policy {
  transform:scale(2.5);
  vertical-align: 7.5vw;
}
@media screen and (min-width: 750px) {
  .signin .input_text {
    height: 70px;
  }
  .signin .login_button {
    height: 37.5px;
    border-radius: 7.5px;
  }
  .signin .signin_button {
    height: 70px;
    border-radius: 7.5px;
  }
  .signin .agree_policy {
    margin-top: 4%;
  }
  .signin .signin_caption {
    font-size: 52.5px;
  }
}
</style>