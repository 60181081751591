<template>
  <div>
    <div v-if="loading">読み込み中...</div>
    <div v-if="!deleted">
      <div class="friends_caption">
        <p><img src="@/assets/renewal/edit.png"></p>
        <h2 class="title_fonts">フレンド削除</h2>
      </div>
      <div class="friend_detail_info">
        <h1 class="title_fonts">{{ friend_Record.FriendNickName }}</h1>
        <h4 class="main_fonts">ユーザーID：{{ friend_Record.FriendUserID }}</h4>
      </div>
      <div class="friend_detail_info">
        <h4 class="main_fonts" style="color: #FF3333;">このユーザーとのフレンド登録を削除しますか？</h4>
      </div>
      <button class="result_button button_fonts" v-on:mouseup="deleteFriend(friend_Record.FriendID)" v-bind:disabled="loading">削除</button>
      <button class="result_back_button button_fonts" v-on:mouseup="pageMove('friend_edit', friend_Record)">戻る</button>
    </div>
    <div v-else>
      <div class="friends_caption">
        <p><img src="@/assets/renewal/edit.png"></p>
        <h2 class="title_fonts">フレンド削除</h2>
      </div>
      <div class="friend_detail_info">
        <h4 class="main_fonts" style="color: #FF3333;">{{ friend_Record.FriendNickName }} さんとの<br/>フレンド登録を削除しました。</h4>
      </div>
      <button class="result_back_button button_fonts" v-on:mouseup="pageMove('')">戻る</button>
    </div>
    <div class="advertise_space"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API

import { getRecordInShinseiKBN } from "@/graphql/queries"
import { getSerialNumber } from "@/graphql/queries"	// ニックネームのレコードを取得するquery
import { createFriendData } from "@/graphql/mutations"	// 組み合わせデータを作成するmutation
import { createKumiawaseData } from "@/graphql/mutations"	// 組み合わせデータを作成するmutation
import { updateGolfHandicap } from "../graphql/mutations"	// レコードを更新するmutation

import _ from 'lodash'  // 配列を扱うモジュール

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

export default {
  name: 'Friend_Delete',
  props: ['friend_Record'],
  data() {
    return {
      rule: 1,
      handicap: "00.0",
      hc_Value: "00.0",
      loading: false,
      deleted: false,
    }
  },
  mounted: function () {
    this.rule = this.friend_Record.Rule;
    if (this.rule % 2 == 0)
    {
      this.rule += 1;
    }
    this.handicap = parseFloat(-this.friend_Record.Handicap).toFixed(1).toString().padStart(4, '0');
    this.hc_value = this.friend_Record.HC_Value;
  },
  methods: {
    pageMove: function(pagename) {
      this.$emit('pageMove', pagename);
    },
    fixRequest: async function(record) {  // 組み合わせレコード更新
      this.loading = true;
      try {
        let handicap = -this.handicap;
        this.fixFriendRecord(store.state.user.username, record.KumiawaseID, handicap, this.hc_Value, this.rule);
        handicap = this.handicap;
        this.fixFriendRecord(record.FriendUserID, record.KumiawaseID, handicap, this.hc_Value, this.rule);
        this.updateKumiawaseData(record.KumiawaseID, -handicap, this.hc_Value, this.rule);
        this.pageMove("");
      } catch (err) {
          console.log(err);
      }
      this.loading = false;
    },
    fixFriendRecord: async function (user_id, kumiawase_id, handi, hc_Value, rule) {
      const input_data = {
        HashKey: user_id,
        RangeKey: kumiawase_id,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        Handicap: handi,
        HC_Value: hc_Value,
        Rule: rule
      }
      console.log(input_data);
      API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
    },
    deleteFriend: async function() {  // フレンド削除
      if (confirm(this.friend_Record.FriendNickName + " さんとのフレンド登録を削除します。\nよろしいですか？")) {
        this.loading = true;
        try {
          this.deleteFriendRecord(store.state.user.username, this.friend_Record.KumiawaseID);
          this.deleteFriendRecord(this.friend_Record.FriendUserID, this.friend_Record.KumiawaseID);
          this.deleted = true;
        } catch (err) {
          console.log(err);
        }
        this.loading = false;
      }
    },
    deleteFriendRecord: async function(self_id, kumiawase_id) {
      const input_data = {
        HashKey: self_id,
        RangeKey: kumiawase_id,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        ShinseiKBN: 9
      }
      API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
    },
    createFriendData: async function(user, friend, handicap, kNum, self) {
      let rule = this.rule;
      let hc_value = this.hc_Value;
      let shinseiKBN = 1;
      if (!self) {
        shinseiKBN = 2;
      }
      // 格納用のデータ
      const fri_Data = {
        HashKey: user.UserID,
        RangeKey: kNum,
        UserID: user.UserID,
        NickName: user.NickName,
        CreatedAt: this.getDateString(),
        CreateUserID: store.state.user.username,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        KumiawaseID: kNum,
        FriendUserID: friend.UserID,
        FriendNickName: friend.NickName,
        ShinseiKBN: shinseiKBN,
        ShinseiDate: this.getDateString(),
        Handicap: handicap,
        HC_Value: hc_value,
        Rule: rule,
        Matches: 0,
        Win: 0,
        Lose: 0,
        Draw: 0,
        Points: 0,
        MatchesThisYear: 0,
        WinThisYear: 0,
        LoseThisYear: 0,
        DrawThisYear: 0,
        PointsThisYear: 0,
        
      }
      await API.graphql(graphqlOperation(createFriendData, {input: fri_Data}));
    },
    createKumiawaseData: async function (kNum, user, friend, handicap) {
      let rule = this.rule;
      let hc_value = this.hc_Value;
      const kumi_data = {
        HashKey: kNum,
        RangeKey: "MST",
        CreatedAt: this.getDateString(),
        CreateUserID: store.state.user.username,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        KumiawaseID: kNum,
        Player1UserID: user.UserID,
        Player1NickName: user.NickName,
        Player2UserID: friend.UserID,
        Player2NickName: friend.NickName,
        ShinseiKBN: 0,
        ShinseiDate: this.getDateString(),
        Handicap: handicap,
        HC_Value: hc_value,
        Rule: rule,
        Matches: 0,
        Player1Win: 0,
        Player1Lose: 0,
        Player1Points: 0,
        Player1Rate: 0,
        Player2Win: 0,
        Player2Lose: 0,
        Player2Points: 0,
        Player2Rate: 0,
        Draw: 0,
        MatchesThisYear: 0,
        Player1WinThisYear: 0,
        Player1LoseThisYear: 0,
        Player1PointsThisYear: 0,
        Player1RateThisYear: 0,
        Player2WinThisYear: 0,
        Player2LoseThisYear: 0,
        Player2PointsThisYear: 0,
        Player2RateThisYear: 0,
        DrawThisYear: 0,
      }
      await API.graphql(graphqlOperation(createKumiawaseData, {input: kumi_data}));
    },
    updateKumiawaseData: async function (kNum, handi, hc_value, rule) {
      const kumi_data = {
        HashKey: kNum,
        RangeKey: "MST",
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        Handicap: handi,
        HC_Value: hc_value,
        Rule: rule,
      }
      await API.graphql(graphqlOperation(updateGolfHandicap, {input: kumi_data}));
    },
    updateFriendRecordRequest: async function (user, handi, shinseiKBN, rule, kid) {  // 申請フラグ以外も更新
      let hc_value = 1;
      hc_value = this.hc_Value;
      const input_data = {
        HashKey: user.UserID,
        RangeKey: kid,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        Handicap: handi,
        HC_Value: hc_value,
        Rule: rule,
        ShinseiKBN: shinseiKBN
      }
      await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
    },
    reloadPage: async function () {
      const path = this.$route.path
      await router.push({path: "/ "});
      await router.push({path: path});
    },
    getDateString () {
      const date = new Date();
      const date_str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getHours() + "-" + date.getMinutes();
      return date_str;
    },
    optionsHC(minValue, maxValue) {  // プルダウン用
      let options = {};
      let n = 0;
      for (let i = minValue; i < maxValue; i+=0.5) {
        options[n] = {
          id: n,
          value: i.toFixed(1).toString().padStart(4, '0')
        }
        n++;
      }
      return options;
    },
  }
}
</script>