<template>
  <div class="home">
    <div class="loading" v-if="loading">読み込み中...</div>
    <div class="main">
      <div class="welcome main_fonts">ようこそ<span class="nickname" :style="{ fontSize: resizedFontSize((userDB && userDB.NickName) ? userDB.NickName : '', 8, 8, 0.9)}">{{ (userDB && userDB.NickName) ? userDB.NickName : '名無し' }}</span>さん</div>
      <div class="data_container year main_fonts">{{ date.getFullYear() }} 年</div>
      <div class="data_container">
        <div class="data_win_count">
          <p class="data_label main_fonts">戦数</p>
          <h2 class="data_data title_fonts">
            <span class="data_value">{{ (userDB && userDB.MatchesThisYear) ? userDB.MatchesThisYear : '0' }}</span>
            <span class="data_unit">戦</span>
          </h2>
        </div>
        <div class="data_win_rate">
          <p class="data_label main_fonts">勝率</p>
          <h2 class="data_data title_fonts" v-if="userDB && (userDB.WinThisYear + userDB.LoseThisYear) > 0">
            <span class="data_value">{{ (userDB.WinThisYear / (userDB.WinThisYear + userDB.LoseThisYear) * 100).toFixed(1) }}</span>
            <span class="data_unit">%</span>
          </h2>
          <h2 class="data_data title_fonts" v-else>
            <span class="data_value">-</span>
          </h2>
        </div>
        <div class="data_result">
          <p class="data_label main_fonts">戦績</p>
          <h2 class="data_data title_fonts" v-if="userDB && userDB.MatchesThisYear">
            <span class="data_value">{{ userDB.WinThisYear }}</span>
            <span class="data_unit">勝</span>
            <span class="data_value">{{ userDB.LoseThisYear }}</span>
            <span class="data_unit">敗</span>
            <span class="data_value">{{ userDB.DrawThisYear }}</span>
            <span class="data_unit">分</span>
          </h2>
          <h2 class="data_data title_fonts" v-else>
            <span class="data_value">-</span>
          </h2>
        </div>
      </div>
      <div class="rate_container">
        <div class="rate_superior" v-on:mouseup="pagePushFriend((userDB && userDB.DisadvantageKumiawaseID) ? userDB.DisadvantageKumiawaseID : '')">
          <p class="rate_caption main_fonts"><img src="@/assets/renewal/rate_superior.png" class="rate_image"><span class="rate_label">不利な相手</span></p>
          <p class="rate_name" :style="{ fontSize: resizedFontSize((userDB && userDB.DisadvantageNickName) ? userDB.DisadvantageNickName : '', 8, 7, 0.9)}">{{ (userDB && userDB.DisadvantageNickName) ? userDB.DisadvantageNickName : '-' }}</p>
          <p class="rate_data title_fonts" v-if="userDB && userDB.DisadvantageNickName"><span class="rate_value">{{(userDB.DisadvantagePoints > 0) ? '+' : '-' }} {{ Math.abs(userDB.DisadvantagePoints) }} </span>Pt</p>
          <p class="rate_data title_fonts" v-else><span class="rate_value">-</span></p>
        </div>
        <div class="rate_inferior" v-on:mouseup="pagePushFriend((userDB && userDB.AdvantageKumiawaseID) ? userDB.AdvantageKumiawaseID : '')">
          <p class="rate_caption main_fonts"><img src="@/assets/renewal/rate_inferior.png" class="rate_image"><span class="rate_label">有利な相手</span></p>
          <p class="rate_name" :style="{ fontSize: resizedFontSize((userDB && userDB.AdvantageNickName) ? userDB.AdvantageNickName : '', 8, 7, 0.9)}">{{ (userDB && userDB.AdvantageNickName) ? userDB.AdvantageNickName : '-' }}</p>
          <p class="rate_data title_fonts" v-if="userDB && userDB.AdvantageNickName"><span class="rate_value">{{(userDB.AdvantagePoints > 0) ? '+' : '-' }} {{ Math.abs(userDB.AdvantagePoints) }} </span>Pt</p>
          <p class="rate_data title_fonts" v-else><span class="rate_value">-</span></p>
        </div>
      </div>
      <div class="last_container" v-on:mouseup="pagePushTaisen((userDB && userDB.PreTaisenID) ? userDB.PreTaisenID : '')">
        <h2 class="last_result main_fonts" v-if="userDB && userDB.PreTaisenDate">{{ userDB.PreTaisenDate.slice(0,4) }}<span class="last_unit">年</span>{{ userDB.PreTaisenDate.slice(5,7) }}<span class="last_unit">月</span>{{ userDB.PreTaisenDate.slice(8,10) }}<span class="last_unit">日</span><span class="last_unit">-</span>{{ userDB.PreResult }}</h2>
        <h2 class="last_result" v-else>-</h2>
        <div v-if="userDB && userDB.PreTaisenID">
          <div class="last_self">
            <p class="last_name" :style="{ fontSize: resizedFontSize((userDB) ? userDB.NickName : '', 8, 7, 0.9)}">{{ userDB.NickName }}</p>
            <h2 class="last_point title_fonts">{{ userDB.PreMyScore - ((0 > userDB.PreHandicap) ? 0 : Math.abs(userDB.PreHandicap)) }}<span class="last_net" v-if="userDB.PreHandicap > 0">({{ userDB.PreMyScore }})</span></h2>
          </div>
          <div class="last_vs main_fonts">
            <p class="last_name">vs</p>
            <h2 class="last_point"><span class="last_net"></span></h2>
          </div>
          <div class="last_rival">
            <p class="last_name" :style="{ fontSize: resizedFontSize((userDB && userDB.PreFriendNickName) ? userDB.PreFriendNickName : '', 8, 7, 0.9)}">{{ userDB.PreFriendNickName }}</p>
            <h2 class="last_point title_fonts">{{ userDB.PreFriendsScore - ((0 > userDB.PreHandicap) ? Math.abs(userDB.PreHandicap) : 0) }}<span class="last_net" v-if="0 > userDB.PreHandicap">({{ userDB.PreFriendsScore }})</span></h2>
          </div>
        </div>
        <div v-else>
          <div class="last_vs title_fonts">
            <p class="last_name">-</p>
            <h2 class="last_point"><span class="last_net"></span></h2>
          </div>
        </div>
      </div>
      <div class="menu_container buttonicon_fonts">
        <div class="score_button" v-on:mouseup="pagePush('/battle')">
          <p class="menu_icon"><img src="@/assets/renewal/score.png"></p>
          <p class="menu_label">スコア入力</p>
        </div>
        <div class="menu_button badge" v-on:mouseup="pagePush('/friend_List')" data-num="!" v-if="userDB && userDB.Notice">
          <p class="menu_icon"><img src="@/assets/renewal/friend_height.png"></p>
          <p class="menu_label">フレンド</p>
        </div>
        <div class="menu_button" v-on:mouseup="pagePush('/friend_List')" v-else>
          <p class="menu_icon"><img src="@/assets/renewal/friend_height.png"></p>
          <p class="menu_label">フレンド</p>
        </div>
        <div class="menu_button" v-on:mouseup="pagePush('/results')">
          <p class="menu_icon"><img src="@/assets/renewal/result.png"></p>
          <p class="menu_label">戦績</p>
        </div>
        <div class="menu_button" v-on:mouseup="pagePush('/profile')">
          <p class="menu_icon"><img src="@/assets/renewal/option.png"></p>
          <p class="menu_label">ユーザー設定</p>
        </div>
        <div class="advertise_space"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
/* メニュー画面 */
import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API

import { getNickName } from "@/graphql/queries"

import _ from 'lodash'  // 配列を扱うモジュール

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

export default {
  name: 'Home',
  data () {
    return {
      page:"",
      userDB: null,
      loading: false,
      vertical_layout: false,
      date: null,
      windowWidth: window.innerWidth,
    }
  },
  mounted: async function () {
    await this.getRecord();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('resize', this.updateWindowSize);
    this.date = new Date();
  },
  methods: {
    pageMove: function(pagename) {	// 表示切替
      this.page = pagename;
      this.getRecord();
    },
    pagePush: function(pagename) {	// ページ遷移
      router.push({path: pagename})
    },
    pagePushFriend (friendID) {
      if (friendID) {
        store.commit('setFriend', friendID);
        this.pagePush("/friend_list");
      }
    },
    pagePushTaisen (taisenID) {
      if (taisenID) {
        store.commit('setTaisen', taisenID);
        this.pagePush("/results");
      }
    },
    getRecord : async function() {
      this.loading = true;
      if (store.state.user) {
        const userMST = await API.graphql(graphqlOperation(getNickName, {HashKey: store.state.user.username, RangeKey: "MST"}));
        store.commit('setUserDB', userMST.data.getGolfHandicapTableRenewal);
        this.userDB = userMST.data.getGolfHandicapTableRenewal;
      }
      else {
        this.nickname = "";
      }
      this.loading = false;
    },
    handleResize: function() {
      if (document.body.clientWidth <= 750) {
        this.vertical_layout = true;
      }
      else {
        this.vertical_layout = false;
      }
    },
    resizedFontSize: function(text, maxSize, minCount, unitMinus) {
      let size = maxSize;
      let count = this.getStringLength(text);
      if (count > minCount)
      {
        size *= unitMinus ** (count - minCount);
      }
      
      let unit = "vw";
      if (this.windowWidth >= 750)
      {
        size *= 5;
        unit = "px"
      }
      
      console.log(size + unit);
      
      return size + unit;
    },
    updateWindowSize() {
      this.windowWidth = window.innerWidth;
    },
    getStringLength(str) {
      let count = 0;
      for (let i = 0; i < str.length; i++)
      {
        let charCode = str.charCodeAt(i);
        if (charCode >= 0x00 && charCode <= 0xFF) {
          count += 1;
        }
        else
        {
          count += 2;
        }
      }
      return count;
    }
  },
  beforeRouteEnter(to, from, next) {
    const pagename_friend = ["Friend_Search", "Requests", "Friend_List"];
    const pagename_score = ["ScoreRequests"];
    next (async (vm) => {
      if (pagename_friend.indexOf(from.name) != -1) {
        vm.pageMove("friend");
      } else if (pagename_score.indexOf(from.name) != -1) {
        vm.pageMove("score");
      }
      next()
    });
  },
}
</script>

<style scoped>
.home {
  padding-top: 14%;
  background-image: url('../assets/renewal/ngr_top_background.png');
  background-size: cover;
  text-shadow: 0px 0px 2vw rgb(0, 0, 0),
               0px 0px 1vw rgb(0, 0, 0);
}

.badge{
  position: relative;
}
.badge[data-num="0"]::before {
  display: none;
  margin: 0px;
}
.badge::before {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: attr(data-num);
    min-width: 5vw;
    min-height: 5vw;
    box-sizing: border-box;
    padding: 4px;
    font-weight: bold;
    background-color: #F3E348;
    border-radius: 2.5vw;
    top: 5%;
    right: 5%;
    transform: translate(40%, -40%);
    z-index: 1;
}
.home .welcome {
  text-align: center;
  font-weight: bold;
  margin-top: 5%;
  margin-bottom: 3%;
}
.home .welcome .nickname {
  margin-left: 2%;
  margin-right: 2%;
}
.home .data_container, .home .last_container {
  width: 90%;
  margin: 0 auto 0 auto;
  background-color: rgba(34, 34, 34, 0.22);
}
.home .data_container {
  border-bottom: solid 1px #FAFAFA;
  padding: 3% auto 3% auto;
}
.home .data_win_count,.home .data_win_rate {
  display: inline-block;
  width: 49.8%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.home .data_win_count {
  border-right: solid 1px #FAFAFA;
}
.home .data_result {
  display: inline-block;
  width: 100%;
  margin-bottom: 1.5%;
  border-top: solid 1px #FAFAFA;
}
.home .data_container p {
  margin: 10% 0 5% 0;
}
.home .data_result p {
  margin: 7.5% 0 2.5% 0;
}
.home .data_container h2 {
  margin: 1% 0 7.5% 0;
}
.home .data_container .data_result h2 {
  margin: 1% 0 5% 0;
}
.home .data_label {
  text-indent: 8vw;
  letter-spacing: 8vw;
  line-height: 0.75;
}
.home .data_data {
  line-height: 0.75;
}
.home .data_unit {
  margin-left: 1%;
  margin-right: 2%;
}
.home .rate_container {
  width: 90%;
  margin: 0 auto 0 auto;
  background-color: rgba(200, 221, 105, 0.22);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .rate_superior,.home .rate_inferior {
  width: 49.8%;
}
.home .rate_superior {
  border-right: solid 1px #FAFAFA;
}
.home .rate_inferior {
}
.home .rate_image{
  width: 13%;
  position: relative;
  bottom: 1vw;
  filter: drop-shadow(0px 0px 1vw rgba(0, 0, 0, 0.5));
}
.home .rate_label {
  vertical-align: 40%;
  margin-left: 3%;
  margin-right: 7%;
}
.home .rate_caption {
  margin-bottom: 0%;
  padding-left: 5%;
  text-align: center;
}
.home .rate_name {
  height: 7vw;
  margin-top: 0%;
  margin-bottom: 10%;
}
.home .rate_data {
  height: 8vw;
  margin-top: 1%;
  margin-bottom: 12.5%;
}
.home .rate_value {
  font-weight: bold;
}
.home .last_container {
  border-top: solid 1px #FAFAFA;
}
.home .last_caption {
  margin-bottom: 1%;
}
.home .last_result {
  margin-top: 1.5%;
  margin-bottom: 1%;
  padding-top: 4%;
}
.home .last_unit {
  margin-right: 1%;
}
.home .last_self,.home .last_rival {
  display: inline-block;
  width: 40%;
  margin-top: 0%;
}
.home .last_self {
  padding-left: 3%;
}
.home .last_rival {
  padding-right: 3%;
}
.home .last_vs {
  display: inline-block;
  width: 10%;
}
.home .last_name {
  margin-top: 3%;
  margin-bottom: 6.5%;
  line-height: 1;
}
.home .last_point {
  margin-top: 3%;
  margin-bottom: 15%;
}
.home .menu_container {
  width: 100%;
  height: 60%;
  margin-top: 7.5%;
  padding-top: 1%;
  padding-bottom: 36%;
  background-color: #FAFAFA;
  color: #212121;
  text-shadow: 0px 0px 0px rgb(0, 0, 0);
}
.home .score_button {
  width: 93%;
  background-color: #F1F1F1;
  display: block;
  margin: 2% auto 2% auto;
  border-radius: 3vw;
}
.home .score_button p {
  display: inline-block;
}
.home .score_button .menu_label {
  font-size: 5vw;
  vertical-align: 75%;
  line-height: 150%;
  margin-left: 2%;
  margin-top: 0;
  margin-bottom: 0;
}
.home .menu_button {
  background-color: #F1F1F1;
  width: 30%;
  display: inline-block;
  margin: 0 1% 0 1%;
  border-radius: 3vw;
}
.home .menu_icon {
  width: 50%;
  display: inline-block;
}
.home .score_button .menu_icon {
  width: 16%;
  margin-top: 3vw;
  margin-bottom: 3vw;
}
.home .menu_button .menu_icon {
  margin-top: 3vw;
  margin-bottom: 0;
}
.home .menu_icon img{
  width: 100%;
}
.home .menu_button .menu_label {
  margin-top: 0;
  margin-bottom: 3vw;
}
.home .advertise {
  width: 50%;
  height: 6%;
  background-color: #0088FF;
  border: 1px solid #212121;
  margin: 15% auto 0 auto;
  line-height: 275%;
}
@media screen and (min-width: 750px) {
  .buttons {
    width: 250px;
  }
  .badge::before {
    min-width: 37.5px;
    min-height: 37.5px;
    padding: 4px;
    border-radius: 18.75px;
    top: 0%;
    right: 10%;
  }
  .home .data_label {
    text-indent: 40px;
    letter-spacing: 40px;
  }
  .home .rate_name {
    height: 52.5px;
  }
  .home .rate_data {
    height: 60px;
  }
  .home .score_button {
    border-radius: 22.5px;
  }
  .home .score_button .menu_label {
    font-size: 37.5px;
  }
  .home .menu_button {
    border-radius: 22.5px;
  }
}
</style>
