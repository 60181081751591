import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import SignIn from '@/views/SignIn.vue'
import Friend_List from '@/views/Friend_List.vue'
import Battle from '@/views/Battle.vue'
import Results from '@/views/Results.vue'
import Profile from '@/views/Profile.vue'
import Sorry from '@/views/Sorry.vue'

import store from '@/store/index.js'

import { AmplifyEventBus } from 'aws-amplify-vue'
import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'

Vue.use(VueRouter)
Vue.use(AmplifyPlugin, AmplifyModules)

let user

const sorry = false;

getUser().then((user) => {
  if (user) {
    router.push({path: '/'})
  }
})

function getUser() {

  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUser', data);
      return data;
    }
  }).catch(() => {
    store.commit('setUser', null)
    return null
  })
}

AmplifyEventBus.$on('authState', async (state) => {
  if (state === 'signedOut'){
    user = null
    store.commit('setUser', null)
    router.push({path: '/signin'}, () => {})
  } else if (state === 'signedIn') {
    user = await getUser();
    router.push({path: '/'})
  }
})

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requireAuth: true }
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignIn
  },
  {
    path: '/friend_List',
    name: 'Friend_List',
    component: Friend_List,
    meta: { requireAuth: true }
  },
  {
    path: '/battle',
    name: 'Battle',
    component: Battle,
    meta: { requireAuth: true }
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
    meta: { requireAuth: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requireAuth: true }
  },
  {
    path: '/sorry',
    name: 'Sorry',
    component: Sorry
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  if (sorry && to.name != "Sorry") {
    return next({
      path: '/sorry'
    })
  }
  if (!sorry && to.name == "Sorry") {
    return next({
      path: '/'
    })
  }
  if (to.matched.some(record => record.meta.requireAuth)) {
    user = await getUser()
    if (!user) {
      return next({
        path: '/signin'
      })
    }
    return next()
  }
  return next()
})

export default router
