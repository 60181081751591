<template>
  <div>
    <div v-if="loading">読み込み中...</div>
    <div class="friends_caption">
      <p><img src="@/assets/renewal/friend.png"></p>
      <h2 class="title_fonts">フレンド申請</h2>
    </div>
    <div class="friend_detail_info">
      <h1 class="title_fonts">{{ friendUser.NickName }}</h1>
      <h4 class="main_fonts">ユーザーID：{{ friendUser.UserID }}</h4>
    </div>
    <div class="friend_edit_container main_fonts">
      <h4>ルール</h4>
      <div class="select_wrapper">
        <select class="main_fonts" v-model="rule">
          <option :value="1" v-text="'1勝制'"></option>
          <option :value="3" v-text="'3勝制'"></option>
        </select>
      </div>
    </div>
    <div class="friend_edit_container main_fonts">
      <h4>初戦のHC<span>({{ (rule == 1)?"/18H":"/9H" }})</span></h4>
      <div class="select_wrapper">
        <select class="main_fonts" v-model="handicap">
          <option v-for="hc in optionsHC(-50, 50, true)" :value="hc.value" v-text="((hc.value != 0) ? ((hc.value > 0) ? '相手に' : '自分に') : '' ) + (-parseFloat(Math.abs(hc.value))).toFixed(1)" v-bind:key="hc.id"></option>
        </select>
      </div>
    </div>
    <div class="friend_edit_container main_fonts">
      <h4>HC変動値<span>({{ (rule == 1)?"/18H":"/9H" }})</span></h4>
      <div class="select_wrapper">
        <select class="hc_Value main_fonts" v-model="hc_Value">
          <option v-for="hc in optionsHC(0, 10, false)" :value="hc.value" v-text="(parseFloat(hc.value)).toFixed(1)" v-bind:key="hc.id"></option>
        </select>
      </div>
    </div>
    <button class="result_button button_fonts" v-on:mouseup="friendRequest()" v-bind:disabled="loading">フレンド申請</button>
    <button class="result_back_button button_fonts" v-on:mouseup="pageMove('')">戻る</button>
    <div class="advertise_space"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API

import { getRecordInShinseiKBN } from "@/graphql/queries"
import { getSerialNumber } from "@/graphql/queries"	// ニックネームのレコードを取得するquery
import { createFriendData } from "@/graphql/mutations"	// 組み合わせデータを作成するmutation
import { createKumiawaseData } from "@/graphql/mutations"	// 組み合わせデータを作成するmutation
import { updateGolfHandicap } from "../graphql/mutations"	// レコードを更新するmutation

import _ from 'lodash'  // 配列を扱うモジュール

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

export default {
  name: 'Friend_Request',
  props: ['friendUser'],
  data() {
    return {
      rule: 1,
      handicap: "00.0",
      hc_Value: "00.0",
      loading: false,
    }
  },
  mounted: function () {
    
  },
  methods: {
    pageMove: function(pagename) {
      this.$emit('pageMove', pagename);
    },
    friendRequest: async function() {	// フレンド申請
      this.loading = true;
      // 該当するユーザーとのフレンドレコードが存在するか確認
      const friendData = await this.getRecordOfIndex(store.state.user.username);
      const matchFRec = _.filter(friendData, (item) => {return item.FriendUserID == this.friendUser.UserID});
      let kNum = 0;
      // 存在しない場合は採番用レコードを確認
      if (matchFRec.length == 0) {
        // 組み合わせIDの採番用レコードを確認
        const kNumData = await API.graphql(graphqlOperation(getSerialNumber, {HashKey: "SerialNumber", RangeKey: "Kumiawase"}));
        kNum = kNumData.data.getGolfHandicapTableRenewal.Number;
        // 自分のフレンドレコードを作成
        let handicap = -this.handicap;
        await this.createFriendData(store.state.userDB, this.friendUser, handicap, "K01" + kNum, true);
        // 相手のフレンドレコードを作成
        handicap = this.handicap;
        await this.createFriendData(this.friendUser, store.state.userDB, handicap, "K01" + kNum, false);
        // 組み合わせレコードを作成
        handicap = -this.handicap;
        await this.createKumiawaseData("K01" + kNum, store.state.userDB, this.friendUser, handicap);
        // 採番用レコードを更新
        const new_kNum = Number(parseInt(kNum, 16) + 1).toString(16).padStart(8, '0');
        const input_data = {
          HashKey: "SerialNumber",
          RangeKey: "Kumiawase",
          Number: new_kNum,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
        //	通知フラグ更新
        const flg_data = {
          HashKey: this.friendUser.UserID,
          RangeKey: "MST",
          Notice: 1,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: flg_data}));
      } else {  // 存在する場合は既存のレコードの組み合わせIDと紐づけ
        let handicap = -this.handicap;
        await this.updateFriendRecordRequest(store.state.userDB, handicap, 1, this.rule, matchFRec[0].KumiawaseID);
        handicap = this.handicap;
        await this.updateFriendRecordRequest(this.friendUser, handicap, 2, this.rule, matchFRec[0].KumiawaseID);
        handicap = -this.handicap;
        await this.updateKumiawaseData(matchFRec[0].KumiawaseID, store.state.userDB, this.friendUser, handicap);
        //	通知フラグ更新
        const flg_data = {
          HashKey: this.friendUser.UserID,
          RangeKey: "MST",
          Notice: 1,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: flg_data}));
      }
      this.loading = false;
      this.reloadPage();
    },
    getRecordOfIndex: async function(id) {
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const index = "\"ShinseiKBN-index\"";
      const searchData = {
        HashKey: {
          eq: id,
        },
        ShinseiKBN: {
          eq: 9
        }
      }
      do {
        try {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(getRecordInShinseiKBN, {index: index, query: searchData}));
            recordsArr = result.data.listGolfHandicapTableRenewalsIndex.items;
          } else {
            result = await API.graphql(graphqlOperation(getRecordInShinseiKBN, {index: index, query: searchData, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsIndex.items);
          }
          nextToken = result.data.listGolfHandicapTableRenewalsIndex.nextToken;
        }catch(err) {
          console.log(err);
        }
      } while (nextToken != null)
      return recordsArr;
    },
    createFriendData: async function(user, friend, handicap, kNum, self) {
      let rule = this.rule;
      let hc_value = this.hc_Value;
      let shinseiKBN = 1;
      if (!self) {
        shinseiKBN = 2;
      }
      // 格納用のデータ
      const fri_Data = {
        HashKey: user.UserID,
        RangeKey: kNum,
        UserID: user.UserID,
        NickName: user.NickName,
        CreatedAt: this.getDateString(),
        CreateUserID: store.state.user.username,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        KumiawaseID: kNum,
        FriendUserID: friend.UserID,
        FriendNickName: friend.NickName,
        ShinseiKBN: shinseiKBN,
        ShinseiDate: this.getDateString(),
        Handicap: handicap,
        HC_Value: hc_value,
        Rule: rule,
        Matches: 0,
        Win: 0,
        Lose: 0,
        Draw: 0,
        Points: 0,
        MatchesThisYear: 0,
        WinThisYear: 0,
        LoseThisYear: 0,
        DrawThisYear: 0,
        PointsThisYear: 0,
        
      }
      await API.graphql(graphqlOperation(createFriendData, {input: fri_Data}));
    },
    createKumiawaseData: async function (kNum, user, friend, handicap) {
      let rule = this.rule;
      let hc_value = this.hc_Value;
      const kumi_data = {
        HashKey: kNum,
        RangeKey: "MST",
        CreatedAt: this.getDateString(),
        CreateUserID: store.state.user.username,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        KumiawaseID: kNum,
        Player1UserID: user.UserID,
        Player1NickName: user.NickName,
        Player2UserID: friend.UserID,
        Player2NickName: friend.NickName,
        ShinseiKBN: 0,
        ShinseiDate: this.getDateString(),
        Handicap: handicap,
        HC_Value: hc_value,
        Rule: rule,
        Matches: 0,
        Player1Win: 0,
        Player1Lose: 0,
        Player1Points: 0,
        Player1Rate: 0,
        Player2Win: 0,
        Player2Lose: 0,
        Player2Points: 0,
        Player2Rate: 0,
        Draw: 0,
        MatchesThisYear: 0,
        Player1WinThisYear: 0,
        Player1LoseThisYear: 0,
        Player1PointsThisYear: 0,
        Player1RateThisYear: 0,
        Player2WinThisYear: 0,
        Player2LoseThisYear: 0,
        Player2PointsThisYear: 0,
        Player2RateThisYear: 0,
        DrawThisYear: 0,
      }
      await API.graphql(graphqlOperation(createKumiawaseData, {input: kumi_data}));
    },
    updateKumiawaseData: async function (kNum, user, friend, handicap) {
      let rule = this.rule;
      let hc_value = this.hc_Value;
      const kumi_data = {
        HashKey: kNum,
        RangeKey: "MST",
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        Handicap: handicap,
        HC_Value: hc_value,
        Rule: rule,
      }
      await API.graphql(graphqlOperation(updateGolfHandicap, {input: kumi_data}));
    },
    updateFriendRecordRequest: async function (user, handi, shinseiKBN, rule, kid) {  // 申請フラグ以外も更新
      let hc_value = 1;
      hc_value = this.hc_Value;
      const input_data = {
        HashKey: user.UserID,
        RangeKey: kid,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        Handicap: handi,
        HC_Value: hc_value,
        Rule: rule,
        ShinseiKBN: shinseiKBN
      }
      await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
    },
    reloadPage: async function () {
      const path = this.$route.path
      await router.push({path: "/ "});
      await router.push({path: path});
    },
    getDateString () {
      const date = new Date();
      const date_str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getHours() + "-" + date.getMinutes();
      return date_str;
    },
    optionsHC(minValue, maxValue) {  // プルダウン用
      let options = {};
      let n = 0;
      for (let i = minValue; i < maxValue; i+=0.5) {
        options[n] = {
          id: n,
          value: i.toFixed(1).toString().padStart(4, '0')
        }
        n++;
      }
      return options;
    },
  }
}
</script>