const towns = 
[
  {"id": "01", "name": "北海道", "region": "01"},
  {"id": "02", "name": "青森県", "region": "02"},
  {"id": "03", "name": "岩手県", "region": "02"},
  {"id": "04", "name": "宮城県", "region": "02"},
  {"id": "05", "name": "秋田県", "region": "02"},
  {"id": "06", "name": "山形県", "region": "02"},
  {"id": "07", "name": "福島県", "region": "02"},
  {"id": "08", "name": "茨城県", "region": "03"},
  {"id": "09", "name": "栃木県", "region": "03"},
  {"id": "10", "name": "群馬県", "region": "03"},
  {"id": "11", "name": "埼玉県", "region": "03"},
  {"id": "12", "name": "千葉県", "region": "03"},
  {"id": "13", "name": "東京都", "region": "03"},
  {"id": "14", "name": "神奈川県", "region": "03"},
  {"id": "15", "name": "新潟県", "region": "04"},
  {"id": "16", "name": "富山県", "region": "04"},
  {"id": "17", "name": "石川県", "region": "04"},
  {"id": "18", "name": "福井県", "region": "04"},
  {"id": "19", "name": "山梨県", "region": "04"},
  {"id": "20", "name": "長野県", "region": "04"},
  {"id": "21", "name": "岐阜県", "region": "04"},
  {"id": "22", "name": "静岡県", "region": "04"},
  {"id": "23", "name": "愛知県", "region": "04"},
  {"id": "24", "name": "三重県", "region": "05"},
  {"id": "25", "name": "滋賀県", "region": "05"},
  {"id": "26", "name": "京都府", "region": "05"},
  {"id": "27", "name": "大阪府", "region": "05"},
  {"id": "28", "name": "兵庫県", "region": "05"},
  {"id": "29", "name": "奈良県", "region": "05"},
  {"id": "30", "name": "和歌山県", "region": "05"},
  {"id": "31", "name": "鳥取県", "region": "06"},
  {"id": "32", "name": "島根県", "region": "06"},
  {"id": "33", "name": "岡山県", "region": "06"},
  {"id": "34", "name": "広島県", "region": "06"},
  {"id": "35", "name": "山口県", "region": "06"},
  {"id": "36", "name": "徳島県", "region": "07"},
  {"id": "37", "name": "香川県", "region": "07"},
  {"id": "38", "name": "愛媛県", "region": "07"},
  {"id": "39", "name": "高知県", "region": "07"},
  {"id": "40", "name": "福岡県", "region": "08"},
  {"id": "41", "name": "佐賀県", "region": "08"},
  {"id": "42", "name": "長崎県", "region": "08"},
  {"id": "43", "name": "熊本県", "region": "08"},
  {"id": "44", "name": "大分県", "region": "08"},
  {"id": "45", "name": "宮崎県", "region": "08"},
  {"id": "46", "name": "鹿児島県", "region": "08"},
  {"id": "47", "name": "沖縄県", "region": "08"},
  {"id": "48", "name": "海外", "region": "09"},
];

export const regions =
[
  {"id": "01", "name": "北海道"},
  {"id": "02", "name": "東北"},
  {"id": "03", "name": "関東"},
  {"id": "04", "name": "中部"},
  {"id": "05", "name": "近畿"},
  {"id": "06", "name": "中国"},
  {"id": "07", "name": "四国"},
  {"id": "08", "name": "九州"},
  {"id": "09", "name": "海外"},
]

export default towns;