/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserData = /* GraphQL */ `
  mutation CreateGolfHandicapTableRenewal(
    $input: CreateGolfHandicapTableRenewalInput!
  ) {
    createGolfHandicapTableRenewal(input: $input) {
      HashKey
      RangeKey
      UserID
      NickName
      MailAddress
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      Notice
      MatchesThisYear
      WinThisYear
      LoseThisYear
      DrawThisYear
      RateThisYear
      PointsThisYear
      AdvantageUserID
      AdvantageKumiawaseID
      AdvantageNickName
      AdvantagePoints
      AdvantageRate
      DisadvantageUserID
      DisadvantageKumiawaseID
      DisadvantageNickName
      DisadvantagePoints
      DisadvantageRate
      PreTaisenID
      PreHandicap
      PreMyScore
      PreResult
      PreTaisenDate
      PreFriendUser
      PreFriendNickName
      PreFriendScore
    }
  }
`;
export const createSerialNumberData = /* GraphQL */ `
  mutation CreateGolfHandicapTableRenewal(
    $input: CreateGolfHandicapTableRenewalInput!
  ) {
    createGolfHandicapTableRenewal(input: $input) {
      HashKey
      RangeKey
      Number
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
    }
  }
`;
export const createFriendData = /* GraphQL */ `
  mutation CreateGolfHandicapTableRenewal(
    $input: CreateGolfHandicapTableRenewalInput!
  ) {
    createGolfHandicapTableRenewal(input: $input) {
      HashKey
      RangeKey
      UserID
      NickName
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      KumiawaseID
      FriendUserID
      FriendNickName
      ShinseiKBN
      ShinseiDate
      Handicap
      HC_Value
      Rule
      Matches
      Win
      Lose
      Draw
      Rate
      Points
      MatchesThisYear
      WinThisYear
      LoseThisYear
      DrawThisYear
      RateThisYear
      PointsThisYear
    }
  }
`;
export const createKumiawaseData = /* GraphQL */ `
  mutation CreateGolfHandicapTableRenewal(
    $input: CreateGolfHandicapTableRenewalInput!
  ) {
    createGolfHandicapTableRenewal(input: $input) {
      HashKey
      RangeKey
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      KumiawaseID
      Player1UserID
      Player1NickName
      Player2UserID
      Player2NickName
      ShinseiKBN
      ShinseiDate
      Handicap
      HC_Value
      Rule
      Matches
      Player1Win
      Player1Lose
      Player1Points
      Player1Rate
      Player2Win
      Player2Lose
      Player2Points
      Player2Rate
      Draw
      MatchesThisYear
      Player1WinThisYear
      Player1LoseThisYear
      Player1PointsThisYear
      Player1RateThisYear
      Player2WinThisYear
      Player2LoseThisYear
      Player2PointsThisYear
      Player2RateThisYear
      DrawThisYear
      SensekiID
    }
  }
`;
export const createScoreData = /* GraphQL */ `
  mutation CreateGolfHandicapTableRenewal(
    $input: CreateGolfHandicapTableRenewalInput!
  ) {
    createGolfHandicapTableRenewal(input: $input) {
      HashKey
      RangeKey
      UserID
      NickName
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      KumiawaseID
      FriendUserID
      FriendNickName
      ShinseiKBN
      ShinseiDate
      TaisenID
      Handicap
      HC_Value
      Rule
      MyFirstScore
      MySecondScore
      MyTotalScore
      FriendsFirstScore
      FriendsSecondScore
      FriendsTotalScore
      Result
      Win
      Lose
      Draw
      Points
      GolfCourseName
    }
  }
`;
export const createTaisenData = /* GraphQL */ `
  mutation CreateGolfHandicapTableRenewal(
    $input: CreateGolfHandicapTableRenewalInput!
  ) {
    createGolfHandicapTableRenewal(input: $input) {
      HashKey
      RangeKey
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      KumiawaseID
      Date
      Player1UserID
      Player1NickName
      Player2UserID
      Player2NickName
      TaisenID
      Handicap
      HC_Value
      Rule
      Player1FirstScore
      Player1SecondScore
      Player1TotalScore
      Player2FirstScore
      Player2SecondScore
      Player2TotalScore
      Result
      Player1Win
      Player1Lose
      Player1Points
      Player2Win
      Player2Lose
      Player2Points
      Draw
      Win
      Lose
      Points
      GolfCourseName
    }
  }
`;
export const updateGolfHandicap = /* GraphQL */ `
  mutation UpdateGolfHandicapTableRenewal(
    $input: UpdateGolfHandicapTableRenewalInput!
  ) {
    updateGolfHandicapTableRenewal(input: $input) {
      HashKey
      RangeKey
      UserID
      NickName
      UserID
      NickName
      MailAddress
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      KumiawaseID
      FriendUserID
      FriendNickName
      ShinseiKBN
      ShinseiDate
      Notice
      TaisenID
      Handicap
      HC_Value
      Rule
      MyFirstScore
      MySecondScore
      MyTotalScore
      FriendsFirstScore
      FriendsSecondScore
      FriendsTotalScore
      Result
      Matches
      Win
      Lose
      Draw
      Rate
      Points
      GolfCourseID
      GolfCourseName
      MatchesThisYear
      WinThisYear
      LoseThisYear
      DrawThisYear
      RateThisYear
      PointsThisYear
      AdvantageUserID
      AdvantageKumiawaseID
      AdvantageNickName
      AdvantagePoints
      AdvantageRate
      DisadvantageUserID
      DisadvantageKumiawaseID
      DisadvantageNickName
      DisadvantagePoints
      DisadvantageRate
      PreTaisenID
      PreHandicap
      PreMyScore
      PreResult
      PreTaisenDate
      PreFriendUserID
      PreFriendNickName
      PreFriendsScore
      Player1UserID
      Player1NickName
      Player2UserID
      Player2NickName
      Player1Win
      Player1Lose
      Player1Rate
      Player1Points
      Player2Win
      Player2Lose
      Player2Rate
      Player2Points
      Player1WinThisYear
      Player1LoseThisYear
      Player1RateThisYear
      Player1PointsThisYear
      Player2WinThisYear
      Player2LoseThisYear
      Player2RateThisYear
      Player2PointsThisYear
      Date
      Player1FirstScore
      Player1SecondScore
      Player1TotalScore
      Player2FirstScore
      Player2SecondScore
      Player2TotalScore
    }
  }
`;
export const updateGolfHandicapBatch = /* GraphQL */ `
  mutation UpdateGolfHandicapTableRenewalBatch(
    $input: [UpdateGolfHandicapTableRenewalInput]!
  ) {
    updateGolfHandicapTableRenewalBatch(input: $input) {
      HashKey
      RangeKey
      UserID
      NickName
      UserID
      NickName
      MailAddress
      CreatedAt
      CreateUserID
      UpdatedAt
      UpdateUserID
      KumiawaseID
      FriendUserID
      FriendNickName
      ShinseiKBN
      ShinseiDate
      Notice
      TaisenID
      Handicap
      HC_Value
      Rule
      MyFirstScore
      MySecondScore
      MyTotalScore
      FriendsFirstScore
      FriendsSecondScore
      FriendsTotalScore
      Result
      Matches
      Win
      Lose
      Draw
      Rate
      Points
      GolfCourseID
      GolfCourseName
      MatchesThisYear
      WinThisYear
      LoseThisYear
      DrawThisYear
      RateThisYear
      PointsThisYear
      AdvantageUserID
      AdvantageKumiawaseID
      AdvantageNickName
      AdvantagePoints
      AdvantageRate
      DisadvantageUserID
      DisadvantageKumiawaseID
      DisadvantageNickName
      DisadvantagePoints
      DisadvantageRate
      PreTaisenID
      PreHandicap
      PreMyScore
      PreResult
      PreTaisenDate
      PreFriendUserID
      PreFriendNickName
      PreFriendsScore
      Player1UserID
      Player1NickName
      Player2UserID
      Player2NickName
      Player1Win
      Player1Lose
      Player1Rate
      Player1Points
      Player2Win
      Player2Lose
      Player2Rate
      Player2Points
      Player1WinThisYear
      Player1LoseThisYear
      Player1RateThisYear
      Player1PointsThisYear
      Player2WinThisYear
      Player2LoseThisYear
      Player2RateThisYear
      Player2PointsThisYear
      Date
      Player1FirstScore
      Player1SecondScore
      Player1TotalScore
      Player2FirstScore
      Player2SecondScore
      Player2TotalScore
    }
  }
`;
export const deleteGolfHandicap = /* GraphQL */ `
  mutation DeleteGolfHandicapTableRenewal(
    $input: DeleteGolfHandicapTableRenewalInput!
  ) {
    deleteGolfHandicapTableRenewal(input: $input) {
      HashKey
      RangeKey
    }
  }
`;
