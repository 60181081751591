<template>
  <div>
    <div v-if="loading">読み込み中...</div>
    <div class="friends_caption">
        <p><img src="@/assets/renewal/result.png"></p>
        <h2 class="title_fonts">戦績詳細</h2>
      </div>
      <div class="result_game">
        <h3 class="main_fonts">{{ result_Record.Date.slice(0,4) }}<span>年</span>{{ result_Record.Date.slice(5,7) }}<span>月</span> {{ result_Record.Date.slice(8,10) }}<span>日</span></h3>
        <h2 :style="{ fontSize: resizedFontSize((result_Record) ? result_Record.GolfCourseName : '', 7, 28, 0.95)}">{{ (result_Record.GolfCourseName)?result_Record.GolfCourseName:'未入力' }}</h2>
        <h1 class="title_fonts">{{ friend_Record.FriendNickName }}</h1>
        <h4 class="main_fonts">ユーザーID：{{ friend_Record.FriendUserID }}</h4>
      </div>
      <div class="gray_line"></div>
      <div class="result_detail_list main_fonts">
        <div class="result_score_line">
          <div class="result_score_caption">自分</div>
          <div class="result_score_value">{{ result_Record.MyFirstScore - ((result_Record.Handicap>0 && result_Record.Rule!=0 && result_Record.Rule!=1)?result_Record.Handicap:0) }}<span v-if="result_Record.Handicap>0 && result_Record.Rule!=0 && result_Record.Rule!=1"><br/>({{ result_Record.MyFirstScore }})</span></div>
          <div class="result_score_value">{{ result_Record.MySecondScore - ((result_Record.Handicap>0 && result_Record.Rule!=0 && result_Record.Rule!=1)?result_Record.Handicap:0) }}<span v-if="result_Record.Handicap>0 && result_Record.Rule!=0 && result_Record.Rule!=1"><br/>({{ result_Record.MySecondScore }})</span></div>
          <div class="result_score_value" v-if="result_Record.Rule==2 || result_Record.Rule==3">{{ result_Record.MyTotalScore - ((result_Record.Handicap>0)?result_Record.Handicap:0)*2 }}<span v-if="result_Record.Handicap>0"><br/>({{ result_Record.MyTotalScore }})</span></div>
          <div class="result_score_value" v-else>{{ result_Record.MyTotalScore - ((result_Record.Handicap>0)?result_Record.Handicap:0) }}<span v-if="result_Record.Handicap>0"><br/>({{ result_Record.MyTotalScore }})</span></div>
        </div>
        <div class="result_score_line">
          <div class="result_score_caption">相手</div>
          <div class="result_score_value">{{ result_Record.FriendsFirstScore - ((0>result_Record.Handicap && result_Record.Rule!=0 && result_Record.Rule!=1)?-result_Record.Handicap:0) }}<span v-if="0>result_Record.Handicap && result_Record.Rule!=0 && result_Record.Rule!=1">({{ result_Record.FriendsFirstScore }})</span></div>
          <div class="result_score_value">{{ result_Record.FriendsSecondScore - ((0>result_Record.Handicap && result_Record.Rule!=0 && result_Record.Rule!=1)?-result_Record.Handicap:0) }}<span v-if="0>result_Record.Handicap && result_Record.Rule!=0 && result_Record.Rule!=1">({{ result_Record.FriendsSecondScore }})</span></div>
          <div class="result_score_value" v-if="result_Record.Rule==2 || result_Record.Rule==3">{{ result_Record.FriendsTotalScore - ((0>result_Record.Handicap)?-result_Record.Handicap:0)*2 }}<span v-if="result_Record.Handicap>0">({{ result_Record.FriendsTotalScore }})</span></div>
          <div class="result_score_value" v-else>{{ result_Record.FriendsTotalScore - ((0>result_Record.Handicap)?-result_Record.Handicap:0) }}<span v-if="0>result_Record.Handicap">({{ result_Record.FriendsTotalScore }})</span></div>
        </div>
        <div class="result_score_line">
          <div class="result_score_caption">勝敗</div>
          <div :class="'result_score_judge ' + (result_Record.judge.indexOf(1) != -1 ? 'win' : (result_Record.judge.indexOf(4) != -1)?'lose':'')">{{ (result_Record.judge.indexOf(1) != -1 ? '○' : (result_Record.judge.indexOf(4) != -1)?'×':'-') }}</div>
          <div :class="'result_score_judge ' + (result_Record.judge.indexOf(2) != -1 ? 'win' : (result_Record.judge.indexOf(5) != -1)?'lose':'')">{{ (result_Record.judge.indexOf(2) != -1 ? '○' : (result_Record.judge.indexOf(5) != -1)?'×':'-') }}</div>
          <div :class="'result_score_judge ' + (result_Record.judge.indexOf(3) != -1 ? 'win' : (result_Record.judge.indexOf(6) != -1)?'lose':'')">{{ (result_Record.judge.indexOf(3) != -1 ? '○' : (result_Record.judge.indexOf(6) != -1)?'×':'-') }}</div>
        </div>
        <div class="result_score_line">
          <div class="result_score_rule">{{ result_Record.Rule>=2 ? '3' : '1' }}<span>勝制</span></div>
          <div :class="'result_score_totaljudge ' + (result_Record.Result=='Win'? 'win' : result_Record.Result=='Lose'?'lose':'')">{{ (result_Record.Result=='Win'? 'You Win' : result_Record.Result=='Lose'?'You Lose':'Draw') }}</div>
          <div class="result_score_judgecount">{{ getWinCount(result_Record, true) }}</div>
          <div class="result_score_judgehyphen">-</div>
          <div class="result_score_judgecount">{{ getWinCount(result_Record, false) }}</div>
        </div>
        <div class="result_score_line">
          <div class="result_score_caption_small"><span>今回</span>HC</div>
          <div class="result_score_handicap"><span>{{ (result_Record.Handicap>0) ? "自分に" : "相手に" }}</span>-{{ Math.abs(result_Record.Handicap).toFixed(1) }}</div>
        </div>
        <div class="result_score_line">
          <div class="result_score_caption_small"><span>次回</span>HC</div>
          <div class="result_score_handicap"><span>{{ (checkChangedHandicapDetail()>0) ? "自分に" : "相手に" }}</span>-{{ Math.abs(checkChangedHandicapDetail()).toFixed(1) }}</div>
        </div>
        <div class="result_score_line">
          <div class="result_score_caption_small"><span>更新日時</span></div>
          <div class="result_score_date">{{ result_Record.UpdatedAt.split("-")[0] + "-" + result_Record.UpdatedAt.split("-")[1] + "-" + result_Record.UpdatedAt.split("-")[2] + " " + result_Record.UpdatedAt.split("-")[3] + ":" + result_Record.UpdatedAt.split("-")[4]}}</div>
        </div>
        <div class="result_score_line">
          <div class="result_score_caption_small"><span>更新者</span></div>
          <div class="result_score_update"><span>{{ result_Record.UpdateUserID == "Admin" ? "-" : (result_Record.UpdateUserID == result_Record.UserID) ? result_Record.NickName : result_Record.FriendNickName }}</span></div>
        </div>
      </div>
      <div class="result_detail_buttons">
        <button v-on:mouseup="moveResultDetail(false)" v-bind:disabled="loading" :class="((can_move_before) ? '':'disabled')+' buttonicon_fonts'"><img src="@/assets/renewal/arrow_left.png"><br/>前回</button>
        <button v-on:mouseup="moveResultDetail(true)" v-bind:disabled="loading" :class="((can_move_after || nextToken_result) ? '':'disabled')+' buttonicon_fonts'"><img src="@/assets/renewal/arrow_right.png"><br/>次回</button>
        <button v-on:mouseup="pageMoveResultEdit()" v-bind:disabled="loading" class="buttonicon_fonts"><img src="@/assets/renewal/edit.png"><br/>修正</button>
        <button v-on:mouseup="removeRecords(result_Record)" v-bind:disabled="loading" class="buttonicon_fonts"><img src="@/assets/renewal/trash.png"><br/>削除</button>
      </div>
      <button class="result_back_button button_fonts" v-on:mouseup="openResultList(friend_Record)">戻る</button>
      <div class="advertise_space"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API

import _ from 'lodash'  // 配列を扱うモジュール

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

export default {
  name: 'Result_Detail',
  props: ['friend_Record', 'result_Record', 'nextToken_result', 'can_move_before', 'can_move_after'],
  data() {
    return {
      rate: 0,
      loading: false,
      windowWidth: window.innerWidth,
    }
  },
  mounted: function () {
    if (this.friend_Record.Matches > 0) {
      this.rate = this.friend_Record.WinThisYear / (this.friend_Record.WinThisYear + this.friend_Record.LoseThisYear) * 100
    }
    else {
      this.rate = 50
    }
    window.addEventListener('resize', this.updateWindowSize);
  },
  methods: {
    pageMove: function(pagename) {
      this.$emit('pageMove', pagename);
    },
    openResultList: function (friend) {
      this.$emit('openResultList', friend);
    },
    openResultDetail: function (result) {
      this.$emit('openResultDetail', result);
    },
    moveResultDetail: function (next) {
      this.$emit('moveResultDetail', next);
    },
    pageMoveResultEdit: function () {
      this.$emit('pageMoveResultEdit');
    },
    removeRecords: function (result) {
      this.$emit('removeRecords', result);
    },
    getWinCount(result_Record, self) {
      let judges = [];
      if (self) {
        judges = [1,2,3];
      }
      else {
        judges = [4,5,6];
      }
      let count = 0;
      for (let i = 0; i < judges.length; i++) {
        if (result_Record.judge.indexOf(judges[i]) != -1) {
          count++;
        }
      }
      return count;
    },
    checkChangedHandicapDetail() {
      let myNetScore = [];
      let friNetScore = [];
      let win = false;
      let lose = false;
      if (this.result_Record.Rule >= 2) {
        if (this.result_Record.Handicap >= 0) {
          myNetScore = [this.result_Record.MyFirstScore - this.result_Record.Handicap, this.result_Record.MySecondScore - this.result_Record.Handicap, this.result_Record.MyTotalScore - this.result_Record.Handicap*2];
          friNetScore = [this.result_Record.FriendsFirstScore, this.result_Record.FriendsSecondScore, this.result_Record.FriendsTotalScore];
        }
        else {
          myNetScore = [this.result_Record.MyFirstScore, this.result_Record.MySecondScore, this.result_Record.MyTotalScore];
          friNetScore = [this.result_Record.FriendsFirstScore + this.result_Record.Handicap, this.result_Record.FriendsSecondScore + this.result_Record.Handicap, this.result_Record.FriendsTotalScore + this.result_Record.Handicap*2];
        }
        let winCount = 0;
        let loseCount = 0;
        for (let i = 0; i < myNetScore.length; i++) {
          if (myNetScore[i] < friNetScore[i]) {
            winCount ++;
          }
          else if (myNetScore[i] > friNetScore[i]) {
            loseCount ++;
          }
        }
        if (winCount == 3) {
          win = true;
        }
        else if (loseCount == 3) {
          lose = true;
        }
      }
      else {
        if (this.result_Record.Handicap >= 0) {
          myNetScore = this.result_Record.MyTotalScore - this.result_Record.Handicap;
          friNetScore = this.result_Record.FriendsTotalScore;
        }
        else {
          myNetScore = this.result_Record.MyTotalScore;
          friNetScore = this.result_Record.FriendsTotalScore + this.result_Record.Handicap;
        }
        if (myNetScore < friNetScore) {
          win = true;
        }
        else if (myNetScore > friNetScore) {
          lose = true;
        }
      }
      if (win) {
        return this.result_Record.Handicap - this.result_Record.HC_Value;
      }
      else if (lose) {
        return this.result_Record.Handicap + this.result_Record.HC_Value;
      }
      else {
        return this.result_Record.Handicap;
      }
    },
    resizedFontSize: function(text, maxSize, minCount, unitMinus) {
      let size = maxSize;
      let count = this.getStringLength(text);
      if (count > minCount)
      {
        size *= unitMinus ** (count - minCount);
      }
      
      let unit = "vw";
      if (this.windowWidth >= 750)
      {
        size *= 7.5;
        unit = "px"
      }
      
      console.log(size + unit);
      
      return size + unit;
    },
    updateWindowSize() {
      this.windowWidth = window.innerWidth;
    },
    getStringLength(str) {
      let count = 0;
      for (let i = 0; i < str.length; i++)
      {
        let charCode = str.charCodeAt(i);
        if (charCode >= 0x00 && charCode <= 0xFF) {
          count += 1;
        }
        else
        {
          count += 2;
        }
      }
      return count;
    }
  }
}
</script>