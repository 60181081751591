<template>
  <div class="battle">
    <div v-if="loading">読み込み中...</div>
    <div class="game" v-if="page==''">
      <p class="back_image"></p>
      <div class="battle_caption">
        <p><img src="@/assets/renewal/score_w.png"></p>
        <h2 class="title_fonts">情報選択</h2>
      </div>
      <div class="white_line"></div>
      <div class="game_caption main_fonts">
        <p><img src="@/assets/renewal/date.png"></p>
        <h3>日付</h3>
      </div>
      <div class="game_value main_fonts" v-on:mouseup="switchHamburger('Date')">
        <h3>{{ year }}</h3><p>年</p><h3>{{ month }}</h3><p>月</p><h3>{{ day }}</h3><p>日</p>
      </div>
      <div class="game_caption main_fonts">
        <p><img src="@/assets/renewal/green.png"></p>
        <h3>ゴルフ場</h3>
      </div>
      <div class="game_value main_fonts" v-on:mouseup="switchHamburger('Golf')">
        <h3 class="game_course">{{ (golf_course) ? golf_course.GolfCourseName : "未選択" }}</h3>
      </div>
      <div class="game_caption main_fonts">
        <p><img src="@/assets/renewal/players.png"></p>
        <h3>対戦相手</h3>
      </div>
      <div class="game_value main_fonts" v-on:mouseup="switchHamburger('Friend')">
        <h3 class="game_enemy">{{ (selected_friends[0])?selected_friends[0].FriendNickName : "未選択" }}</h3>
        <p class="game_others" v-if="selected_friends.length >= 2">他{{ selected_friends.length - 1 }}名</p>
      </div>
      <button class="game_button button_fonts" v-on:mouseup="pageMove('score', golf_course && selected_friends.length)">スコア入力へ</button>
      <button class="game_back_button button_fonts" v-on:mouseup="pagePush('/')">戻る</button>
      <div class="advertise_space"></div>
    </div>
    <div class="score" v-if="page=='score'">
      <p class="back_image"></p>
      <div class="battle_caption">
        <p><img src="@/assets/renewal/score_w.png"></p>
        <h2 class="title_fonts">スコア入力</h2>
      </div>
      <div class="white_line"></div>
      <div class="score_game  main_fonts">
        <h3>{{ year }}<span>年</span>{{ month }}<span>月</span> {{ day }}<span>日</span></h3>
        <h2 :style="{ fontSize: resizedFontSize((golf_course) ? golf_course.GolfCourseName : '', 8, 20, 0.95)}">{{ (golf_course.GolfCourseName)?golf_course.GolfCourseName:'未入力' }}</h2>
      </div>
      <div class="score_container self main_fonts">
        <div class="score_name"><p>{{ self_nickname }}</p></div>
        <div class="score_line">
          <p><input type="number" min="0" class="input_score main_fonts" @change="onScoreChangeSelf()" v-model="s_out" inputmode="numeric" placeholder="前半"></p>
          <p><input type="number" min="0" class="input_score main_fonts" @change="onScoreChangeSelf()" v-model="s_in" inputmode="numeric" placeholder="後半"></p>
          <p>{{ s_total }}</p>
        </div>
      </div>
      <div class="score_container main_fonts" v-for="(friend, FriendUserID) in selected_friends" v-bind:key="FriendUserID">
        <div class="score_name"><p>{{ friend.FriendNickName }}</p></div>
        <div class="score_line">
          <p><input type="number" min="0" class="input_score main_fonts" @change="onScoreChange(friend)" v-model="friend.out_score" inputmode="numeric" value=null placeholder="前半"></p>
          <p><input type="number" min="0" class="input_score main_fonts" @change="onScoreChange(friend)" v-model="friend.in_score" inputmode="numeric" value=null placeholder="後半"></p>
          <p><span class="score_total">{{ friend.total_score }}</span></p>
        </div>
        <div class="score_line score_line_hc">
          <!--
          <p class="score_hcpm">
            <select v-model="friend.hc_Value">
              <option value="" disabled>── 増減値を選択 ──</option>
              <option v-for="(text,value) in options(0, 200, 2)" :value="parseFloat(text)/2" v-text="(parseFloat(text)/2).toFixed(1)" v-bind:key="value"></option>
            </select>
          </p>
          <p style="text-align: center;" class="score_hcpm_unit">
            <span v-if="friend.Rule == 0 || friend.Rule == 1">/18H</span>
            <span v-else>/9H</span>
          </p>
          -->
          <p class="score_hc"><span style="text-align: left">ＨＣ </span><span>{{ friend.Handicap.toFixed(1) }}/{{ (friend.Rule == 0 || friend.Rule == 1)?"18":"9" }}H</span></p>
          <p></p>
          <p class="score_next"><span style="text-align: left">次回 </span><span class="score_next_value">{{ checkChangedHandicap(friend, friend.Rule).toFixed(1) }}/{{ (friend.Rule == 0 || friend.Rule == 1)?"18":"9" }}H</span></p>
        </div>
      </div>
      <div class="score_error main_fonts" v-if="!checkAllInput()">未入力の項目があります。</div>
      <button class="score_button button_fonts" v-on:mouseup="submitRecords()" v-bind:disabled="loading || !checkAllInput()">入力確定</button>
      <button class="score_back_button button_fonts" v-on:mouseup="pageMove('', true)">戻る</button>
      <div class="advertise_space"></div>
    </div>
    <slide left :burgerIcon="false" :crossIcon="false" :isOpen="hamburger.Date" disableOutsideClick :width="getWindowWidth()">
      <div><h3 class="slide_caption title_fonts">日付選択</h3></div>
      <div class="game_caption_slide  main_fonts">
        <h3>年</h3>
      </div>
      <div class="game_value_slide main_fonts">
        <select class="gc_select dropdown main_fonts" v-model="selected_year" v-on:change="checkShortMonth()">
          <option v-for="(text,value) in options(2020,current_date.getFullYear(),4)" :value="value" v-text="text" v-bind:key="value"></option>
        </select>
      </div>
      <div class="game_caption_slide main_fonts">
        <h3>月</h3>
      </div>
      <div class="game_value_slide main_fonts">
        <select class="gc_select dropdown main_fonts" v-model="selected_month" v-on:change="checkShortMonth()">
            <option v-for="(text,value) in options(1, (selected_year != current_date.getFullYear()) ? 12 : current_date.getMonth() + 1, 2)" :value="value" v-text="text" v-bind:key="value"></option>
          </select>
      </div>
      <div class="game_caption_slide main_fonts">
        <h3>日</h3>
      </div>
      <div class="game_value_slide main_fonts">
        <select class="dropdown ver_date main_fonts" v-model="selected_day" v-if="[4,6,9,11].includes(parseInt(selected_month))">
            <option v-for="(text,value) in options(1, !(selected_year == current_date.getFullYear() && selected_month == current_date.getMonth()+1) ? 30 : current_date.getDate() , 2)" :value="value" v-text="text" v-bind:key="value"></option>
          </select>
          <select class="dropdown ver_date main_fonts" v-model="selected_day" v-else-if="selected_month==2 && selected_year%4==0">
            <option v-for="(text,value) in options(1, !(selected_year == current_date.getFullYear() && selected_month == current_date.getMonth()+1) ? 29 : current_date.getDate(), 2)" :value="value" v-text="text" v-bind:key="value"></option>
          </select>
          <select class="dropdown ver_date main_fonts" v-model="selected_day" v-else-if="selected_month==2">
            <option v-for="(text,value) in options(1, !(selected_year == current_date.getFullYear() && selected_month == current_date.getMonth()+1) ? 28 : current_date.getDate(), 2)" :value="value" v-text="text" v-bind:key="value"></option>
          </select>
          <select class="dropdown ver_date main_fonts" v-model="selected_day" v-else>
            <option v-for="(text,value) in options(1, !(selected_year == current_date.getFullYear() && selected_month == current_date.getMonth()+1) ? 31 : current_date.getDate(), 2)" :value="value" v-text="text" v-bind:key="value"></option>
          </select>
      </div>
      <div class="slide_button button_fonts" v-on:mouseup="confirmHamburger('Date')"><div>確定</div></div>
      <div class="slide_button button_fonts" v-on:mouseup="switchHamburger('Date')"><div>キャンセル</div></div>
    </slide>
    <slide left :burgerIcon="false" :crossIcon="false" :isOpen="hamburger.Golf" disableOutsideClick :width="getWindowWidth()">
      <div><h3 class="slide_caption title_fonts">ゴルフ場選択</h3></div>
      <div class="game_caption_slide main_fonts">
        <h3>地方</h3>
      </div>
      <div class="game_value_slide main_fonts">
        <select class="gc_select dropdown main_fonts" v-model="region" v-on:change="getTowns()" v-bind:disabled="loading">
          <option :value="null" disabled=true></option>
          <option v-for="r in regions" :value="r" v-text="r.name" v-bind:key="r.id"></option>
        </select>
      </div>
      <div class="game_caption_slide main_fonts">
        <h3>都道府県</h3>
      </div>
      <div class="game_value_slide main_fonts">
        <select class="gc_select dropdown main_fonts" v-model="town" v-on:change="getGolfCourses()" v-bind:disabled="loading">
          <option :value="null" disabled=true></option>
          <option v-for="t in towns_region" :value="t.id" v-text="t.name" v-bind:key="t.id"></option>
        </select>
      </div>
      <div class="game_caption_slide main_fonts">
        <h3>ゴルフ場</h3>
      </div>
      <div class="game_value_slide main_fonts">
        <select class="gc_select dropdown main_fonts" v-model="selected_gc" v-bind:disabled="golf_courses.length == 0 || loading">
          <option :value="null" disabled=true class="initial"></option>
          <option v-for="course in golf_courses" :value="course" v-text="course.GolfCourseYomigana != 'そのた' ? course.GolfCourseName : 'その他（リストに無いゴルフ場）'" v-bind:key="course.GolfCourseID" :class="(course.GolfCourseYomigana.length != 1) ? '' : 'initial'" :disabled="course.GolfCourseYomigana.length == 1">{{ course.GolfCourseName }}</option>
        </select>
      </div>
      <div class="game_caption_slide main_fonts" v-if="(selected_gc) && selected_gc.GolfCourseYomigana == 'そのた'">
        <h3>ゴルフ場名</h3>
      </div>
      <div class="game_value_slide main_fonts" v-if="(selected_gc) && selected_gc.GolfCourseYomigana == 'そのた'">
        <input type="text" class="gc_input main_fonts" v-model="input_gc" placeholder="ゴルフ場名を入力">
      </div>
      <div class="slide_button button_fonts" v-on:mouseup="confirmHamburger('Golf')"><div>確定</div></div>
      <div class="slide_button button_fonts" v-on:mouseup="switchHamburger('Golf')"><div>キャンセル</div></div>
    </slide>
    <slide left :burgerIcon="false" :crossIcon="false" :isOpen="hamburger.Friend" disableOutsideClick :width="getWindowWidth()">
      <div><h3 class="slide_caption title_fonts">対戦相手選択</h3></div>
      <div class="game_friend_slide main_fonts">
        <div class="game_friend_slide_top">
          <h3 class="game_friend_check_ex">選択</h3>
          <h3 class="game_friend_name">名前</h3>
          <h3 class="game_friend_rule_ex">ルール</h3>
        </div>
      </div>
      <div class="game_friend_slide main_fonts" v-for="(friend, FriendUserID) in friend_data" v-bind:key="FriendUserID">
        <label :for="friend.FriendUserID">
        <div class="game_friend_slide_top">
          <h3 class="game_friend_check"><input :id="friend.FriendUserID" type="checkbox" class="ver_friend_check" v-bind:value="friend" v-model="selected_friends_slide"></h3>
          <h3 class="game_friend_name">{{ friend.FriendNickName }}</h3>
          <h3 class="game_friend_rule">{{ (friend.Rule == 0 || friend.Rule == 1) ? "1勝制" : "3勝制" }}</h3>
        </div>
        </label>
      </div>
      <div class="slide_button button_fonts" v-on:mouseup="confirmHamburger('Friend')"><div>確定</div></div>
      <div class="slide_button button_fonts" v-on:mouseup="switchHamburger('Friend')"><div>キャンセル</div></div>
    </slide>
  </div>
</template>
<script>
/* eslint-disable */
/*対戦相手選択画面*/
import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API

import { getFriendRecord } from "@/graphql/queries"  // フレンドレコードを取得するquery
import { getKumiawaseRecord } from "@/graphql/queries"  // 組み合わせレコードを取得するquery
import { getScoreRecord } from "@/graphql/queries"	// スコアレコードを取得するquery
import { getNickName } from "@/graphql/queries"  // ニックネームのレコードを取得するquery
import { getTaisenRecord } from "@/graphql/queries"	// スコアレコードを取得するquery
import { listRecordOfGolfCourse } from "@/graphql/queries" // ゴルフコースのレコードを取得するquery
import { createSerialNumberData } from "@/graphql/mutations"  // 採番データを作成するmutation
import { createTaisenData } from "@/graphql/mutations"  // 対戦データを作成するmutation
import { createKumiawaseData } from "@/graphql/mutations"  // 組み合わせデータを作成するmutation
import { createScoreData } from "@/graphql/mutations"  // スコアデータを作成するmutation
import { updateGolfHandicap } from "../graphql/mutations"  // レコードを更新するmutation

import _ from 'lodash'  // 配列を扱うモジュール

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

import { regions } from '@/town-list.js'
import towns from '@/town-list.js'

export default {
  name: 'Battle',
  data () {
    return {
      page: "",  // 表示するページ
      self_nickname: "",  // 自分のニックネーム
      friend_data: [],  // 取得した組み合わせレコード配列
      friendUser: null,  // 対戦を行う相手との組み合わせレコード配列
      selected_friends: [],
      selected_friends_slide: [],
      current_date: null,
      year: "2022",  // 年
      selected_year: "2022",
      month: "12",  // 月
      selected_month: "12",
      day: "12",  // 日
      selected_day: "12",
      address: "",  // 都道府県
      golf_course: null,  // ゴルフ場
      selected_gc: null,
      input_gc: "",
      golf_courses: [],  // 取得したゴルフ場レコード配列
      s_out: null,  // 自分のアウトコーススコア
      s_in: null,  // 自分のインコーススコア
      s_total: 0,  // 自分の合計スコア
      f_out: null,  // 相手のアウトコーススコア
      f_in: null,  // 相手のインコーススコア
      f_total: 0,  // 相手の合計スコア
      regions: regions,
      region: "",
      towns: towns,
      towns_region: [],
      town: '',
      loading: false,
      vertical_layout: false,
      hamburger: {
        Date: false,
        Golf: false,
        Friend: false,
      },
      windowWidth: window.innerWidth,
    }
  },
  mounted: function () {
    this.page = "";  // ページ初期化
    this.getMyFriendRecord();  // 組み合わせレコード取得
    const date = new Date();  // 現在の日付時刻を取得
    this.current_date = date;
    this.year = date.getFullYear();  // 年月日の初期値入力
    this.selected_year = date.getFullYear();
    this.month = (date.getMonth() + 1);
    this.selected_month = (date.getMonth() + 1);
    this.day = date.getDate();
    this.selected_day = date.getDate();
    this.self_nickname = store.state.nickname;  // 自分のニックネーム格納
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('resize', this.updateWindowSize);
  },
  methods: {
    pageMove: function(pagename, conditions) {	// 表示切替
      if (conditions) {
        this.page = pagename;
      }
      else {
        alert("未入力の項目があります。");
      }
    },
    pagePush: function(pagename) {	// ページ遷移
      router.push({path: pagename})
    },
    changeCheck: function (friend) {
      const selected = this.selected_friends.indexOf(friend)
      if (selected != -1) {
        this.selected_friends.splice(selected, 1);
      }
      else {
        this.selected_friends.push(friend);
      }
    },
    getScoreRecord: async function(userid) {
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const searchData = {
        HashKey: {
          eq: userid,
        },
        RangeKey: {
          beginsWith: "T01"
        }
      }
      do {
        try {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(getScoreRecord, {query: searchData}));
            recordsArr = result.data.listGolfHandicapTableRenewalsQuery.items;
          } else {
            result = await API.graphql(graphqlOperation(getScoreRecord, {query: searchData, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsQuery.items);
          }
          nextToken = result.data.listGolfHandicapTableRenewalsQuery.nextToken;
        }catch(err) {
          console.log(err);
        }
      } while (nextToken != null)
      return recordsArr;
    },
    getTaisenRecord: async function() {
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const searchData = {
        HashKey: {
          eq: store.state.user.username,  // 完全一致
        },
        RangeKey: {
          beginsWith: "T01"  // 前方一致
        }
      }
      do {
        try {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(getTaisenRecord, {query: searchData}));
            recordsArr = result.data.listGolfHandicapTableRenewalsReverse.items;
          } else {
            result = await API.graphql(graphqlOperation(getTaisenRecord, {query: searchData, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsReverse.items);
          }
          nextToken = result.data.listGolfHandicapTableRenewalsReverse.nextToken;
        }catch(err) {
          console.log(err);
        }
      } while (nextToken != null)
      return recordsArr;
    },
    getFriendRecord: async function(userid) {  // 組み合わせレコード取得
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const searchData = {
        HashKey: {
          eq: userid,  // 完全一致
        },
        RangeKey: {
          beginsWith: "K01"  // 前方一致
        }
      }
      do {
        try {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(getFriendRecord, {query: searchData}));
            recordsArr = result.data.listGolfHandicapTableRenewalsQuery.items;
          } else {
            result = await API.graphql(graphqlOperation(getFriendRecord, {query: searchData, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsQuery.items);
          }
          nextToken = result.data.listGolfHandicapTableRenewalsQuery.nextToken;
        }catch(err) {
          console.log(err);
        }
      } while (nextToken != null)
      return recordsArr;
    },
    getKumiawaseRecord: async function(kid) {
      let result = null;
      const searchData = {
        HashKey: kid,
        RangeKey: "MST"
      }
      try {
        result = await API.graphql(graphqlOperation(getKumiawaseRecord, searchData));
      }catch(err) {
        console.log(err);
      }
      return result.data.getGolfHandicapTableRenewal;
    },
    getYearKumiawaseRecord: async function(kid, year) {
      let result = null;
      const searchData = {
        HashKey: kid,
        RangeKey: "S01" + year
      }
      try {
        result = await API.graphql(graphqlOperation(getKumiawaseRecord, searchData));
      }catch(err) {
        console.log(err);
      }
      return result.data.getGolfHandicapTableRenewal;
    },
    getTowns: function() {
      this.towns_region = _.filter(towns, (item) => {return item.region == this.region.id});
      if (this.towns_region.length == 1) {
        this.town = this.towns_region[0].id;
        this.getGolfCourses();
      }
    },
    getGolfCourses: async function() {  // ゴルフ場レコード取得
      this.loading = true;
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const query_data = {
        HashKey: {
          eq: "GolfCourseName",  // 完全一致
        },
        RangeKey: {
          beginsWith: "P01" + this.town
        }
      }
      do {
        try {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(listRecordOfGolfCourse, {query: query_data}));
            recordsArr = result.data.listGolfHandicapTableRenewalsQuery.items;
          } else {
            result = await API.graphql(graphqlOperation(listRecordOfGolfCourse, {query: query_data, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsQuery.items);
          }
          nextToken = result.data.listGolfHandicapTableRenewalsQuery.nextToken;
        }catch(err) {
          console.log(err);
        }
      } while (nextToken != null);
      for (let i = 0; i < recordsArr.length; i++)
      {
        if (recordsArr[i].GolfCourseYomigana.slice(0, 1) == "ヴ")
        {
          recordsArr[i].GolfCourseYomigana = recordsArr[i].GolfCourseYomigana.replace("ヴ", "う");
        }
      }
      recordsArr = _.orderBy(recordsArr, 'GolfCourseYomigana', 'asc');
      let initial = "";
      for (let i = 0; i < recordsArr.length; i++)
      {
        let initial_char = "";
        initial_char = recordsArr[i].GolfCourseYomigana.slice(0, 1).normalize('NFD')[0];
        if (initial != initial_char)
        {
          initial = initial_char;
          const params = {
            GolfCourseName: initial_char,
            GolfCourseYomigana: initial_char
          }
          recordsArr.splice(i, 0, params)
        }
      }
      recordsArr.push({
        GolfCourseID: "P01" + this.town + "_G9999999999",
        GolfCourseName: "",
        GolfCourseYomigana: "そのた"
      });
      this.golf_courses = recordsArr;
      this.loading = false;
    },
    getMyFriendRecord : async function() {  // 自分に関する組み合わせレコード全取得
      this.loading = true;
      const request_data = await this.getFriendRecord(store.state.user.username)
      console.log(request_data);
      let friend_data = _.filter(request_data, (item) => {return item.ShinseiKBN == 0});  // フレンド承認されている状態のフレンド
      for (let i = 0; i < friend_data.length; i++) {
         friend_data[i]['out_score'] = null;
         friend_data[i]['in_score'] = null;
         friend_data[i]['total_score'] = 0;
         friend_data[i]['hc_Value'] = friend_data[i].HC_Value;
      }
      this.friend_data = friend_data;  // 項目を追加した配列を格納
      this.loading = false;
    },
    submitRecords : async function () {
      if (this.checkInInputZero()) {
        if (!confirm("後半スコアが 0 となっています。よろしいでしょうか。")) {
          return;
        }
      }
      for (let i = 0; i < this.selected_friends.length; i++) {
        await this.submitRecord(this.selected_friends[i]);
      }
    },
    submitRecord : async function(friend) {  // 対戦レコードとスコアレコードの登録
      this.loading = true;
      const score_data = await this.getTaisenRecord();
      const input_date = this.year.toString() + "-" + this.month.toString().padStart(2, '0') + "-" + this.day.toString().padStart(2, '0');
      const same_date = _.filter(score_data, (item) => {return (item.Date == input_date && item.KumiawaseID == friend.KumiawaseID)});
      if (same_date.length == 0) {
        const kumi_data = await this.getKumiawaseRecord(friend.KumiawaseID);
        let player1_score = [];
        let player2_score = [];
        let handicap = kumi_data.Handicap;
        if (kumi_data.Player1UserID == store.state.user.username) {
          player1_score = [this.s_out, this.s_in, this.s_total];
          player2_score = [friend.out_score, friend.in_score, friend.total_score];
        }
        else {
          player1_score = [friend.out_score, friend.in_score, friend.total_score];
          player2_score = [this.s_out, this.s_in, this.s_total];
        }
        let rule = friend.Rule;
        if (player1_score[1] == 0 || player2_score[1] == 0) {
          rule = 1;
        }
        let judge = this.getJudgement(player1_score, player2_score, handicap, rule);
        let result = "Draw";
        if (judge[0] > judge[1]) {
          result = "Player1";
        }
        else if (judge[0] < judge[1]) {
          result = "Player2";
        }
        // 対戦データ作成
        const taisen_ID = "T" + friend.KumiawaseID.slice(-10) + this.year + this.month.toString().padStart(2, '0') + this.day.toString().padStart(2, '0');
        const taisen_data = {
          HashKey: taisen_ID,
          RangeKey: "MST",
          CreatedAt: this.getDateString(),
          CreateUserID: store.state.user.username,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          KumiawaseID: friend.KumiawaseID,
          Date: this.year + "-" + this.month.toString().padStart(2, '0') + "-" + this.day.toString().padStart(2, '0'),
          Player1UserID: kumi_data.Player1UserID,
          Player1NickName: kumi_data.Player1NickName,
          Player2UserID: kumi_data.Player2UserID,
          Player2NickName: kumi_data.Player2NickName,
          TaisenID: taisen_ID,
          Handicap: handicap,
          HC_Value: this.hc_value,
          Rule: rule,
          Player1FirstScore: player1_score[0],
          Player1SecondScore: player1_score[1],
          Player1TotalScore: player1_score[2],
          Player2FirstScore: player2_score[0],
          Player2SecondScore: player2_score[1],
          Player2TotalScore: player2_score[2],
          Result: result,
          Player1Win: (result == "Player1") ? 1 : 0,
          Player1Lose: (result == "Player2") ? 1 : 0,
          Draw: (result == "Draw") ? 1 : 0,
          Player1Points: judge[0] - judge[1],
          Player2Win: (result == "Player2") ? 1 : 0,
          Player2Lose: (result == "Player1") ? 1 : 0,
          Player2Points: judge[1] - judge[0],
          GolfCourseID: this.golf_course.GolfCourseID,
          GolfCourseName: this.golf_course.GolfCourseName,
        }
        await API.graphql(graphqlOperation(createTaisenData, {input: taisen_data}));
        
        // スコアレコード作成
        await this.createScoreRecord(kumi_data, taisen_ID, player1_score, player2_score, friend.hc_Value, judge, this.golf_course, input_date, rule);
        
        // ハンデ更新判定
        let hc_changed = (kumi_data.Player1UserID == store.state.user.username) ? this.checkChangedHandicap(friend, rule) : -this.checkChangedHandicap(friend, rule);
        
        // 組み合わせレコード更新
        let kumiawase_data = {
          HashKey: kumi_data.KumiawaseID,
          RangeKey: "MST",
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Handicap: hc_changed,
          HC_Value: friend.hc_Value,
          Matches: kumi_data.Matches + 1,
          Player1Win: kumi_data.Player1Win + ((result == "Player1") ? 1 : 0),
          Player1Lose: kumi_data.Player1Lose + ((result == "Player2") ? 1 : 0),
          Draw: kumi_data.Draw + ((result == "Draw") ? 1 : 0),
          Player1Points: kumi_data.Player1Points + judge[0] - judge[1],
          Player2Win: kumi_data.Player2Win + ((result == "Player2") ? 1 : 0),
          Player2Lose: kumi_data.Player2Lose + ((result == "Player1") ? 1 : 0),
          Player2Points: kumi_data.Player2Points + judge[1] - judge[0],
          Draw: kumi_data.Draw + ((result == "Draw") ? 1 : 0),
        }
        const date = new Date();
        if (this.year.toString() == date.getFullYear()) {
          kumiawase_data.MatchesThisYear = kumi_data.MatchesThisYear + 1;
          kumiawase_data.Player1WinThisYear = kumi_data.Player1WinThisYear + ((result == "Player1") ? 1 : 0);
          kumiawase_data.Player1LoseThisYear = kumi_data.Player1LoseThisYear + ((result == "Player2") ? 1 : 0);
          kumiawase_data.DrawThisYear = kumi_data.DrawThisYear + ((result == "Draw") ? 1 : 0);
          kumiawase_data.Player1PointsThisYear = kumi_data.Player1PointsThisYear + judge[0] - judge[1];
          kumiawase_data.Player2WinThisYear = kumi_data.Player2WinThisYear + ((result == "Player2") ? 1 : 0);
          kumiawase_data.Player2LoseThisYear = kumi_data.Player2LoseThisYear + ((result == "Player1") ? 1 : 0);
          kumiawase_data.Player2PointsThisYear = kumi_data.Player2PointsThisYear + judge[1] - judge[0];
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: kumiawase_data}));
        
        // 年次戦績レコードを確認
        const year_kumi_data = await this.getYearKumiawaseRecord(kumi_data.KumiawaseID, this.year);
        if (!year_kumi_data) {
          // 年次戦績レコードが無い場合は作成
          const year_kumi_create = {
            HashKey: kumi_data.KumiawaseID,
            RangeKey: "S01" + this.year,
            CreatedAt: this.getDateString(),
            CreateUserID: store.state.user.username,
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
            KumiawaseID: kumi_data.KumiawaseID,
            Player1UserID: kumi_data.Player1UserID,
            Player1NickName: kumi_data.Player1NickName,
            Player2UserID: kumi_data.Player2UserID,
            Player2NickName: kumi_data.Player2NickName,
            MatchesThisYear: 1,
            Player1Win: ((result == "Player1") ? 1 : 0),
            Player1Lose: ((result == "Player2") ? 1 : 0),
            Draw: ((result == "Draw") ? 1 : 0),
            Player1Points: judge[0] - judge[1],
            Player2Win: ((result == "Player2") ? 1 : 0),
            Player2Lose: ((result == "Player1") ? 1 : 0),
            Player2Points: judge[1] - judge[0],
            SensekiID: "S01" + this.year,
          }
          await API.graphql(graphqlOperation(createKumiawaseData, {input: year_kumi_create}));
        }
        else {
          // 年次戦績レコードがある場合は更新
          const year_kumi_update = {
            HashKey: kumi_data.KumiawaseID,
            RangeKey: year_kumi_data.SensekiID,
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
            Matches: year_kumi_data.MatchesThisYear + 1,
            Player1Win: (year_kumi_data.Player1Win ? year_kumi_data.Player1Win : 0) + ((result == "Player1") ? 1 : 0),
            Player1Lose: (year_kumi_data.Player1Lose ? year_kumi_data.Player1Lose : 0) + ((result == "Player2") ? 1 : 0),
            Draw: (year_kumi_data.Draw ? year_kumi_data.Draw : 0) + ((result == "Draw") ? 1 : 0),
            Player1Points: (year_kumi_data.Player1Points ? year_kumi_data.Player1Points : 0) + judge[0] - judge[1],
            Player2Win: (year_kumi_data.Player2Win ? year_kumi_data.Player2Win : 0) + ((result == "Player2") ? 1 : 0),
            Player2Lose: (year_kumi_data.Player2Lose ? year_kumi_data.Player2Lose : 0) + ((result == "Player1") ? 1 : 0),
            Player2Points: (year_kumi_data.Player2Points ? year_kumi_data.Player2Points : 0) + judge[1] - judge[0],
          }
          await API.graphql(graphqlOperation(updateGolfHandicap, {input: year_kumi_update}));
        }
        
        // フレンドレコード更新
        const p1_friend_update = {
          HashKey: kumi_data.Player1UserID,
          RangeKey: kumi_data.KumiawaseID,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Handicap: hc_changed,
          HC_Value: friend.hc_Value,
          Matches: kumi_data.Matches + 1,
          Win: kumi_data.Player1Win + ((result == "Player1") ? 1 : 0),
          Lose: kumi_data.Player1Lose + ((result == "Player2") ? 1 : 0),
          Draw: kumi_data.Draw + ((result == "Draw") ? 1 : 0),
          Points: kumi_data.Player1Points + judge[0] - judge[1],
        }
        if (this.year.toString() == date.getFullYear()) {
          p1_friend_update.MatchesThisYear = kumi_data.MatchesThisYear + 1;
          p1_friend_update.WinThisYear = kumi_data.Player1WinThisYear + ((result == "Player1") ? 1 : 0);
          p1_friend_update.LoseThisYear = kumi_data.Player1LoseThisYear + ((result == "Player2") ? 1 : 0);
          p1_friend_update.DrawThisYear = kumi_data.DrawThisYear + ((result == "Draw") ? 1 : 0);
          p1_friend_update.PointsThisYear = kumi_data.Player1PointsThisYear + judge[0] - judge[1];
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: p1_friend_update}));
        const p2_friend_update = {
          HashKey: kumi_data.Player2UserID,
          RangeKey: kumi_data.KumiawaseID,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Handicap: -hc_changed,
          HC_Value: friend.hc_Value,
          Matches: kumi_data.Matches + 1,
          Win: kumi_data.Player2Win + ((result == "Player2") ? 1 : 0),
          Lose: kumi_data.Player2Lose + ((result == "Player1") ? 1 : 0),
          Draw: kumi_data.Draw + ((result == "Draw") ? 1 : 0),
          Points: kumi_data.Player2Points + judge[1] - judge[0],
          MatchesThisYear: kumi_data.MatchesThisYear + 1,
          WinThisYear: kumi_data.Player2WinThisYear + ((result == "Player2") ? 1 : 0),
          LoseThisYear: kumi_data.Player2LoseThisYear + ((result == "Player1") ? 1 : 0),
          DrawThisYear: kumi_data.DrawThisYear + ((result == "Draw") ? 1 : 0),
          PointsThisYear: kumi_data.Player2PointsThisYear + judge[1] - judge[0],
        }
        if (this.year.toString() == date.getFullYear()) {
          p2_friend_update.MatchesThisYear = kumi_data.MatchesThisYear + 1;
          p2_friend_update.WinThisYear = kumi_data.Player2WinThisYear + ((result == "Player2") ? 1 : 0);
          p2_friend_update.LoseThisYear = kumi_data.Player2LoseThisYear + ((result == "Player1") ? 1 : 0);
          p2_friend_update.DrawThisYear = kumi_data.DrawThisYear + ((result == "Draw") ? 1 : 0);
          p2_friend_update.PointsThisYear = kumi_data.Player2PointsThisYear + judge[1] - judge[0];
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: p2_friend_update}));
        
        // ユーザーレコード更新
        const friend_userDB = await API.graphql(graphqlOperation(getNickName, {HashKey: friend.FriendUserID, RangeKey: "MST"}));
        let p1_userDB = null, p2_userDB = null;
        if (kumi_data.Player1UserID == store.state.user.username) {
          p1_userDB = store.state.userDB;
          p2_userDB = friend_userDB.data.getGolfHandicapTableRenewal;
        }
        else {
          p1_userDB = friend_userDB.data.getGolfHandicapTableRenewal;
          p2_userDB = store.state.userDB;
        }
        
        const p1_points = kumi_data.Player1PointsThisYear + judge[0] - judge[1];
        const p1_rate = (kumi_data.Player1WinThisYear + ((result == "Player1") ? 1 : 0)) / (kumi_data.Player1WinThisYear + kumi_data.Player1LoseThisYear + 1);
        let advantage = {};
        let disadvantage = {};
        if (((p1_userDB.AdvantagePoints < p1_points || !p1_userDB.AdvantagePoints) && p1_points > 0) || p1_userDB.AdvantageUserID == kumi_data.Player2UserID) {
          advantage = {
            AdvantageUserID: kumi_data.Player2UserID,
            AdvantageKumiawaseID: kumi_data.KumiawaseID,
            AdvantageNickName: kumi_data.Player2NickName,
            AdvantagePoints: p1_points,
            AdvantageRate: p1_rate
          };
        }
        else {
          advantage = {
            AdvantageUserID: p1_userDB.AdvantageUserID,
            AdvantageKumiawaseID: p1_userDB.AdvantageKumiawaseID,
            AdvantageNickName: p1_userDB.AdvantageNickName,
            AdvantagePoints: p1_userDB.AdvantagePoints,
            AdvantageRate: p1_userDB.AdvantageRate
          };
        }
        if (((p1_userDB.DisadvantagePoints > p1_points || !p1_userDB.DisadvantagePoints) && p1_points < 0) || p1_userDB.DisadvantageUserID == kumi_data.Player2UserID) {
          disadvantage = {
            DisadvantageUserID: kumi_data.Player2UserID,
            DisadvantageKumiawaseID: kumi_data.KumiawaseID,
            DisadvantageNickName: kumi_data.Player2NickName,
            DisadvantagePoints: p1_points,
            DisadvantageRate: p1_rate
          };
        }
        else {
          disadvantage = {
            DisadvantageUserID: p1_userDB.DisadvantageUserID,
            DisadvantageKumiawaseID: p1_userDB.DisadvantageKumiawaseID,
            DisadvantageNickName: p1_userDB.DisadvantageNickName,
            DisadvantagePoints: p1_userDB.DisadvantagePoints,
            DisadvantageRate: p1_userDB.DisadvantageRate
          };
        }
        const p1_user_update = {
          HashKey: kumi_data.Player1UserID,
          RangeKey: "MST",
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          AdvantageUserID: advantage.AdvantageUserID,
          AdvantageKumiawaseID: advantage.AdvantageKumiawaseID,
          AdvantageNickName: advantage.AdvantageNickName,
          AdvantagePoints: advantage.AdvantagePoints,
          AdvantageRate: advantage.AdvantageRate,
          DisadvantageUserID: disadvantage.DisadvantageUserID,
          DisadvantageKumiawaseID: disadvantage.DisadvantageKumiawaseID,
          DisadvantageNickName: disadvantage.DisadvantageNickName,
          DisadvantagePoints: disadvantage.DisadvantagePoints,
          DisadvantageRate: disadvantage.DisadvantageRate,
          PreTaisenID: taisen_ID,
          PreHandicap: handicap * ((rule <= 1) ? 1 : 2),
          PreMyScore: player1_score[2],
          PreResult: (result == "Player1") ? "Win" : ((result == "Player2") ? "Lose" : "Draw"),
          PreTaisenDate: this.year + "-" + this.month.toString().padStart(2, '0') + "-" + this.day.toString().padStart(2, '0'),
          PreFriendUserID: kumi_data.Player2UserID,
          PreFriendNickName: kumi_data.Player2NickName,
          PreFriendsScore: player2_score[2],
        }
        if (this.year.toString() == date.getFullYear()) {
          p1_user_update.MatchesThisYear = p1_userDB.MatchesThisYear + 1;
          p1_user_update.WinThisYear = p1_userDB.WinThisYear + ((result == "Player1") ? 1 : 0);
          p1_user_update.LoseThisYear = p1_userDB.LoseThisYear + ((result == "Player2") ? 1 : 0);
          p1_user_update.DrawThisYear = p1_userDB.DrawThisYear + ((result == "Draw") ? 1 : 0);
          p1_user_update.PointsThisYear = p1_points;
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: p1_user_update}));
        
        const p2_points = kumi_data.Player2PointsThisYear + judge[1] - judge[0];
        const p2_rate = (kumi_data.Player2WinThisYear + ((result == "Player2") ? 1 : 0)) / (kumi_data.Player2WinThisYear + kumi_data.Player2LoseThisYear + 1);
        if (((p2_userDB.AdvantagePoints < p2_points || !p2_userDB.AdvantagePoints) && p2_points > 0) || p2_userDB.AdvantageUserID == kumi_data.Player1UserID) {
          advantage = {
            AdvantageUserID: kumi_data.Player1UserID,
            AdvantageKumiawaseID: kumi_data.KumiawaseID,
            AdvantageNickName: kumi_data.Player1NickName,
            AdvantagePoints: p2_points,
            AdvantageRate: p2_rate
          };
        }
        else {
          advantage = {
            AdvantageUserID: p2_userDB.AdvantageUserID,
            AdvantageKumiawaseID: p2_userDB.AdvantageKumiawaseID,
            AdvantageNickName: p2_userDB.AdvantageNickName,
            AdvantagePoints: p2_userDB.AdvantagePoints,
            AdvantageRate: p2_userDB.AdvantageRate
          };
        }
        if (((p2_userDB.DisadvantagePoints > p2_points || !p2_userDB.DisadvantagePoints) && p2_points < 0) || p2_userDB.DisadvantageUserID == kumi_data.Player1UserID) {
          disadvantage = {
            DisadvantageUserID: kumi_data.Player1UserID,
            DisadvantageKumiawaseID: kumi_data.KumiawaseID,
            DisadvantageNickName: kumi_data.Player1NickName,
            DisadvantagePoints: p2_points,
            DisadvantageRate: p2_rate
          };
        }
        else {
          disadvantage = {
            DisadvantageUserID: p2_userDB.DisadvantageUserID,
            DisadvantageKumiawaseID: p2_userDB.DisadvantageKumiawaseID,
            DisadvantageNickName: p2_userDB.DisadvantageNickName,
            DisadvantagePoints: p2_userDB.DisadvantagePoints,
            DisadvantageRate: p2_userDB.DisadvantageRate
          };
        }
        const p2_user_update = {
          HashKey: kumi_data.Player2UserID,
          RangeKey: "MST",
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          MatchesThisYear: p2_userDB.MatchesThisYear + 1,
          WinThisYear: p2_userDB.WinThisYear + ((result == "Player2") ? 1 : 0),
          LoseThisYear: p2_userDB.LoseThisYear + ((result == "Player1") ? 1 : 0),
          DrawThisYear: p2_userDB.DrawThisYear + ((result == "Draw") ? 1 : 0),
          PointsThisYear: p2_points,
          AdvantageUserID: advantage.AdvantageUserID,
          AdvantageKumiawaseID: advantage.AdvantageKumiawaseID,
          AdvantageNickName: advantage.AdvantageNickName,
          AdvantagePoints: advantage.AdvantagePoints,
          AdvantageRate: advantage.AdvantageRate,
          DisadvantageUserID: disadvantage.DisadvantageUserID,
          DisadvantageKumiawaseID: disadvantage.DisadvantageKumiawaseID,
          DisadvantageNickName: disadvantage.DisadvantageNickName,
          DisadvantagePoints: disadvantage.DisadvantagePoints,
          DisadvantageRate: disadvantage.DisadvantageRate,
          PreTaisenID: taisen_ID,
          PreHandicap: -handicap * ((rule <= 1) ? 1 : 2),
          PreMyScore: player2_score[2],
          PreResult: (result == "Player2") ? "Win" : ((result == "Player1") ? "Lose" : "Draw"),
          PreTaisenDate: this.year + "-" + this.month.toString().padStart(2, '0') + "-" + this.day.toString().padStart(2, '0'),
          PreFriendUserID: kumi_data.Player1UserID,
          PreFriendNickName: kumi_data.Player1NickName,
          PreFriendsScore: player1_score[2],
        }
        if (this.year.toString() == date.getFullYear()) {
          p2_user_update.MatchesThisYear = p2_userDB.MatchesThisYear + 1;
          p2_user_update.WinThisYear = p2_userDB.WinThisYear + ((result == "Player2") ? 1 : 0);
          p2_user_update.LoseThisYear = p2_userDB.LoseThisYear + ((result == "Player1") ? 1 : 0);
          p2_user_update.DrawThisYear = p2_userDB.DrawThisYear + ((result == "Draw") ? 1 : 0);
          p2_user_update.PointsThisYear = p2_points;
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: p2_user_update}));
        const userDB = await API.graphql(graphqlOperation(getNickName, {HashKey: store.state.user.username, RangeKey: "MST"}));
        store.commit('setUserDB', userDB.data.getGolfHandicapTableRenewal);
        
        // ホームに戻る
        this.pagePush('/results');
      } else {
        if (same_date.length != 0) {
          alert(this.year + "/" + this.month.toString().padStart(2, '0') + "/" + this.day.toString().padStart(2, '0') + "の" + friend.FriendNickName + " さんとのスコアは既に入力済みです。");
        }
      }
      this.loading = false;
    },
    getJudgement: function(p1_score, p2_score, p1_handicap, rule) {
      let p1_win = 0, p2_win = 0;
      let p1_hc = (p1_handicap > 0) ? p1_handicap : 0;
      let p2_hc = (p1_handicap < 0) ? -p1_handicap : 0;
      if (rule <= 1) {
        // １勝制
        if (p1_score[2] - p1_hc < p2_score[2] - p2_hc) {
          p1_win = 1;
        }
        else if (p1_score[2] - p1_hc > p2_score[2] - p2_hc) {
          p2_win = 1;
        }
      }
      else {
        // ３勝制
        // アウト
        if (p1_score[0] - p1_hc < p2_score[0] - p2_hc) {
          p1_win++;
        }
        else if (p1_score[0] - p1_hc > p2_score[0] - p2_hc) {
          p2_win++
        }
        // イン
        if (p1_score[1] - p1_hc < p2_score[1] - p2_hc) {
          p1_win++;
        }
        else if (p1_score[1] - p1_hc > p2_score[1] - p2_hc) {
          p2_win++
        }
        // トータル
        if (p1_score[2] - p1_hc*2 < p2_score[2] - p2_hc*2) {
          p1_win++;
        }
        else if (p1_score[2] - p1_hc*2 > p2_score[2] - p2_hc*2) {
          p2_win++
        }
      }
      return [p1_win, p2_win];
    },
    createScoreRecord : async function(k_data, taisen_ID, p1_score, p2_score, hc_value, judge, golf_course, date, rule) {  // スコアレコードの作成
      let p1_result = "Draw";
      let p2_result = "Draw";
      if (judge[0] > judge[1]) {
        p1_result = "Win";
        p2_result = "Lose";
      }
      else if (judge[0] < judge[1]) {
        p2_result = "Win";
        p1_result = "Lose";
      }
      // 入力データ
      const p1_data = {
        HashKey: k_data.Player1UserID,
        RangeKey: taisen_ID,
        UserID: k_data.Player1UserID,
        NickName: k_data.Player1NickName,
        CreatedAt: this.getDateString(),
        CreateUserID: store.state.user.username,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        KumiawaseID: k_data.KumiawaseID,
        FriendUserID: k_data.Player2UserID,
        FriendNickName: k_data.Player2NickName,
        ShinseiKBN: 0,
        ShinseiDate: this.getDateString(),
        TaisenID: taisen_ID,
        Handicap: k_data.Handicap,
        HC_Value: hc_value,
        Rule: rule,
        MyFirstScore: p1_score[0],
        MySecondScore: p1_score[1],
        MyTotalScore: p1_score[2],
        FriendsFirstScore: p2_score[0],
        FriendsSecondScore: p2_score[1],
        FriendsTotalScore: p2_score[2],
        Result: p1_result,
        Win: (p1_result == "Win") ? 1 : 0,
        Lose: (p2_result == "Win") ? 1 : 0,
        Draw: (p1_result == "Draw") ? 1 : 0,
        Points: judge[0] - judge[1],
        GolfCourseID: golf_course.GolfCourseID,
        GolfCourseName: golf_course.GolfCourseName,
        Date: date,
      }
      const p2_data = {
        HashKey: k_data.Player2UserID,
        RangeKey: taisen_ID,
        UserID: k_data.Player2UserID,
        NickName: k_data.Player2NickName,
        CreatedAt: this.getDateString(),
        CreateUserID: store.state.user.username,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        KumiawaseID: k_data.KumiawaseID,
        FriendUserID: k_data.Player1UserID,
        FriendNickName: k_data.Player1NickName,
        ShinseiKBN: 0,
        ShinseiDate: this.getDateString(),
        TaisenID: taisen_ID,
        Handicap: -k_data.Handicap,
        HC_Value: hc_value,
        Rule: rule,
        MyFirstScore: p2_score[0],
        MySecondScore: p2_score[1],
        MyTotalScore: p2_score[2],
        FriendsFirstScore: p1_score[0],
        FriendsSecondScore: p1_score[1],
        FriendsTotalScore: p1_score[2],
        Result: p2_result,
        Win: (p2_result == "Win") ? 1 : 0,
        Lose: (p1_result == "Win") ? 1 : 0,
        Draw: (p2_result == "Draw") ? 1 : 0,
        Points: judge[1] - judge[0],
        GolfCourseID: golf_course.GolfCourseID,
        GolfCourseName: golf_course.GolfCourseName,
        Date: date,
      }
      await API.graphql(graphqlOperation(createScoreData, {input: p1_data}));
      await API.graphql(graphqlOperation(createScoreData, {input: p2_data}));
    },
    checkRuleCount_InOut() {
      return _.filter(this.selected_friends, (item) => {return (item.Rule == 2 || item.Rule == 3)}).length;
    },
    checkAllInput() {
      let score_input = [this.s_total];
      if (this.checkRuleCount_InOut()) {
        score_input.push(this.s_out);
        score_input.push(this.s_in);
      }
      for (let i = 0; i < this.selected_friends.length; i++) {
        score_input.push(this.selected_friends[i].out_score);
        score_input.push(this.selected_friends[i].in_score);
        score_input.push(this.selected_friends[i].total_score);
      }
      return !(score_input.includes("") || score_input.includes(null));
    },
    checkInInputZero() {
      let in_score = [this.s_in]
      for (let i = 0; i < this.selected_friends.length; i++) {
        in_score.push(this.selected_friends[i].in_score);
      }
      return in_score.includes(0) || in_score.includes("0");
    },
    onScoreChange(user) {  // 3勝制の場合、前後半に変更を加えると合計が自動で変更される
      user.out_score = (user.out_score >= 0) ? user.out_score : -user.out_score;
      user.in_score = (user.in_score >= 0) ? user.in_score : -user.in_score;
      const o_score = (user.out_score) ? user.out_score:0;
      const i_score = (user.in_score) ? user.in_score:0;
      user.total_score = parseInt(o_score) + parseInt(i_score);
    },
    onScoreChangeSelf() {
      this.s_out = (this.s_out >= 0) ? this.s_out : -this.s_out;
      this.s_in = (this.s_in >= 0) ? this.s_in : -this.s_in;
      const s_out = (this.s_out) ? this.s_out : 0;
      const s_in = (this.s_in) ? this.s_in : 0;
      this.s_total = parseInt(s_out) + parseInt(s_in);
    },
    checkShortMonth() {
      if (this.selected_year >= this.current_date.getFullYear()) {
        if (this.selected_month >= this.current_date.getMonth()+1) {
          this.selected_month = this.current_date.getMonth()+1;
          if (this.selected_day > this.current_date.getDate()) {
            this.selected_day = this.current_date.getDate();
          }
        }
      }
      let maxDate = 31;
      if ([4,6,9,11].includes(parseInt(this.selected_month))) {
        maxDate = 30;
      }
      else if (this.selected_month == 2){
        if (this.selected_year % 4 == 0) {
          maxDate = 29;
        }
        else {
          maxDate = 28;
        }
      }
      if (this.selected_day > maxDate) {
        this.selected_day = maxDate.toString();
      }
    },
    checkChangedHandicap(friend, rule) {
      let myNetScore = [];
      let friNetScore = [];
      let win = false;
      let lose = false;
      if (rule >= 2) {
        if (friend.Handicap >= 0) {
          myNetScore = [this.s_out - friend.Handicap, this.s_in - friend.Handicap, this.s_total - friend.Handicap*2];
          friNetScore = [parseInt(friend.out_score), parseInt(friend.in_score), friend.total_score];
        }
        else {
          myNetScore = [parseInt(this.s_out), parseInt(this.s_in), this.s_total];
          friNetScore = [parseInt(friend.out_score) + friend.Handicap, parseInt(friend.in_score) + friend.Handicap, friend.total_score + friend.Handicap*2];
        }
        let winCount = 0;
        let loseCount = 0;
        for (let i = 0; i < myNetScore.length; i++) {
          if (myNetScore[i] < friNetScore[i]) {
            winCount ++;
          }
          else if (myNetScore[i] > friNetScore[i]) {
            loseCount ++;
          }
        }
        if (winCount == 3) {
          win = true;
        }
        else if (loseCount == 3) {
          lose = true;
        }
      }
      else {
        if (friend.Handicap >= 0) {
          myNetScore = this.s_total - friend.Handicap;
          friNetScore = friend.total_score;
        }
        else {
          myNetScore = this.s_total;
          friNetScore = friend.total_score + friend.Handicap;
        }
        if (myNetScore < friNetScore) {
          win = true;
        }
        else if (myNetScore > friNetScore) {
          lose = true;
        }
      }
      if (win) {
        return friend.Handicap - friend.hc_Value;
      }
      else if (lose) {
        return friend.Handicap + friend.hc_Value;
      }
      else {
        return friend.Handicap;
      }
    },
    getDateString () {
      const date = new Date();
      const date_str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getHours() + "-" + date.getMinutes();
      return date_str;
    },
    options(minValue, maxValue, digit) {  // プルダウン用
      let options = {};
      
      for (let i = minValue; i <= maxValue; i++) {
        options[i] = i.toString().padStart(digit, '0');
      }
      return options;
    },
    confirmHamburger: function (menu) {
      switch(menu) {
        case "Date":   this.year = this.selected_year;
                       this.month = this.selected_month;
                       this.day = this.selected_day;
                       break;
        case "Golf":   this.golf_course = this.selected_gc;
                       if (this.selected_gc.GolfCourseYomigana == "そのた") {
                         this.golf_course.GolfCourseName = this.input_gc;
                       }
                       break;
        case "Friend": this.selected_friends = [];
                       for (let i = 0; i < this.selected_friends_slide.length; i++) {
                         this.selected_friends.push(this.selected_friends_slide[i]);
                       }
                       break;
      }
      this.hamburger[menu] = !this.hamburger[menu];
    },
    switchHamburger: function (menu) {
      this.hamburger[menu] = !this.hamburger[menu];
    },
    handleResize: function() {
      if (document.body.clientWidth <= 750) {
        this.vertical_layout = true;
      }
      else {
        this.vertical_layout = false;
      }
    },
    getWindowWidth() {
      return (document.body.clientWidth * 0.9).toString(10);
    },
    resizedFontSize: function(text, maxSize, minCount, unitMinus) {
      let size = maxSize;
      let count = this.getStringLength(text);
      if (count > minCount)
      {
        size *= unitMinus ** (count - minCount);
      }
      
      let unit = "vw";
      if (this.windowWidth >= 750)
      {
        size *= 7.5;
        unit = "px"
      }
      
      console.log(size + unit);
      
      return size + unit;
    },
    updateWindowSize() {
      this.windowWidth = window.innerWidth;
    },
    getStringLength(str) {
      let count = 0;
      for (let i = 0; i < str.length; i++)
      {
        let charCode = str.charCodeAt(i);
        if (charCode >= 0x00 && charCode <= 0xFF) {
          count += 1;
        }
        else
        {
          count += 2;
        }
      }
      return count;
    }
  }
}
</script>
<style>
.battle {
  text-shadow: 0px 0px 2vw rgb(0, 0, 0),
               0px 0px 1vw rgb(0, 0, 0);
}
.battle .game,.battle .score {
  padding-top: 14%;
  background-size: cover;
  position: relative;
  z-index: 0;
}
.battle .game {
  background-image: url('../assets/renewal/ngr_battle_game_background.jpg');
}
.battle .score {
  background-image: url('../assets/renewal/ngr_battle_score_background.png');
}
.battle .back_image {
  content: "";
  display: block;
  position: absolute;
  background: inherit;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
}
.battle .game p,.battle .score p {
  margin: 0;
}
.battle .battle_caption{
  background-color: rgba(34, 34, 34, 0);
  text-align: center;
  width: 85%;
  margin: 5% auto 5% auto;
}
.battle .battle_caption * {
  z-index: 2;
}
.battle .battle_caption p, .battle .battle_caption h2 {
  display: inline-block;
  text-align: center;
  margin: 0 0 0 0;
}
.battle .battle_caption p {
  width: 12.5%;
}
.battle .battle_caption img {
  width: 100%;
  filter: drop-shadow(0px 0px 1vw rgba(0, 0, 0, 1));
}
.battle .battle_caption h2 {
  text-align: left;
  vertical-align: 20%;
  padding-left: 4%;
}
.battle .white_line {
  background-color: #FAFAFA;
  width: 90%;
  height: 2px;
  margin: 5% auto 0 auto;
}
.battle .game_caption {
  text-align: center;
  background-color: rgba(34, 34, 34, 0.5);
  width: 90%;
  margin: 5% auto 0% auto;
  border-bottom: solid 1px #FAFAFA;
  border-radius: 2vw 2vw 0 0;
}
.battle .game_caption p, .battle .game_caption h3 {
  display: inline-block;
  margin: 1% 0 1% 0;
}
.battle .game_caption p {
  width: 10%;
}
.battle .game_caption img {
  width: 100%;
  filter: drop-shadow(0px 0px 1vw rgba(0, 0, 0, 0.5));
}
.battle .game_caption h3 {
  text-align: left;
  vertical-align: 30%;
  padding-left: 4%;
}
.battle .game_value {
  background-color: rgba(34, 34, 34, 0.22);
  width: 90%;
  margin: 0% auto auto auto;
  padding-bottom: 2vw;
}
.battle .game_value h3,.battle .game_value p {
  display: inline-block;
}
.battle .game_value h3 {
  margin-top: 3%;
  margin-bottom: 0%;
}
.battle .game_value p {
  margin-right: 2%;
}
.battle .game_others {
  text-align: right;
  display: block !important;
  margin-top: 0;
  padding-bottom: 1.5%;
  position: relative;
  top: 2vw
}
.battle .game_button,.battle .score_button {
  background-color: #5294D1;
  color: #FAFAFA;
  font-weight: bold;
  width: 90%;
  display: block;
  padding-top: 3vw;
  padding-bottom: 3vw;
  border: none;
  border-radius: 1vw;
}
.battle .game_button {
  margin: 5% auto 0 auto;
}
.battle .score_button {
  margin: 5% auto 0 auto;
}
.battle .score_button:disabled {
  background-color: #BFBFBF;
}
.battle .game_back_button,.battle .score_back_button {
  background-color: #FAFAFA;
  color: #5294D1;
  font-weight: bold;
  width: 90%;
  display: block;
  margin: 5% auto 0 auto;
  padding-top: 3vw;
  padding-bottom: 3vw;
  border: solid 1px #5294D1;
  border-radius: 1vw;
}
.battle .advertise {
  width: 50%;
  height: 2.5%;
  background-color: #0088FF;
  color: #212121;
  border: 1px solid #212121;
  margin: 15% auto 0 auto;
  line-height: 275%;
  text-shadow: 0px 0px 0px rgb(0, 0, 0);
}
.battle .score_game {
  background-color: rgba(34, 34, 34, 0.25);
  width: 90%;
  margin: 0 auto 0 auto;
}
.battle .score_game h3 {
  line-height: 1.25;
  padding: 5% 0 0 0;
  margin-bottom: 0%;
}
.battle .score_game h3 span {
  margin: 0 2% 0 1%;
}
.battle .score_game h2 {
  line-height: 1.25;
  margin-top: 0%;
  padding-left:5%;
  padding-right:5%;
  padding-bottom: 5%
}
.battle .score_column {
  width: 90%;
  background-color: rgba(34, 34, 34, 0.5);
  border-radius: 2vw 2vw 0 0;
  margin: 2% auto 2% auto;
  padding-top: 1%;
}
.battle .score_name {
  margin-top: 1%;
  padding: 1% 0 2% 0;
  line-height: 0.75;
}
.battle .score_column .score_line {
  display: flex;
  padding: 1% 0.5% 1% 0.5%;
}
.battle .score_line p{
  width: 22%;
  text-align: center;
}
.battle .score_line .score_hc{
  width: 40%;
}
.battle .score_hc {
  text-align: right !important;
  position: relative;
  right: 1vw;
  bottom: -0.5vw;
  display: grid;
  grid-template-columns: 35% 65%;
}
.battle .score_hcpm {
  margin-top: 1% !important;
}
.battle .score_next {
  text-align: right !important;
  position: relative;
  right: 1vw;
  bottom: -0.75vw;
  display: grid;
  grid-template-columns: 35% 65%;
}
.battle .score_next_value {
  text-align: right;
  display: inline-block;
}
.battle .score_white_line {
  width: 85%;
  height: 1px;
  background-color: #FAFAFA;
  margin: auto;
}
.battle .score_container {
  width: 80%;
  background-color: rgba(34, 34, 34, 0.25);
  margin: 0 auto 0 auto;
  padding: 5%;
  border-bottom: solid 1px #FAFAFA;
}
.battle .score_container .score_line {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  margin-bottom: 1.1%;
}
.battle .score_container .score_line_hc {
  display: grid;
  grid-template-columns: 45% 10% 45%;
  margin-top: 0;
  margin-bottom: 0;
}
.battle .score_container input {
  color: #FAFAFA;
  text-align: center;
  width: 100%;
  height: 80%;
  padding-block: 0;
  padding-inline: 0;
  position: relative;
  bottom: -0.75vw;
  border: solid 1px #212121;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1vw;
  box-sizing: border-box;
  -webkit-appearance: none;
}
.battle .score_container input::placeholder {
  position: relative;
  top: -0.5vw;
}
.score_hcpm_unit {
  position: relative;
  left: -2vw;
  bottom: -0.5vw;
}
.battle .score_container select {
  color: #FAFAFA;
  text-align: center;
  width: 100%;
  height: 80%;
  position: relative;
  bottom: -0.5vw;
  border: solid 1px #212121;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1vw;
  text-shadow: 0px 0px 1vw rgb(0, 0, 0);
  margin: 0;
  -webkit-appearance: none;
}
.battle .score_container .score_line p {
  width: 100%;
  line-height: 10vw;
}
.gc_input {
  width: 100%;
  text-align: center;
  font-weight: bold;
}
.battle .score_container .score_line .score_hcpm span {
  margin-left: 5%;
}
.battle .score_error {
  color: #FF7676;
  width: 80%;
  background-color: rgba(34, 34, 34, 0.5);
  padding: 5% 5% 5% 5%;
  margin: 7% auto 7% auto;
}
.slide {
  margin-bottom: 10px;
  padding: 3px !important;
  overflow-y: scroll;
  white-space: pre;
  display: block !important;
  border: solid 1px gray;
  width: 98%;
  height: 90%;
}
.slide::-webkit-scrollbar{ 
    width: 15px;
}
.slide::-webkit-scrollbar-thumb{ 
    background: #c1c1c1;
}
.slide::-webkit-scrollbar-track-piece:start{
    background: #f1f1f1;
}
.slide::-webkit-scrollbar-track-piece:end{
    background: #f1f1f1;
}
.battle .bm-item-list {
  height: 50% !important;
  text-shadow: 0px 0px 0px rgb(0, 0, 0);
}
.battle .padding {
  height: 64px;
}
.battle .bm-item-list {
  margin: auto;
}
.battle .game_caption_slide {
  text-align: center;
  background-color: #BFBFBF;
  width: 77vw;
  margin: 7% auto 0% auto;
  padding: 0 4% 0 4%;
  border-bottom: solid 1px #FAFAFA;
  border-radius: 2vw 2vw 0 0;
}
.battle .game_caption_slide h3 {
  width: 100%;
  margin: 1% 0 1% 0;
}
.battle .game_caption_slide h3 {
  text-align: center;
  vertical-align: 30%;
}
.battle .game_value_slide {
  background-color: #FAFAFA;
  width: 77vw;
  margin: 0% auto auto auto;
  border-radius: 0 0 2vw 2vw;
}
.battle .game_value_slide select {
  background-color: #FAFAFA;
  text-align: center;
  font-weight: bold;
  width: 100%;
  height: auto;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.battle .game_value_slide select:focus {
  outline: none;
}
.battle .slide_caption {
  text-align: center;
  width: 77vw;
  margin: auto;
}
.battle .slide_button {
  background-color: #FAFAFA;
  font-weight: bold;
  text-align: center;
  width: 77vw;
  margin: 8% auto 0% auto;
}
.battle .slide_button:hover {
  background-color: #EFEFEF;
}
.battle .slide_button div {
  width: 100%;
}
.battle .game_friend_slide {
  background-color: #FAFAFA;
  width: 80vw;
  margin: auto;
  padding: 3% 2% 3% 2%;
  display: block;
}
.battle .game_friend_slide_top, .battle .game_friend_slide_bottom {
  width: 100%;
  display: flex;
}
.battle .game_friend_slide h3 {
  margin: 1% 0 1% 0;
}
.battle .game_friend_check {
  width: 15%;
  text-align: center;
  padding-right: 0%;
  vertical-align: middle;
}
.battle .game_friend_check input[type="checkbox"] {
  transform:scale(2.0);
  position: relative;
  top: 0.5vw;
}
.battle .game_friend_check_ex {
  width: 20%;
  text-align: left;
  padding-left: 3%;
}
.battle .game_friend_name {
  width: 55%;
  padding-left: 3%;
}
.battle .game_friend_rule {
  width: 30%;
}
.battle .game_friend_rule_ex {
  width: 30%;
  padding-left: 7%;
  padding-right: 3%;
}
.battle .game_friend_handicap {
  width: 20%;
}
.battle .game_friend_results {
  width: 60%;
}
.battle .game_friend_result {
  width: 16%;
}
.battle .padding {
  height: 2vw;
}
.battle .score_line option {
  color: #212121 !important;
}
@media screen and (min-width: 750px) {
  .battle .game_button,.battle .score_button  {
    border-radius: 7.5px;
    padding-top: 22.5px;
    padding-bottom: 22.5px;
  }
  .battle .game_back_button,.battle .score_back_button {
    border-radius: 7.5px;
    padding-top: 22.5px;
    padding-bottom: 22.5px;
  }
  .battle .game_value {
    padding-bottom: 15px;
  }
  .battle .score_name {
    padding: 1% 0 1% 0;
  }
  .battle .score_container .score_line {
    height: 70px;
  }
  .battle .score_container input {
    height: 60px;;
    border-radius: 7.5px;
    position: relative;
    top: -5px;
  }
  .battle .score_container select {
    height: 60px;
    position: relative;
    top: -5px;
  }
  .battle .score_container input::placeholder {
    position: relative;
    left: 7px;
  }
  .battle .score_container select {
    border-radius: 7.5px;
  }
  .battle .score_container .score_line p {
    line-height: 45px;
  }
  .battle .score_container .score_line .score_hcpm span {
    margin-left: 5%;
  }
  .battle .game_caption_slide {
    width: 577.5px;
    border-radius: 15px 15px 0 0;
  }
  .battle .game_value_slide {
    width: 577.5px;
    border-radius: 0 0 15px 15px;
  }
  .battle .slide_caption {
    width: 577.5px;
  }
  .battle .slide_button {
    width: 577.5px;
  }
  .battle .game_friend_slide {
    width: 100%;
  }
}
</style>