<template>
  <div class="friends">
    <div v-if="loading">読み込み中...</div>
    <div class="friend_list" v-if="page==''">
      <div class="friends_caption">
        <p><img src="@/assets/renewal/friend.png"></p>
        <h2 class="title_fonts">フレンド一覧</h2>
      </div>
      <div v-if="request_data.length > 0">
        <p class="friends_nortice main_fonts">新着のフレンド申請が<span>{{ request_data.length }}</span>件あります</p>
        <div class="friend_r_list_line main_fonts" v-for="(request, ID) in request_data" v-bind:key="ID">
          <h4>{{ request.FriendNickName }}</h4>
          <div class="friend_r_list_name">
            <div class="frield_list_params">
              <p class="friend_r_list_hc"><span class="left_align">HC：</span><span class="half_chara">{{ request.Handicap.toFixed(1) }}/{{ (request.Rule == 0 || request.Rule == 1)?"18":"9" }}H</span></p>
              <p></p>
              <p><span>増減：</span><span class="half_chara right_align">{{ (request.HC_Value == 0)? "0.0" : Math.abs(request.HC_Value).toFixed(1) }}/{{ (request.Rule == 0 || request.Rule == 1)?"18":"9" }}H</span></p>
            </div>
          </div>
          <div class="friend_r_list_buttons">
            <p><button class="friend_r_list_button button_fonts" v-on:mouseup="approvalRequest(request)">承認</button></p>
            <p><button class="friend_r_list_button_reject button_fonts" v-on:mouseup="rejectRequest(request)">拒否</button></p>
          </div>
        </div>
        <div class="friend_r_list_more main_fonts" v-on:mouseup="addMyFriendRequestList()" v-if="nextToken_friend_request">
          <p>さらに表示</p>
        </div>
      </div>
      <div v-else>
        <p class="friends_nortice_none main_fonts">新着のフレンド申請はありません。</p>
      </div>
      <div class="friend_search">
        <h4><img src="@/assets/renewal/search.png"></h4>
        <p><input type="text" class="username main_fonts" placeholder="ID検索" v-model="username" @change="searchFriend()" v-on:keydown.enter="searchFriend()"></p>
      </div>
      <div class="gray_line"></div>
      <div class="results_friend_list">
        <div class="results_friend_list_column">
          <h2 class="results_friend_list_relation main_fonts">関係</h2>
          <h2 class="results_friend_list_name main_fonts">名前</h2>
          <h2 class="results_friend_list_rule main_fonts">ルール</h2>
        </div>
      </div>
      <div class="results_friend_list" v-for="(friend, FriendUserID) in friend_data" v-bind:key="FriendUserID" v-on:mouseup="openResultList(friend)">
        <div class="results_friend_list_line">
          <h2 class="results_friend_list_relation main_fonts" v-if="friend.Rate >= 60"><img src="@/assets/renewal/rate_inferior_b.png"></h2>
          <h2 class="results_friend_list_relation main_fonts" v-else-if="40 >= friend.Rate"><img src="@/assets/renewal/rate_superior_b.png"></h2>
          <h2 class="results_friend_list_relation main_fonts" v-else><img src="@/assets/renewal/rate_rival_b.png"></h2>
          <h2 class="results_friend_list_name" :style="{ fontSize: resizedFontSize((friend) ? friend.FriendNickName : '', 5, 14, 0.95)}">{{ friend.FriendNickName }}</h2>
          <h2 class="results_friend_list_rule main_fonts"><span>{{ (friend.Rule >= 2)? "３" : "１" }}勝制</span></h2>
        </div>
      </div>
      <div class="result_friend_list_more button_fonts" v-on:mouseup="addMyFriendList()" v-if="nextToken_friend">
        <p>さらに表示</p>
      </div>
      <button class="result_back_button button_fonts" v-on:mouseup="pagePush('/')">戻る</button>
    </div>
    <div class="friend_detail" v-if="page=='result'">
      <Result_List :friend_Record=friend_Record :result_data=result_data :nextToken_result=nextToken_result @pageMove="pageMove" @addResultList="addResultList" @openResultDetail="openResultDetail" @openResultListYear="openResultListYear" @addResultListYear="addResultListYear" @pageMoveFriendEdit="pageMoveFriendEdit"></Result_List>
    </div>
    <div class="friend_request" v-if="page=='request'">
      <Friend_Request :friendUser=friendUser @pageMove="pageMove"></Friend_Request>
    </div>
    <div class="friend_edit" v-if="page=='friend_edit'">
      <Friend_Edit :friend_Record=friend_Record @pageMove="pageMove" ></Friend_Edit>
    </div>
    <div class="friend_delete" v-if="page=='friend_delete'">
      <Friend_Delete :friend_Record=friend_Record @pageMove="pageMove" ></Friend_Delete>
    </div>
    <div class="result_detail" v-if="page=='detail'">
      <Result_Detail :friend_Record=friend_Record :result_Record=result_Record :can_move_before=can_move_before :can_move_after=can_move_after @pageMove="pageMove" @openResultList="openResultList" @moveResultDetail="moveResultDetail" @pageMoveResultEdit="pageMoveResultEdit" @removeRecords="removeRecords"></Result_Detail>
    </div>
    <div class="result_edit" v-if="page=='result_edit'">
      <Result_Edit :friend_Record=friend_Record :result_Record=result_Record :year=year :month=month :day=day :golf_course=golf_course @pageMove="pageMove" @switchHamburger="switchHamburger" @editRecord="editRecord" @openResultDetail="openResultDetail"></Result_Edit>
    </div>
    <div class="advertise_space"></div>
    <slide left :burgerIcon="false" :crossIcon="false" :isOpen="hamburger.Date" disableOutsideClick :width="getWindowWidth()">
      <div><h3 class="slide_caption">日付選択</h3></div>
      <div class="game_caption_slide main_fonts">
        <h3>年</h3>
      </div>
      <div class="game_value_slide">
        <select class="gc_select dropdown main_fonts" v-model="selected_year" v-on:change="checkShortMonth()">
          <option v-for="(text,value) in options(2020,current_date.getFullYear(),4)" :value="value" v-text="text" v-bind:key="value"></option>
        </select>
      </div>
      <div class="game_caption_slide">
        <h3>月</h3>
      </div>
      <div class="game_value_slide">
        <select class="gc_select dropdown main_fonts" v-model="selected_month" v-on:change="checkShortMonth()">
            <option v-for="(text,value) in options(1, (selected_year != current_date.getFullYear()) ? 12 : current_date.getMonth() + 1, 2)" :value="value" v-text="text" v-bind:key="value"></option>
          </select>
      </div>
      <div class="game_caption_slide">
        <h3>日</h3>
      </div>
      <div class="game_value_slide">
        <select class="dropdown ver_date main_fonts" v-model="selected_day" v-if="[4,6,9,11].includes(parseInt(selected_month))">
            <option v-for="(text,value) in options(1, !(selected_year == current_date.getFullYear() && selected_month == current_date.getMonth()+1) ? 30 : current_date.getDate() , 2)" :value="value" v-text="text" v-bind:key="value"></option>
          </select>
          <select class="dropdown ver_date main_fonts" v-model="selected_day" v-else-if="selected_month==2 && selected_year%4==0">
            <option v-for="(text,value) in options(1, !(selected_year == current_date.getFullYear() && selected_month == current_date.getMonth()+1) ? 29 : current_date.getDate(), 2)" :value="value" v-text="text" v-bind:key="value"></option>
          </select>
          <select class="dropdown ver_date main_fonts" v-model="selected_day" v-else-if="selected_month==2">
            <option v-for="(text,value) in options(1, !(selected_year == current_date.getFullYear() && selected_month == current_date.getMonth()+1) ? 28 : current_date.getDate(), 2)" :value="value" v-text="text" v-bind:key="value"></option>
          </select>
          <select class="dropdown ver_date main_fonts" v-model="selected_day" v-else>
            <option v-for="(text,value) in options(1, !(selected_year == current_date.getFullYear() && selected_month == current_date.getMonth()+1) ? 31 : current_date.getDate(), 2)" :value="value" v-text="text" v-bind:key="value"></option>
          </select>
      </div>
      <div class="slide_button button_fonts" v-on:mouseup="confirmHamburger('Date')"><div>確定</div></div>
      <div class="slide_button button_fonts" v-on:mouseup="switchHamburger('Date')"><div>キャンセル</div></div>
    </slide>
    <slide left :burgerIcon="false" :crossIcon="false" :isOpen="hamburger.Golf" disableOutsideClick :width="getWindowWidth()">
      <div><h3 class="slide_caption title_fonts">ゴルフ場選択</h3></div>
      <div class="game_caption_slide main_fonts">
        <h3>地方</h3>
      </div>
      <div class="game_value_slide">
        <select class="gc_select dropdown main_fonts" v-model="region" v-on:change="getTowns()" v-bind:disabled="loading">
          <option :value="null" disabled=true></option>
          <option v-for="r in regions" :value="r" v-text="r.name" v-bind:key="r.id"></option>
        </select>
      </div>
      <div class="game_caption_slide main_fonts">
        <h3>都道府県</h3>
      </div>
      <div class="game_value_slide">
        <select class="gc_select dropdown main_fonts" v-model="town" v-on:change="getGolfCourses()" v-bind:disabled="loading">
          <option :value="null" disabled=true></option>
          <option v-for="t in towns_region" :value="t.id" v-text="t.name" v-bind:key="t.id"></option>
        </select>
      </div>
      <div class="game_caption_slide main_fonts">
        <h3>ゴルフ場</h3>
      </div>
      <div class="game_value_slide">
        <select class="gc_select dropdown main_fonts" v-model="selected_gc" v-bind:disabled="golf_courses.length == 0 || loading">
          <option :value="null" disabled=true class="initial"></option>
          <option v-for="course in golf_courses" :value="course" v-text="course.GolfCourseYomigana != 'そのた' ? course.GolfCourseName : 'その他（リストに無いゴルフ場）'" v-bind:key="course.GolfCourseID" :class="(course.GolfCourseYomigana.length != 1) ? '' : 'initial'" :disabled="course.GolfCourseYomigana.length == 1">{{ course.GolfCourseName }}</option>
        </select>
      </div>
      <div class="game_caption_slide main_fonts" v-if="(selected_gc) && selected_gc.GolfCourseYomigana == 'そのた'">
        <h3>ゴルフ場名</h3>
      </div>
      <div class="game_value_slide" v-if="(selected_gc) && selected_gc.GolfCourseYomigana == 'そのた'">
        <input type="text" class="gc_input main_fonts" v-model="input_gc" placeholder="ゴルフ場名を入力">
      </div>
      <div class="slide_button button_fonts" v-on:mouseup="confirmHamburger('Golf')"><div>確定</div></div>
      <div class="slide_button button_fonts" v-on:mouseup="switchHamburger('Golf')"><div>キャンセル</div></div>
    </slide>
  </div>
</template>

<script>
/* eslint-disable */
/* フレンドリスト画面 */
import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API

import { getNickName } from "@/graphql/queries"	// ニックネームのレコードを取得するquery
import { getScoreRecord } from "@/graphql/queries"	// スコアレコードを取得するquery
import { getScoreRecordUnit } from "@/graphql/queries"	// スコアレコードを1件取得するquery
import { getKumiawaseRecord } from "@/graphql/queries"	// 組み合わせレコードを１件取得するquery
import { getFriendRecord } from "@/graphql/queries"	// フレンドレコードを取得するquery
import { getFriendRecordUnit } from "@/graphql/queries"	// フレンドレコードを１件取得するquery
import { getTaisenRecord } from "@/graphql/queries"	// スコアレコードを取得するquery
import { getGolfCourses } from "@/graphql/queries"	// スコアレコードを取得するquery
import { listRecordOfGolfCourse } from "@/graphql/queries" // ゴルフコースのレコードを取得するquery
import { deleteGolfHandicap } from "@/graphql/mutations"	// レコードを削除するmutation
import { updateGolfHandicap } from "../graphql/mutations"	// レコードを更新するmutation
import { getSerialNumber } from "@/graphql/queries"	// ニックネームのレコードを取得するquery
import { getRecordInShinseiKBN } from "@/graphql/queries"
import { createFriendData } from "@/graphql/mutations"	// 組み合わせデータを作成するmutation
import { createSerialNumberData } from "@/graphql/mutations"	// 採番データを作成するmutation
import { createTaisenData } from "@/graphql/mutations"  // 対戦データを作成するmutation
import { createScoreData } from "@/graphql/mutations"  // スコアデータを作成するmutation

import Result_List from '@/components/Result_List.vue'
import Result_Detail from '@/components/Result_Detail.vue'
import Result_Edit from '@/components/Result_Edit.vue'
import Friend_Request from '@/components/Friend_Request.vue'
import Friend_Edit from '@/components/Friend_Edit.vue'
import Friend_Delete from '@/components/Friend_Delete.vue'

import _ from 'lodash'  // 配列を扱うモジュール

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

import { regions } from '@/town-list.js'
import towns from '@/town-list.js'

export default {
  name: 'Friend_List',
  components: {
    Result_List,
    Result_Detail,
    Result_Edit,
    Friend_Edit,
    Friend_Delete,
    Friend_Request,
  },
  data () {
    return {
      page: "",
      friend_data: [],
      request_data: [],
      result_data: [],
      friend_Record: null,
      result_Record: null,
      score_Record: null,
      friendNickName: "",
      selfNickName: "",
      username: "",
      friendUser: null,
      s_win: 0,
      f_win: 0,
      s_out: 0,
      s_in: 0,
      s_total: 0,
      f_out: 0,
      f_in: 0,
      f_total: 0,
      rule: 1,
      handicap: "00.0",
      hc_Value: "00.0",
      current_date: null,
      year: "2022",  // 年
      selected_year: "2022",
      month: "12",  // 月
      selected_month: "12",
      day: "12",  // 日
      selected_day: "12",
      regions: regions,
      region: "",
      towns: towns,
      towns_region: [],
      town: '',
      golf_courses: [],
      selected_gc: null,
      input_gc: "",
      golf_course: null,
      loading: false,
      nextToken_friend_request: "",
      nextToken_friend: "",
      nextToken_result: "",
      can_move_before: false,
      can_move_after: false,
      vertical_layout: false,
      hamburger: {
        Date: false,
        Golf: false,
      },
      windowWidth: window.innerWidth,
    }
  },
  mounted: async function () {
    this.selfNickName = store.state.nickname;
    this.page = "";
    this.getMyFriendRecord();
    this.getMyFriendRequestRecord();
    if (store.state.friend) {
      const params = {
        HashKey: store.state.user.username,
        RangeKey: store.state.friend
      }
      const target = await API.graphql(graphqlOperation(getFriendRecordUnit, params));
      await this.openResultList(target.data.getGolfHandicapTableRenewal);
      store.commit('setFriend', "");
    }
    //	通知フラグ更新
    const flg_data = {
      HashKey: store.state.user.username,
      RangeKey: "MST",
      Notice: 0
    }
    await API.graphql(graphqlOperation(updateGolfHandicap, {input: flg_data}));
    const date = new Date();  // 現在の日付時刻を取得
    this.current_date = date;
    this.year = date.getFullYear();  // 年月日の初期値入力
    this.selected_year = date.getFullYear();
    this.month = (date.getMonth() + 1);
    this.selected_month = (date.getMonth() + 1);
    this.day = date.getDate();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('resize', this.updateWindowSize);
  },
  methods: {
    pageMove: function(pagename) {	// 表示切替
      this.page = pagename;
      if (pagename == "") {
        this.nextToken_result = "";
        this.getMyFriendRecord();
      }
      scrollTo(0,0);
    },
    pagePush: function(pagename) {	// ページ遷移
      router.push({path: pagename})
    },
    pageMoveArg: async function (pagename, user) {  // レコードの情報を渡しつつ表示切替
      this.pageMove(pagename);
    },
    pageMoveFriendEdit: async function () {
      this.loading = true;
      this.rule = this.friend_Record.Rule;
      this.handicap = parseFloat(this.friend_Record.Handicap).toFixed(1).toString().padStart(4, '0');
      this.hc_value = this.friend_Record.HC_Value;
      this.loading = false;
      this.page = "friend_edit";
    },
    pageMoveResultEdit: async function () {
      this.loading = true;
      const town = this.result_Record.GolfCourseID.split('_')[0];
      const region_id = (town != "その他") ? (_.filter(towns, (item) => {return item.id == town.slice(3)})[0].region) : "その他";
      this.region = (region_id != "その他") ? (_.filter(regions, (item) => {return item.id == region_id})[0]) : "その他";
      if (town != "その他") {
        await this.getTowns();
        this.town = town.slice(3);
        await this.getGolfCourses();
      }
      
      for (let i = 0; i < this.golf_courses.length; i++) {
        if (this.golf_courses[i].GolfCourseID == this.result_Record.GolfCourseID) {
          this.golf_course = this.golf_courses[i]
        }
      }
      if (this.result_Record.GolfCourseID.includes("99999999")) {
        console.log(this.golf_course)
        console.log(this.result_Record)
        this.golf_course.GolfCourseName = this.result_Record.GolfCourseName;
        this.input_gc = this.result_Record.GolfCourseName;
      }
      this.selected_gc = this.golf_course;
      const year = this.result_Record.Date.substr(0,4);
      const month = this.result_Record.Date.substr(5,2);
      const day = this.result_Record.Date.substr(8,2);
      this.year = parseInt(year);
      this.selected_year = this.year;
      this.month = parseInt(month);
      this.selected_month = this.month;
      this.day = parseInt(day);
      this.selected_day = this.day;
      this.loading = false;
      this.page = "result_edit";
    },
    updateScoreRecord: async function (user_id, taisen_id, s_scores, f_scores, win, lose, draw, points, date, golf_course, result) {
      const input_data = {
        HashKey: user_id,
        RangeKey: taisen_id,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        MyFirstScore: s_scores[0],
        MySecondScore: s_scores[1],
        MyTotalScore: s_scores[2],
        FriendsFirstScore: f_scores[0],
        FriendsSecondScore: f_scores[1],
        FriendsTotalScore: f_scores[2],
        Win: win,
        Lose: lose,
        Draw: draw,
        Points: points,
        Result: result,
        GolfCourseID: golf_course.GolfCourseID,
        GolfCourseName: golf_course.GolfCourseName,
        Date: date
      }
      await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
    },
    updateJudgement: async function (user_id, taisen_id, win, lose, drow, shinseiKBN){
      const scoreRec = await this.getScoreRecord(user_id);
      const matchRec = _.filter(scoreRec, (item) => {return item.DataValue == taisen_id});
      for (let i = 0; i < matchRec.length; i++) {
        const input_data = {
          ID: user_id,
          DataType: matchRec[i].DataType,
          Win: win,
          Lose: lose,
          Drow: drow,
          ShinseiKBN: shinseiKBN
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
      }
    },
    updateFriendRecord: async function (user_id, k_id, win, lose, draw, matches, points, handi, year_match) {
      const friRec = await this.getFriendRecord(user_id);
      const matchRec = _.filter(friRec, (item) => {return item.KumiawaseID == k_id});
      console.log(matchRec)
      for (let i = 0; i < matchRec.length; i++) {
        const input_data = {
          HashKey: user_id,
          RangeKey: k_id,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Win: matchRec[i].Win + win,
          Lose: matchRec[i].Lose + lose,
          Draw: matchRec[i].Draw + draw,
          Points: matchRec[i].Points + points,
          Matches: matchRec[i].Matches + matches,
          Handicap: handi,
        }
        if (year_match) {
          input_data.WinThisYear = matchRec[i].WinThisYear + win;
          input_data.LoseThisYear = matchRec[i].LoseThisYear + lose;
          input_data.DrawThisYear = matchRec[i].DrawThisYear + draw;
          input_data.MatchesThisYear = matchRec[i].MatchesThisYear + matches;
          input_data.PointsThisYear = matchRec[i].PointsThisYear + points;
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
      }
    },
    getTowns: async function() {
      this.towns_region = _.filter(towns, (item) => {return item.region == this.region.id});
      if (this.towns_region.length == 1) {
        this.town = this.towns_region[0].id;
        await this.getGolfCourses();
      }
    },
    getGolfCourses: async function() {  // ゴルフ場レコード取得
      this.loading = true;
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const query_data = {
        HashKey: {
          eq: "GolfCourseName",  // 完全一致
        },
        RangeKey: {
          beginsWith: "P01" + this.town
        }
      }
      do {
        try {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(listRecordOfGolfCourse, {query: query_data}));
            recordsArr = result.data.listGolfHandicapTableRenewalsQuery.items;
          } else {
            result = await API.graphql(graphqlOperation(listRecordOfGolfCourse, {query: query_data, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsQuery.items);
          }
          nextToken = result.data.listGolfHandicapTableRenewalsQuery.nextToken;
        }catch(err) {
          console.log(err);
        }
      } while (nextToken != null);
      for (let i = 0; i < recordsArr.length; i++)
      {
        if (recordsArr[i].GolfCourseYomigana.slice(0, 1) == "ヴ")
        {
          recordsArr[i].GolfCourseYomigana = recordsArr[i].GolfCourseYomigana.replace("ヴ", "う");
        }
      }
      recordsArr = _.orderBy(recordsArr, 'GolfCourseYomigana', 'asc');
      let initial = "";
      for (let i = 0; i < recordsArr.length; i++)
      {
        let initial_char = "";
        initial_char = recordsArr[i].GolfCourseYomigana.slice(0, 1).normalize('NFD')[0];
        if (initial != initial_char)
        {
          initial = initial_char;
          const params = {
            GolfCourseName: initial_char,
            GolfCourseYomigana: initial_char
          }
          recordsArr.splice(i, 0, params)
        }
      }
      recordsArr.push({
        GolfCourseID: this.town + "_G9999999999",
        GolfCourseName: (this.result_Record && this.result_Record.GolfCourseYomigana == "そのた") ? this.result_Record.GolfCourseName : "",
        GolfCourseYomigana: "そのた"
      });
      this.golf_courses = recordsArr;
      this.loading = false;
    },
    getTaisenRecord: async function(k_id, ask) {  // 組み合わせIDから対戦レコード取得
      let result = null;
      let recordsArr = null;
      const searchData = {
        HashKey: {
          eq: store.state.user.username,  // 完全一致
        },
        RangeKey: {
          beginsWith: "T01" + k_id.slice(3,11),
        }
      }
      try {
        if (this.nextToken_result == "") {
          result = await API.graphql(graphqlOperation(getScoreRecord, {query: searchData, limit: 10, ask: ask}));
          recordsArr = result.data.listGolfHandicapTableRenewalsReverse.items;
        } else {
          result = await API.graphql(graphqlOperation(getScoreRecord, {query: searchData, limit: 10, nextToken: this.nextToken_result, ask: ask}));
          recordsArr = result.data.listGolfHandicapTableRenewalsReverse.items;
        }
        this.nextToken_result = result.data.listGolfHandicapTableRenewalsReverse.nextToken;
      }catch(err) {
        console.log(err);
      }
      return recordsArr;
    },
    getTaisenRecordYear: async function(kid, year, ask) {  // 組み合わせIDから対戦レコード取得
      let result = null;
      let recordsArr = null;
      const searchData = {
        HashKey: {
          eq: store.state.user.username,  // 完全一致
        },
        RangeKey: {
          beginsWith: "T01" + kid.slice(3,11) + year,
        }
      }
      try {
        if (this.nextToken_result == "") {
          result = await API.graphql(graphqlOperation(getScoreRecord, {query: searchData, limit: 10, ask: ask}));
          recordsArr = result.data.listGolfHandicapTableRenewalsReverse.items;
        } else {
          result = await API.graphql(graphqlOperation(getScoreRecord, {query: searchData, limit: 10, nextToken: this.nextToken_result, ask: ask}));
          recordsArr = result.data.listGolfHandicapTableRenewalsReverse.items;
        }
        this.nextToken_result = result.data.listGolfHandicapTableRenewalsReverse.nextToken;
      }catch(err) {
        console.log(err);
      }
      return recordsArr;
    },
    getTaisenRecordAll: async function(k_id, ask) {  // 組み合わせIDから対戦レコード取得
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const searchData = {
        HashKey: {
          eq: store.state.user.username,  // 完全一致
        },
        RangeKey: {
          beginsWith: "T01" + k_id.slice(3,11),
        }
      }
      do {
        try {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(getScoreRecord, {query: searchData, ask: ask}));
            recordsArr = result.data.listGolfHandicapTableRenewalsReverse.items;
          } else {
            result = await API.graphql(graphqlOperation(getScoreRecord, {query: searchData, nextToken: nextToken, ask: ask}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsReverse.items);
          }
          nextToken = result.data.listGolfHandicapTableRenewalsReverse.nextToken;
        }catch(err) {
          console.log(err);
        }
      } while (nextToken != null)
      return recordsArr;
    },
    getFriendRequestRecord: async function(id) {  // 組み合わせレコード取得
      let result = null;
      let recordsArr = null;
      const searchData = {
        HashKey: {
          eq: id,  // 完全一致
        },
        RangeKey: {
          beginsWith: "K01"  // 前方一致
        }
      }
      try {
        result = await API.graphql(graphqlOperation(getFriendRecord, {query: searchData}));
        recordsArr = result.data.listGolfHandicapTableRenewalsQuery.items;
        this.nextToken_friend_request = result.data.listGolfHandicapTableRenewalsQuery.nextToken;
      }catch(err) {
        console.log(err);
      }
      return recordsArr;
    },
    getFriendRecord: async function(id) {  // 組み合わせレコード取得
      let result = null;
      let recordsArr = null;
      const searchData = {
        HashKey: {
          eq: id,  // 完全一致
        },
        RangeKey: {
          beginsWith: "K01"  // 前方一致
        }
      }
      try {
        result = await API.graphql(graphqlOperation(getFriendRecord, {query: searchData}));
        recordsArr = result.data.listGolfHandicapTableRenewalsQuery.items;
        this.nextToken_friend = result.data.listGolfHandicapTableRenewalsQuery.nextToken;
      }catch(err) {
        console.log(err);
      }
      for (let i = 0; i < recordsArr.length; i++) {
        if (recordsArr[i].MatchesThisYear > 0) {
          recordsArr[i].Rate = recordsArr[i].WinThisYear / (recordsArr[i].WinThisYear + recordsArr[i].LoseThisYear) * 100
        }
        else {
          recordsArr[i].Rate = 50;
        }
      }
      return recordsArr;
    },
    getFriendRecordAll: async function(id) {  // 組み合わせレコード取得
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const searchData = {
        HashKey: {
          eq: id,  // 完全一致
        },
        RangeKey: {
          beginsWith: "K01"  // 前方一致
        }
      }
      try {
        do {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(getFriendRecord, {query: searchData}));
            recordsArr = result.data.listGolfHandicapTableRenewalsQuery.items;
          }
          else {
            result = await API.graphql(graphqlOperation(getFriendRecord, {query: searchData, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsQuery.items);
          }
          nextToken = result.data.listGolfHandicapTableRenewalsQuery.nextToken;
        } while (nextToken != null);
      }catch(err) {
        console.log(err);
      }
      return recordsArr;
    },
    getScoreRecord: async function(id) {
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const searchData = {
        ID: {
          eq: id,
        },
        DataType: {
          beginsWith: "TaisenID"
        }
      }
      do {
        try {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(getScoreRecord, {query: searchData}));
            recordsArr = result.data.listGolfHandicapsQuery.items;
          } else {
            result = await API.graphql(graphqlOperation(getScoreRecord, {query: searchData, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapsQuery.items);
          }
          nextToken = result.data.listGolfHandicapsQuery.nextToken;
        }catch(err) {
          console.log(err);
        }
      } while (nextToken != null)
      return recordsArr;
    },
    getNickName: async function (id) {  // ユーザーIDからニックネーム取得
      const nN = await API.graphql(graphqlOperation(getNickName, {ID: id, DataType: "NickName"}));
      return nN.data.getGolfHandicap.DataValue;
    },
    getGolfCourseName: async function (id) {  // 対戦IDからゴルフ場名取得
      const g_id_data = await API.graphql(graphqlOperation(getGolfCourseID, {ID: id, DataType: "GolfCourseID"}));
      const g_id = g_id_data.data.getGolfHandicap.DataValue;
      const g_name_data = await API.graphql(graphqlOperation(getGolfCourseID, {ID: "GolfCourseName", DataType: g_id}))
      const g_name = g_name_data.data.getGolfHandicap.DataValue;
      return g_name;
    },
    getGolfCourseNames: async function (t_input) {  // バッチ処理でゴルフ場名取得
      // 対戦IDからゴルフ場ID取得
      const g_ids_data = await API.graphql(graphqlOperation(getGolfCourses, {input: t_input}));
      const g_ids = g_ids_data.data.getGolfHandicapBatch;
      let g_input = [];
      for (let i = 0; i < g_ids.length; i++) {
        if (g_ids[i] != null) {
          const input_data = {
            ID: "GolfCourseName",
            DataType: g_ids[i].DataValue
          }
          g_input.push(input_data);
        }
      }
      // 重複している物を一意化
      g_input = _.uniqBy(g_input,'DataType');
      // ゴルフ場IDからゴルフ場名を取得
      const g_names_data = await API.graphql(graphqlOperation(getGolfCourses, {input: g_input}));
      const g_names = g_names_data.data.getGolfHandicapBatch;
      let g_id_names = []
      // 取得したデータを元に対戦IDとゴルフ場名を紐づけ
      for (let i = 0; i < g_ids.length; i++) {
        if (g_ids[i] != null) {
          let gc_name = _.filter(g_names, (item) => {return item.DataType == g_ids[i].DataValue})[0];
          const data = {
            TaisenID: g_ids[i].ID,
            GolfCourseName: gc_name.DataValue,
            GolfCourseID: g_ids[i].DataValue
          }
          g_id_names.push(data);
        }
        else {
          console.log("ゴルフ場データが記録されていない対戦ID：" + t_input[i].ID);
        }
      }
      return g_id_names;
    },
    getRecordOfIndex: async function(id) {
      let result = null;
      let recordsArr = null;
      let nextToken = '';
      const index = "\"ShinseiKBN-index\"";
      const searchData = {
        HashKey: {
          eq: id,
        },
        ShinseiKBN: {
          eq: 9
        }
      }
      do {
        try {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(getRecordInShinseiKBN, {index: index, query: searchData}));
            recordsArr = result.data.listGolfHandicapsIndex.items;
          } else {
            result = await API.graphql(graphqlOperation(getRecordInShinseiKBN, {index: index, query: searchData, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapsIndex.items);
          }
          nextToken = result.data.listGolfHandicapsIndex.nextToken;
        }catch(err) {
          console.log(err);
        }
      } while (nextToken != null)
      return recordsArr;
    },
    getMyFriendRecord : async function() {  // 自分に関連する組み合わせレコード取得
      this.loading = true;
      const fri_data = await this.getFriendRecord(store.state.user.username)
      let friend_data = _.filter(fri_data, (item) => {return item.ShinseiKBN == 0});
      this.friend_data = friend_data;
      this.loading = false;
    },
    addMyFriendList: async function() {
      this.loading = true;
      let result = null;
      let recordsArr = null;
      const searchData = {
        HashKey: {
          eq: store.state.user.username,  // 完全一致
        },
        RangeKey: {
          beginsWith: "K01"  // 前方一致
        }
      }
      try {
        result = await API.graphql(graphqlOperation(getFriendRecord, {query: searchData, nextToken: this.nextToken_friend}));
        recordsArr = result.data.listGolfHandicapTableRenewalsQuery.items;
        this.nextToken_friend = result.data.listGolfHandicapTableRenewalsQuery.nextToken;
      }catch(err) {
        console.log(err);
      }
      this.loading = false;
      this.friend_data.push(recordsArr);
    },
    getMyFriendRequestRecord : async function() {  // 自分に関連する組み合わせレコード取得
      this.loading = true;
      const fri_data = await this.getFriendRequestRecord(store.state.user.username)
      let friend_data = _.filter(fri_data, (item) => {return item.ShinseiKBN == 2});
      this.request_data = friend_data;
      this.loading = false;
    },
    addMyFriendRequestList: async function() {
      this.loading = true;
      let result = null;
      let recordsArr = null;
      const searchData = {
        HashKey: {
          eq: store.state.user.username,  // 完全一致
        },
        RangeKey: {
          beginsWith: "K01"  // 前方一致
        }
      }
      try {
        result = await API.graphql(graphqlOperation(getFriendRecord, {query: searchData, nextToken: this.nextToken_friend_request}));
        recordsArr = result.data.listGolfHandicapTableRenewalsQuery.items;
        this.nextToken_friend_request = result.data.listGolfHandicapTableRenewalsQuery.nextToken;
      }catch(err) {
        console.log(err);
      }
      this.loading = false;
      this.request_data.push(recordsArr);
    },
    approvalRequest: async function(friend) {  // 申請承認
      if (confirm(friend.FriendNickName + " さんからのフレンド申請を承認します。\nよろしいですか？")) {
        this.loading = true;
        try {
          await this.updateShinseiKBN(store.state.user.username, 0, friend.KumiawaseID);
          await this.updateShinseiKBN(friend.FriendUserID, 0, friend.KumiawaseID);
          await this.getMyFriendRecord();
          await this.getMyFriendRequestRecord();
          this.pageMove("");
        } catch (err) {
          console.log(err);
        }
        this.loading = false;
      } 
    },
    rejectRequest: async function(friend) {  // 申請拒否
      if (confirm(friend.FriendNickName + " さんからのフレンド申請を拒否します。\nよろしいですか？")) {
        this.loading = true;
        try {
          await this.updateShinseiKBN(store.state.user.username, 9, friend.KumiawaseID);
          await this.updateShinseiKBN(friend.FriendUserID, 9, friend.KumiawaseID);
          await this.getMyFriendRecord();
          await this.getMyFriendRequestRecord();
          this.pageMove("");
        } catch (err) {
          console.log(err);
        }
        this.loading = false;
      } 
    },
    updateShinseiKBN: async function (self_id, shinseiKBN, kid) {  // 組み合わせレコードの申請フラグ更新
      const input_data = {
        HashKey: self_id,
        RangeKey: kid,
        ShinseiKBN: shinseiKBN,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
      }
      await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
    },
    openResultList: async function(friend) {
      this.loading = true;
      this.nextToken_result = "";
      this.friend_Record = friend;
      const taisen_data = await this.getTaisenRecord(friend.KumiawaseID);
      this.result_data = taisen_data;
      this.loading = false;
      this.pageMove('result');
    },
    addResultList: async function(friend) {
      this.loading = true;
      this.friend_Record = friend;
      const taisen_data = await this.getTaisenRecord(friend.KumiawaseID);
      this.result_data = this.result_data.concat(taisen_data);
      this.loading = false;
    },
    openResultListYear: async function(friend, year) {
      this.loading = true;
      this.nextToken_result = "";
      this.friend_Record = friend;
      const taisen_data = await this.getTaisenRecordYear(friend.KumiawaseID, year);
      this.result_data = taisen_data;
      this.loading = false;
      this.pageMove('result');
    },
    addResultListYear: async function(friend, year) {
      this.loading = true;
      this.friend_Record = friend;
      const taisen_data = await this.getTaisenRecordYear(friend.KumiawaseID, year);
      this.result_data = this.result_data.concat(taisen_data);
      this.loading = false;
    },
    openResultDetail: async function(result) {
      this.loading = true;
      let judgeArray = [];
      let s_hc = 0, f_hc = 0;
      if (result.Handicap > 0) {
        s_hc = result.Handicap;
      }
      else {
        f_hc = -result.Handicap;
      }
      if (result.Rule >= 2) {
        if (result.MyFirstScore - s_hc < result.FriendsFirstScore - f_hc) {
          judgeArray.push(1);
        } else if (result.MyFirstScore - s_hc > result.FriendsFirstScore - f_hc) {
          judgeArray.push(4);
        }
        if (result.MySecondScore - s_hc < result.FriendsSecondScore - f_hc) {
          judgeArray.push(2);
        } else if (result.MySecondScore - s_hc > result.FriendsSecondScore - f_hc) {
          judgeArray.push(5);
        }
      }
      if (result.MyTotalScore - (s_hc*((result.Rule>=2)?2:1)) < result.FriendsTotalScore - (f_hc*((result.Rule>=2)?2:1))) {
        judgeArray.push(3);
      } else if (result.MyTotalScore - (s_hc*((result.Rule>=2)?2:1)) > result.FriendsTotalScore - (f_hc*((result.Rule>=2)?2:1))) {
        judgeArray.push(6);
      }
      result['judge'] = judgeArray;
      this.result_Record = result;
      this.can_move_before = this.checkCanMove(false);
      this.can_move_after = this.checkCanMove(true);
      this.loading = false;
      this.pageMove('detail');
    },
    moveResultDetail: async function (next) {
      const move = (next)? 1 : -1;
      let num = -1;
      for (let i = 0; i < this.result_data.length; i++) {
        if (this.result_data[i].TaisenID == this.result_Record.TaisenID) {
          num = i;
        }
      }
      if (this.result_data[num + move] && num != -1) {
        this.openResultDetail(this.result_data[num + move]);
      }
      else if (move == 1 && this.nextToken_result) {
        await this.addResultList(this.friend_Record);
        this.openResultDetail(this.result_data[num + move]);
      }
    },
    
    checkCanMove: function (next) {
      const move = (next)? 1 : -1;
      let num = -1;
      for (let i = 0; i < this.result_data.length; i++) {
        if (this.result_data[i].TaisenID == this.result_Record.TaisenID) {
          num = i;
        }
      }
      return (this.result_data[num + move] && num != -1);
    },
    editRecord: async function(record, s_scores, f_scores) {
      this.loading = true;
      const newTaisenID = record.TaisenID.substr(0,11) + this.year + this.month.toString().padStart(2, '0') + this.day.toString().padStart(2, '0');
      const k_rec = await API.graphql(graphqlOperation(getKumiawaseRecord, {HashKey: record.KumiawaseID, RangeKey: "MST"}));
      const k_data = k_rec.data.getGolfHandicapTableRenewal;
      let p1_scores = [];
      let p2_scores = [];
      let hc = 0
      if (k_data.Player1UserID == record.UserID) {
        p1_scores = s_scores;
        p2_scores = f_scores;
        hc = record.Handicap;
      }
      else {
        p1_scores = f_scores;
        p2_scores = s_scores;
        hc = -record.Handicap;
      }
      const judge = this.getJudgement(p1_scores, p2_scores, hc, record.Rule);
      const p1_win = (judge[0] > judge[1]) ? 1 : 0;
      const p2_win = (judge[1] > judge[0]) ? 1 : 0;
      const draw = (judge[0] == judge[1]) ? 1 : 0;
      const p1_points = judge[0] - judge[1];
      const p2_points = judge[1] - judge[0];
      const result = (draw == 1) ? "Draw" : ((p1_win == 1) ? "Player1" : "Player2");
      const p1_result = (draw == 1) ? "Draw" : ((p1_win == 1) ? "Win" : "Lose");
      const p2_result = (draw == 1) ? "Draw" : ((p2_win == 1) ? "Win" : "Lose");
      const date = this.year + "-" + this.month.toString().padStart(2, '0') + "-" + this.day.toString().padStart(2, '0');
      if (record.TaisenID != newTaisenID) {
        // スコアレコードの削除
        const p1_score_delete = {
          HashKey: k_data.Player1UserID,
          RangeKey: record.TaisenID
        }
        const p2_score_delete = {
          HashKey: k_data.Player2UserID,
          RangeKey: record.TaisenID
        }
        API.graphql(graphqlOperation(deleteGolfHandicap, {input: p1_score_delete}));
        API.graphql(graphqlOperation(deleteGolfHandicap, {input: p2_score_delete}));
        // 対戦レコードの削除
        const taisen_delete = {
          HashKey: record.TaisenID,
          RangeKey: "MST"
        }
        API.graphql(graphqlOperation(deleteGolfHandicap, {input: taisen_delete}));
        // 対戦レコードの作成
        const taisen_data = {
          HashKey: newTaisenID,
          RangeKey: "MST",
          CreatedAt: this.getDateString(),
          CreateUserID: store.state.user.username,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          KumiawaseID: k_data.KumiawaseID,
          Date: this.year + "-" + this.month.toString().padStart(2, '0') + "-" + this.day.toString().padStart(2, '0'),
          Player1UserID: k_data.Player1UserID,
          Player1NickName: k_data.Player1NickName,
          Player2UserID: k_data.Player2UserID,
          Player2NickName: k_data.Player2NickName,
          TaisenID: newTaisenID,
          Handicap: record.Handicap,
          HC_Value: record.HC_Value,
          Rule: record.Rule,
          Player1FirstScore: p1_scores[0],
          Player1SecondScore: p1_scores[1],
          Player1TotalScore: p1_scores[2],
          Player2FirstScore: p2_scores[0],
          Player2SecondScore: p2_scores[1],
          Player2TotalScore: p2_scores[2],
          Result: result,
          Player1Win: (result == "Player1") ? 1 : 0,
          Player1Lose: (result == "Player2") ? 1 : 0,
          Draw: (result == "Draw") ? 1 : 0,
          Player1Points: judge[0] - judge[1],
          Player2Win: (result == "Player2") ? 1 : 0,
          Player2Lose: (result == "Player1") ? 1 : 0,
          Player2Points: judge[1] - judge[0],
          GolfCourseID: this.golf_course.GolfCourseID,
          GolfCourseName: this.golf_course.GolfCourseName,
        }
        API.graphql(graphqlOperation(createTaisenData, {input: taisen_data}));
        
        // スコアレコード作成
        this.createScoreRecord(k_data, newTaisenID, p1_scores, p2_scores, record.HC_Value, judge, this.golf_course, date);
      }
      else {
        // スコアレコードの更新
        this.updateScoreRecord(k_data.Player1UserID, record.TaisenID, p1_scores, p2_scores, p1_win, p2_win, draw, p1_points, date, this.golf_course, p1_result);
        this.updateScoreRecord(k_data.Player2UserID, record.TaisenID, p2_scores, p1_scores, p2_win, p1_win, draw, -p1_points, date, this.golf_course, p2_result);
        // 対戦レコードの更新
        const t_params = {
          HashKey: record.TaisenID,
          RangeKey: "MST",
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Player1FirstScore: p1_scores[0],
          Player1SecondScore: p1_scores[1],
          Player1TotalScore: p1_scores[2],
          Player2FirstScore: p2_scores[0],
          Player2SecondScore: p2_scores[1],
          Player2TotalScore: p2_scores[2],
          Result: result,
          Player1Win: p1_win,
          Player1Lose: p2_win,
          Draw: draw,
          GolfCourseID: this.golf_course.GolfCourseID,
          GolfCourseName: this.golf_course.GolfCourseName,
        }
        API.graphql(graphqlOperation(updateGolfHandicap, {input: t_params}));
      }
      
      // 組み合わせレコードの更新
      let win = 0;
      let lose = 0;
      if (k_data.Player1UserID == record.UserID) {
        win = p1_win;
        lose = p2_win;
      }
      else {
        win = p2_win;
        lose = p1_win;
      }
      // ハンデ更新判定
      let change = 0;
      if (record.HC_Value != 0) {
        if (record.Rule <= 1) {
          // １勝制
          // 変更前のスコア
          if (record.Draw != 1) {
            change += (win == 1) ? record.HC_Value :  -record.HC_Value;
          }
          // 変更後のスコア
          if (result != "Draw") {
            change += (result == "Player1") ? -record.HC_Value :  record.HC_Value;
          }
        }
        else {
          // ３勝制
          // 変更前のスコア
          if (Math.abs(record.Points) == 3) {
            change += (win == 1) ? record.HC_Value :  -record.HC_Value;
          }
          // 変更後のスコア
          if (Math.abs(p1_points) == 3) {
            change = (result == "Player1") ? -record.HC_Value :  record.HC_Value;
          }
        }
      }
      let p1_win_old = 0, p2_win_old;
      let p1_points_old = 0;
      if (k_data.Player1UserID == record.UserID) {
        p1_win_old = record.Win;
        p2_win_old = record.Lose;
        p1_points_old = record.Points;
      }
      else {
        p2_win_old = record.Win;
        p1_win_old = record.Lose;
        p1_points_old = -record.Points;
      }
      let senseki = [];
      if (p1_points_old != p1_points) {
        let k_params = {
          HashKey: k_data.KumiawaseID,
          RangeKey: "MST",
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Handicap: k_data.Handicap + change,
          Player1Win: k_data.Player1Win - p1_win_old + p1_win,
          Player1Lose: k_data.Player1Lose - p2_win_old + p2_win,
          Draw: k_data.Draw - record.Draw + draw,
          Player1Points: k_data.Player1Points - p1_points_old + p1_points,
          Player2Win: k_data.Player2Win - p2_win_old + p2_win,
          Player2Lose: k_data.Player2Lose - p1_win_old + p1_win,
          Player2Points: k_data.Player2Points + p1_points_old - p1_points,
        }
        const date = new Date();
        if (this.year.toString() == date.getFullYear()) {
          k_params.Player1WinThisYear = k_data.Player1WinThisYear - p1_win_old + p1_win;
          k_params.Player1LoseThisYear = k_data.Player1LoseThisYear - p2_win_old + p2_win;
          k_params.Draw = k_data.DrawThisYear - record.DrawThisYear + draw;
          k_params.Player1PointsThisYear = k_data.Player1PointsThisYear - p1_points_old + p1_points;
          k_params.Player2WinThisYear = k_data.Player2WinThisYear - p2_win_old + p2_win;
          k_params.Player2LoseThisYear = k_data.Player2LoseThisYear - p1_win_old + p1_win;
          k_params.Player2PointsThisYear = k_data.Player2PointsThisYear + p1_points_old - p1_points;
        }
        API.graphql(graphqlOperation(updateGolfHandicap, {input: k_params}));
        // 年次戦績レコードの更新
        const s_rec_before = await API.graphql(graphqlOperation(getKumiawaseRecord, {HashKey: record.KumiawaseID, RangeKey: "S01" + record.Date.slice(0,4)}));
        senseki[0] = s_rec_before.data.getGolfHandicapTableRenewal;
        let k_params_before = {
          HashKey: senseki[0].KumiawaseID,
          RangeKey: senseki[0].SensekiID,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Player1WinThisYear: senseki[0].Player1WinThisYear - p1_win_old,
          Player1LoseThisYear: senseki[0].Player1LoseThisYear - p2_win_old,
          Player1PointsThisYear: senseki[0].Player1PointsThisYear - p1_points_old,
          DrawThisYear: senseki[0].DrawThisYear - record.Draw,
          Player2WinThisYear: senseki[0].Player2WinThisYear - p2_win_old,
          Player2LoseThisYear: senseki[0].Player2LoseThisYear - p1_win_old,
          Player2PointsThisYear: senseki[0].Player2PointsThisYear + p1_points_old,
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: k_params_before}));
        let s_rec_after = await API.graphql(graphqlOperation(getKumiawaseRecord, {HashKey: record.KumiawaseID, RangeKey: "S01" + this.year}));
        if (!s_rec_after) {
          // 年次戦績レコードが無い場合は作成
          const year_kumi_create = {
            HashKey: k_data.KumiawaseID,
            RangeKey: "S01" + this.year,
            CreatedAt: this.getDateString(),
            CreateUserID: store.state.user.username,
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
            KumiawaseID: k_data.KumiawaseID,
            Player1UserID: k_data.Player1UserID,
            Player1NickName: k_data.Player1NickName,
            Player2UserID: k_data.Player2UserID,
            Player2NickName: k_data.Player2NickName,
            MatchesThisYear: 1,
            Player1WinThisYear: 0,
            Player1LoseThisYear: 0,
            DrawThisYear: 0,
            Player1PointsThisYear: 0,
            Player2WinThisYear: 0,
            Player2LoseThisYear: 0,
            Player2PointsThisYear: 0,
            SensekiID: "S01" + this.year,
          }
          await API.graphql(graphqlOperation(createKumiawaseData, {input: year_kumi_create}));
          s_rec_after = await API.graphql(graphqlOperation(getKumiawaseRecord, {HashKey: record.KumiawaseID, RangeKey: "S01" + this.year}));
        }
        senseki[1] = s_rec_after.data.getGolfHandicapTableRenewal;
        let k_params_after = {
          HashKey: senseki[1].KumiawaseID,
          RangeKey: senseki[1].SensekiID,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Player1WinThisYear: senseki[1].Player1WinThisYear + p1_win,
          Player1LoseThisYear: senseki[1].Player1LoseThisYear + p2_win,
          DrawThisYear: senseki[1].DrawThisYear + draw,
          Player1PointsThisYear: senseki[1].Player1PointsThisYear + p1_points,
          Player2WinThisYear: senseki[1].Player2WinThisYear + p2_win,
          Player2LoseThisYear: senseki[1].Player2LoseThisYear + p1_win,
          Player2PointsThisYear: senseki[1].Player2PointsThisYear - p1_points,
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: k_params_after}));
        
        // フレンドレコードの更新
        const s_rec_now = await API.graphql(graphqlOperation(getKumiawaseRecord, {HashKey: record.KumiawaseID, RangeKey: "S01" + date.getFullYear()}));
        const s_data_now = s_rec_now.data.getGolfHandicapTableRenewal;
        const p1_friend_update = {
          HashKey: k_data.Player1UserID,
          RangeKey: k_data.KumiawaseID,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Handicap: k_data.Handicap + change,
          Win: k_data.Player1Win - p1_win_old + p1_win,
          Lose: k_data.Player1Lose - p2_win_old + p2_win,
          Draw: k_data.Draw - record.Draw + draw,
          Points: k_data.Player1Points - p1_points_old + p1_points,
          WinThisYear: s_data_now.Player1WinThisYear,
          LoseThisYear: s_data_now.Player1LoseThisYear,
          DrawThisYear: s_data_now.DrawThisYear,
          PointsThisYear: s_data_now.Player1PointsThisYear
        }
        API.graphql(graphqlOperation(updateGolfHandicap, {input: p1_friend_update}));
        
        const p2_friend_update = {
          HashKey: k_data.Player2UserID,
          RangeKey: k_data.KumiawaseID,
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Handicap: k_data.Handicap + change,
          Win: k_data.Player2Win - p2_win_old + p2_win,
          Lose: k_data.Player2Lose - p1_win_old + p1_win,
          Draw: k_data.Draw - record.Draw + draw,
          Points: k_data.Player2Points + p1_points_old - p1_points,
          WinThisYear: s_data_now.Player2WinThisYear,
          LoseThisYear: s_data_now.Player2LoseThisYear,
          DrawThisYear: s_data_now.DrawThisYear,
          PointsThisYear: s_data_now.Player2PointsThisYear
        }
        API.graphql(graphqlOperation(updateGolfHandicap, {input: p2_friend_update}));
        
        // ユーザーレコードの更新
        if (record.Date.slice(0,4) == date.getFullYear() || this.year.toString() == date.getFullYear()) {
          const friend_userDB = await API.graphql(graphqlOperation(getNickName, {HashKey: record.FriendUserID, RangeKey: "MST"}));
          let p1_userDB = null, p2_userDB = null;
          if (k_data.Player1UserID == store.state.user.username) {
            p1_userDB = store.state.userDB;
            p2_userDB = friend_userDB.data.getGolfHandicapTableRenewal;
          }
          else {
            p1_userDB = friend_userDB.data.getGolfHandicapTableRenewal;
            p2_userDB = store.state.userDB;
          }
          let p1_user_update = {
            HashKey: p1_userDB.UserID,
            RangeKey: "MST",
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
            WinThisYear: s_data_now.Player1WinThisYear,
            LoseThisYear: s_data_now.Player1LoseThisYear,
            DrawThisYear: s_data_now.DrawThisYear,
            PointsThisYear: s_data_now.Player1PointsThisYear,
          }
          if (p1_userDB.AdvantageUserID == p2_userDB.UserID) {
            p1_user_update.AdvantagePoints = k_data.Player1PointsThisYear - p1_points_old + p1_points;
          }
          if (p1_userDB.DisadvantageUserID == p2_userDB.UserID) {
            p1_user_update.DisadvantagePoints = k_data.Player1Points - p1_points_old + p1_points;
          }
          await API.graphql(graphqlOperation(updateGolfHandicap, {input: p1_user_update}));
          let p2_user_update = {
            HashKey: p2_userDB.UserID,
            RangeKey: "MST",
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
            WinThisYear: s_data_now.Player2WinThisYear,
            LoseThisYear: s_data_now.Player2LoseThisYear,
            DrawThisYear: s_data_now.DrawThisYear,
            PointsThisYear: s_data_now.Player2PointsThisYear,
          }
          if (p2_userDB.AdvantageUserID == p1_userDB.UserID) {
            p2_user_update.AdvantagePoints = k_data.Player2PointsThisYear + p1_points_old - p1_points;
          }
          if (p2_userDB.DisadvantageUserID == p1_userDB.UserID) {
            p2_user_update.DisadvantagePoints = k_data.Player2Points + p1_points_old - p1_points;
          }
          await API.graphql(graphqlOperation(updateGolfHandicap, {input: p2_user_update}));
        }
      }
      // ユーザーレコードの更新
      const friend_userDB = await API.graphql(graphqlOperation(getNickName, {HashKey: record.FriendUserID, RangeKey: "MST"}));
      let p1_userDB = null, p2_userDB = null;
      if (k_data.Player1UserID == store.state.user.username) {
        p1_userDB = store.state.userDB;
        p2_userDB = friend_userDB.data.getGolfHandicapTableRenewal;
      }
      else {
        p1_userDB = friend_userDB.data.getGolfHandicapTableRenewal;
        p2_userDB = store.state.userDB;
      }
      if (p1_userDB.PreTaisenID == record.TaisenID) {
        let p1_user_update = {
          HashKey: p1_userDB.UserID,
          RangeKey: "MST",
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          PreFriendNickName: p2_userDB.NickName,
          PreFriendsScore: p2_scores[2],
          PreFriendUserID: p2_userDB.UserID,
          PreHandicap: record.Handicap,
          PreMyScore: p1_scores[2],
          PreResult: p1_result,
          PreTaisenDate: this.year + "-" + this.month.toString().padStart(2, '0') + "-" + this.day.toString().padStart(2, '0'),
          PreTaisenID: newTaisenID
        }
        console.log(p1_user_update);
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: p1_user_update}));
      }
      if (p2_userDB.PreTaisenID == record.TaisenID) {
        let p2_user_update = {
          HashKey: p2_userDB.UserID,
          RangeKey: "MST",
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          PreFriendNickName: p1_userDB.NickName,
          PreFriendsScore: p1_scores[2],
          PreFriendUserID: p1_userDB.UserID,
          PreHandicap: -record.Handicap,
          PreMyScore: p2_scores[2],
          PreResult: p2_result,
          PreTaisenDate: this.year + "-" + this.month.toString().padStart(2, '0') + "-" + this.day.toString().padStart(2, '0'),
          PreTaisenID: newTaisenID
        }
        console.log(p2_user_update);
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: p2_user_update}));
      }
      const userDB = await API.graphql(graphqlOperation(getNickName, {HashKey: store.state.user.username, RangeKey: "MST"}));
      store.commit('setUserDB', userDB.data.getGolfHandicapTableRenewal);
      await this.getMyFriendRecord();
      this.loading = false;
      this.pageMove("");
    },
    createScoreRecord : async function(k_data, taisen_ID, p1_score, p2_score, hc_value, judge, golf_course, date) {  // スコアレコードの作成
      let p1_result = "Draw";
      let p2_result = "Draw";
      if (judge[0] > judge[1]) {
        p1_result = "Win";
        p2_result = "Lose";
      }
      else if (judge[0] < judge[1]) {
        p2_result = "Win";
        p1_result = "Lose";
      }
      // 入力データ
      const p1_data = {
        HashKey: k_data.Player1UserID,
        RangeKey: taisen_ID,
        UserID: k_data.Player1UserID,
        NickName: k_data.Player1NickName,
        CreatedAt: this.getDateString(),
        CreateUserID: store.state.user.username,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        KumiawaseID: k_data.KumiawaseID,
        FriendUserID: k_data.Player2UserID,
        FriendNickName: k_data.Player2NickName,
        ShinseiKBN: 0,
        ShinseiDate: this.getDateString(),
        TaisenID: taisen_ID,
        Handicap: k_data.Handicap,
        HC_Value: hc_value,
        Rule: k_data.Rule,
        MyFirstScore: p1_score[0],
        MySecondScore: p1_score[1],
        MyTotalScore: p1_score[2],
        FriendsFirstScore: p2_score[0],
        FriendsSecondScore: p2_score[1],
        FriendsTotalScore: p2_score[2],
        Result: p1_result,
        Win: (p1_result == "Win") ? 1 : 0,
        Lose: (p2_result == "Win") ? 1 : 0,
        Draw: (p1_result == "Draw") ? 1 : 0,
        Points: judge[0] - judge[1],
        GolfCourseID: golf_course.GolfCourseID,
        GolfCourseName: golf_course.GolfCourseName,
        Date: date,
      }
      const p2_data = {
        HashKey: k_data.Player2UserID,
        RangeKey: taisen_ID,
        UserID: k_data.Player2UserID,
        NickName: k_data.Player2NickName,
        CreatedAt: this.getDateString(),
        CreateUserID: store.state.user.username,
        UpdatedAt: this.getDateString(),
        UpdateUserID: store.state.user.username,
        KumiawaseID: k_data.KumiawaseID,
        FriendUserID: k_data.Player1UserID,
        FriendNickName: k_data.Player1NickName,
        ShinseiKBN: 0,
        ShinseiDate: this.getDateString(),
        TaisenID: taisen_ID,
        Handicap: -k_data.Handicap,
        HC_Value: hc_value,
        Rule: k_data.Rule,
        MyFirstScore: p2_score[0],
        MySecondScore: p2_score[1],
        MyTotalScore: p2_score[2],
        FriendsFirstScore: p1_score[0],
        FriendsSecondScore: p1_score[1],
        FriendsTotalScore: p1_score[2],
        Result: p2_result,
        Win: (p2_result == "Win") ? 1 : 0,
        Lose: (p1_result == "Win") ? 1 : 0,
        Draw: (p2_result == "Draw") ? 1 : 0,
        Points: judge[1] - judge[0],
        GolfCourseID: golf_course.GolfCourseID,
        GolfCourseName: golf_course.GolfCourseName,
        Date: date,
      }
      await API.graphql(graphqlOperation(createScoreData, {input: p1_data}));
      await API.graphql(graphqlOperation(createScoreData, {input: p2_data}));
    },
    removeRecords : async function(s_record) {
      if (confirm("この対戦履歴を削除しますか？")) {
        this.loading = true;
        // 該当対戦のレコードの削除
        const k_ID = s_record.KumiawaseID;
        const s_params = {
          HashKey : store.state.user.username,
          RangeKey : s_record.TaisenID
        }
        const f_params = {
          HashKey : s_record.FriendUserID,
          RangeKey : s_record.TaisenID
        }
        const t_params = {
          HashKey: s_record.TaisenID,
          RangeKey: "MST"
        }
        await this.getMyFriendRecord();
        let handi = s_record.Handicap;
        const judge = this.getJudgement([s_record.MyFirstScore, s_record.MySecondScore, s_record.MyTotalScore], [s_record.FriendsFirstScore, s_record.FriendsSecondScore, s_record.FriendsTotalScore], s_record.Handicap, s_record.Rule);
        if(((s_record.Rule == 0 || s_record.Rule == 1) && s_record.Draw != 1) || judge.includes(3)) {  // ハンデ改正キャンセル
          handi =  handi - ((s_record.Win > s_record.Lose) ? -s_record.HC_Value : s_record.HC_Value);
        }
        
        API.graphql(graphqlOperation(deleteGolfHandicap, {input: t_params}));
        API.graphql(graphqlOperation(deleteGolfHandicap, {input: s_params}));
        API.graphql(graphqlOperation(deleteGolfHandicap, {input: f_params}));
        
        // フレンドレコードの更新
        await this.updateFriendRecord(store.state.user.username, s_record.KumiawaseID, -s_record.Win, -s_record.Lose, -s_record.Draw, -1, -s_record.Points, handi, this.getDateString().slice(0,4) == s_record.Date.slice(0,4));
        await this.updateFriendRecord(s_record.FriendUserID, s_record.KumiawaseID, -s_record.Lose, -s_record.Win, -s_record.Draw, -1, -s_record.Points, -handi, this.getDateString().slice(0,4) == s_record.Date.slice(0,4));
        
        // 組み合わせレコードの更新
        const k_rec = await API.graphql(graphqlOperation(getKumiawaseRecord, {HashKey: s_record.KumiawaseID, RangeKey: "MST"}));
        const k_data = k_rec.data.getGolfHandicapTableRenewal;
        let player1_data = {
          Draw: s_record.Draw
        }
        if (k_data.Player1UserID == s_record.UserID) {
          player1_data.Win = s_record.Win;
          player1_data.Lose = s_record.Lose;
          player1_data.Points = s_record.Points;
        }
        else {
          player1_data.Win = -s_record.Win;
          player1_data.Lose = -s_record.Lose;
          player1_data.Points = -s_record.Points;
        }
        let k_params = {
          HashKey: k_data.KumiawaseID,
          RangeKey: "MST",
          UpdatedAt: this.getDateString(),
          UpdateUserID: store.state.user.username,
          Matches: k_data.Matches - 1,
          Player1Win: k_data.Player1Win - player1_data.Win,
          Player1Lose: k_data.Player1Lose - player1_data.Lose,
          Player1Points: k_data.Player1Points - player1_data.Points,
          Player2Win: k_data.Player2Win - player1_data.Lose,
          Player2Lose: k_data.Player2Lose - player1_data.Win,
          Player2Points: k_data.Player2Points + player1_data.Points,
          Draw: k_data.Draw - player1_data.Draw,
        }
        
        // 今年のレコードの場合
        if (this.getDateString().slice(0,4) == s_record.Date.slice(0,4)) {
          // ユーザーレコードの更新
          const userDB_result = await API.graphql(graphqlOperation(getNickName, {HashKey: store.state.user.username, RangeKey: "MST"}));
          const userDB = userDB_result.data.getGolfHandicapTableRenewal;
          const s_input = {
            HashKey: store.state.user.username,
            RangeKey: "MST",
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
            WinThisYear: userDB.WinThisYear - s_record.Win,
            LoseThisYear: userDB.LoseThisYear - s_record.Lose,
            DrawThisYear: userDB.DrawThisYear - s_record.Draw,
            MatchesThisYear: userDB.MatchesThisYear - 1,
            PointsThisYear: userDB.PointsThisYear - s_record.Points,
            PreFriendNickName: "",
            PreFriendsScore: "",
            PreFriendUserID: "",
            PreHandicap: "",
            PreMyScore: "",
            PreResult: "",
            PreTaisenDate: "",
            PreTaisenID: ""
          }
          const friendDB_result = await API.graphql(graphqlOperation(getNickName, {HashKey: s_record.FriendUserID, RangeKey: "MST"}));
          const friendDB = friendDB_result.data.getGolfHandicapTableRenewal;
          const f_input = {
            HashKey: s_record.FriendUserID,
            RangeKey: "MST",
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
            WinThisYear: friendDB.WinThisYear - s_record.Lose,
            LoseThisYear: friendDB.LoseThisYear - s_record.Win,
            DrawThisYear: friendDB.DrawThisYear - s_record.Draw,
            MatchesThisYear: friendDB.MatchesThisYear - 1,
            PointsThisYear: friendDB.PointsThisYear + s_record.Points,
            PreFriendNickName: "",
            PreFriendsScore: "",
            PreFriendUserID: "",
            PreHandicap: "",
            PreMyScore: "",
            PreResult: "",
            PreTaisenDate: "",
            PreTaisenID: ""
          }
          API.graphql(graphqlOperation(updateGolfHandicap, {input: s_input}));
          API.graphql(graphqlOperation(updateGolfHandicap, {input: f_input}));
          
          k_params.MatchesThisYear = k_data.MatchesThisYear - 1;
          k_params.Player1WinThisYear = k_data.Player1WinThisYear - player1_data.Win;
          k_params.Player1LoseThisYear = k_data.Player1LoseThisYear - player1_data.Lose;
          k_params.Player1PointsThisYear = k_data.Player1PointsThisYear - player1_data.Points;
          k_params.Player2WinThisYear = k_data.Player2WinThisYear - player1_data.Lose;
          k_params.Player2LoseThisYear = k_data.Player2LoseThisYear - player1_data.Win;
          k_params.Player2PointsThisYear = k_data.Player2PointsThisYear + player1_data.Points;
          k_params.DrawThisYear = k_data.DrawThisYear - player1_data.Draw;
          // 年次戦績レコードの更新
          const k_rec_year = await API.graphql(graphqlOperation(getKumiawaseRecord, {HashKey: s_record.KumiawaseID, RangeKey: "MST"}));
          const k_data_year = k_rec_year.data.getGolfHandicapTableRenewal;
          const k_params_year = {
            HashKey: k_data_year.KumiawaseID,
            RangeKey: "S01" + s_record.Date.slice(0,4),
            UpdatedAt: this.getDateString(),
            UpdateUserID: store.state.user.username,
            MatchesThisYear: k_data_year.MatchesThisYear - 1,
            Player1WinThisYear: k_data_year.Player1WinThisYear - player1_data.Win,
            Player1LoseThisYear: k_data_year.Player1LoseThisYear - player1_data.Lose,
            Player1PointsThisYear: k_data_year.Player1PointsThisYear - player1_data.Points,
            Player2WinThisYear: k_data_year.Player2WinThisYear - player1_data.Lose,
            Player2LoseThisYear: k_data_year.Player2LoseThisYear - player1_data.Win,
            Player2PointsThisYear: k_data_year.Player2PointsThisYear + player1_data.Points,
            DrawThisYear: k_data_year.DrawThisYear - player1_data.Draw,
          }
          API.graphql(graphqlOperation(updateGolfHandicap, {input: k_params_year}));
        }
        API.graphql(graphqlOperation(updateGolfHandicap, {input: k_params}));
        await this.getMyFriendRecord();
        this.loading = false;
        this.pageMove("");
      }
    },
    checkChangedHandicapDetail() {
      let myNetScore = [];
      let friNetScore = [];
      let win = false;
      let lose = false;
      if (this.result_Record.Rule >= 2) {
        if (this.result_Record.s_Handicap >= 0) {
          myNetScore = [this.result_Record.s_FirstScore - this.result_Record.s_Handicap, this.result_Record.s_SecondScore - this.result_Record.s_Handicap, this.result_Record.s_TotalScore - this.result_Record.s_Handicap*2];
          friNetScore = [this.result_Record.f_FirstScore, this.result_Record.f_SecondScore, this.result_Record.f_TotalScore];
        }
        else {
          myNetScore = [this.result_Record.s_FirstScore, this.result_Record.s_SecondScore, this.result_Record.s_TotalScore];
          friNetScore = [this.result_Record.f_FirstScore + this.result_Record.s_Handicap, this.result_Record.f_SecondScore + this.result_Record.s_Handicap, this.result_Record.f_TotalScore + this.result_Record.s_Handicap*2];
        }
        let winCount = 0;
        let loseCount = 0;
        for (let i = 0; i < myNetScore.length; i++) {
          if (myNetScore[i] < friNetScore[i]) {
            winCount ++;
          }
          else if (myNetScore[i] > friNetScore[i]) {
            loseCount ++;
          }
        }
        if (winCount == 3) {
          win = true;
        }
        else if (loseCount == 3) {
          lose = true;
        }
      }
      else {
        if (this.result_Record.s_Handicap >= 0) {
          myNetScore = this.result_Record.s_TotalScore - this.result_Record.s_Handicap;
          friNetScore = this.result_Record.f_TotalScore;
        }
        else {
          myNetScore = this.result_Record.s_TotalScore;
          friNetScore = this.result_Record.f_TotalScore + this.result_Record.s_Handicap;
        }
        if (myNetScore < friNetScore) {
          win = true;
        }
        else if (myNetScore > friNetScore) {
          lose = true;
        }
      }
      if (win) {
        return this.result_Record.s_Handicap - this.result_Record.HC_Value;
      }
      else if (lose) {
        return this.result_Record.s_Handicap + this.result_Record.HC_Value;
      }
      else {
        return this.result_Record.s_Handicap;
      }
    },
    checkShortMonth() {
      if (this.selected_year >= this.current_date.getFullYear()) {
        if (this.selected_month >= this.current_date.getMonth()+1) {
          this.selected_month = this.current_date.getMonth()+1;
          if (this.selected_day > this.current_date.getDate()) {
            this.selected_day = this.current_date.getDate();
          }
        }
      }
      let maxDate = 31;
      if ([4,6,9,11].includes(parseInt(this.selected_month))) {
        maxDate = 30;
      }
      else if (this.selected_month == 2){
        if (this.selected_year % 4 == 0) {
          maxDate = 29;
        }
        else {
          maxDate = 28;
        }
      }
      if (this.selected_day > maxDate) {
        this.selected_day = maxDate.toString();
      }
    },
    checkChangedHandicap() {
      let myNetScore = [];
      let friNetScore = [];
      let win = false;
      let lose = false;
      if (this.score_record.Rule >= 2) {
        if (this.score_record.Handicap >= 0) {
          myNetScore = [this.s_out - this.score_record.Handicap, this.s_in - this.score_record.Handicap, this.s_total - this.score_record.Handicap*2];
          friNetScore = [parseInt(this.f_out), parseInt(this.f_in), this.f_total];
        }
        else {
          myNetScore = [parseInt(this.s_out), parseInt(this.s_in), this.s_total];
          friNetScore = [parseInt(this.f_out) + this.score_record.Handicap, parseInt(this.f_in) + this.score_record.Handicap, this.f_total + this.score_record.Handicap*2];
        }
        let winCount = 0;
        let loseCount = 0;
        for (let i = 0; i < myNetScore.length; i++) {
          if (myNetScore[i] < friNetScore[i]) {
            winCount ++;
          }
          else if (myNetScore[i] > friNetScore[i]) {
            loseCount ++;
          }
        }
        if (winCount == 3) {
          win = true;
        }
        else if (loseCount == 3) {
          lose = true;
        }
      }
      else {
        if (this.score_record.Handicap >= 0) {
          myNetScore = this.s_total - this.score_record.Handicap;
          friNetScore = this.f_total;
        }
        else {
          myNetScore = this.s_total;
          friNetScore = this.f_total + this.score_record.Handicap;
        }
        if (myNetScore < friNetScore) {
          win = true;
        }
        else if (myNetScore > friNetScore) {
          lose = true;
        }
      }
      if (win) {
        return this.score_record.Handicap - this.hc_Value;
      }
      else if (lose) {
        return this.score_record.Handicap + this.hc_Value;
      }
      else {
        return this.score_record.Handicap;
      }
    },
    getJudgement(s_scores, f_scores, handi, rule) {
      let s_win = 0, s_lose = 0;
      let f_win = 0, f_lose = 0;
      let s_handi = 0;
      let f_handi = 0;
      if (handi > 0) {
        s_handi = handi;
      }
      else {
        f_handi = -handi;
      }
      if (rule == 0 || rule == 1) {  // 1勝制の勝敗判定
        if (s_scores[2] - s_handi < f_scores[2] - f_handi) {
          s_win = 1;
          f_lose = 1;
        } else if (s_scores[2] - s_handi > f_scores[2] - f_handi) {
          s_lose = 1;
          f_win = 1;
        }
      } else {  // 3勝制の勝敗判定
        if (s_scores[1] - s_handi < f_scores[1] - f_handi) {
          s_win += 1;
          f_lose += 1;
        } else if (s_scores[1] - s_handi > f_scores[1] - f_handi) {
          s_lose += 1;
          f_win += 1;
        }
        if (s_scores[0] - s_handi < f_scores[0] - f_handi) {
          s_win += 1;
          f_lose += 1;
        } else if (s_scores[0] - s_handi > f_scores[0] - f_handi) {
          s_lose += 1;
          f_win += 1;
        }
        if (s_scores[2] - s_handi*2 < f_scores[2] - f_handi*2) {
          s_win += 1;
          f_lose += 1;
        } else if (s_scores[2] - s_handi*2 > f_scores[2] - f_handi*2) {
          s_lose += 1;
          f_win += 1;
        }
      }
      return [s_win, f_win];
    },
    getWinCount(result_Record, self) {
      let judges = [];
      if (self) {
        judges = [1,2,3];
      }
      else {
        judges = [4,5,6];
      }
      let count = 0;
      for (let i = 0; i < judges.length; i++) {
        if (result_Record.judge.indexOf(judges[i]) != -1) {
          count++;
        }
      }
      return count;
    },
    onScoreChange(user) {  // 3勝制の場合、前後半に変更を加えると合計が自動で変更される
      this.s_out = (this.s_out) ? ((this.s_out >= 0) ? this.s_out : -this.s_out) : 0;
      this.s_in = (this.s_in) ? ((this.s_in >= 0) ? this.s_in : -this.s_in) : 0;
      this.f_out = (this.f_out) ? ((this.f_out >= 0) ? this.f_out : -this.f_out) : 0;
      this.f_in = (this.f_in) ? ((this.f_in >= 0) ? this.f_in : -this.f_in) : 0;
      this.s_out = parseInt(this.s_out);
      this.s_in = parseInt(this.s_in);
      this.f_out = parseInt(this.f_out);
      this.f_in = parseInt(this.f_in);
      if (!user) {
        this.s_total = parseInt(this.s_out) + parseInt(this.s_in);
        if (this.s_total < 0) {
          this.s_total < 0
        }
      }
      else {
        this.f_total = parseInt(this.f_out) + parseInt(this.f_in);
        if (this.f_total < 0) {
          this.f_total < 0
        }
      }
    },
    searchFriend: async function (){	// フレンド検索
      this.loading = true;
      // IDが入力されているか
      if (this.username) {
        // 入力されたIDが自分自身のものではないか
        if (this.username != store.state.user.username) {
          // 該当のユーザーとのフレンドレコードが既に存在しないか
          const fri_rec = await this.getFriendRecordAll(store.state.user.username);
          const hit_rec = _.filter(fri_rec, (item) => {return item.FriendUserID == this.username && item.ShinseiKBN != 9});
          if (hit_rec.length == 0) {
            const nN = await API.graphql(graphqlOperation(getNickName, {HashKey: this.username, RangeKey: "MST"}));
            if (nN.data.getGolfHandicapTableRenewal) {
              // ヒットしたら表示
              this.friendUser = nN.data.getGolfHandicapTableRenewal;
              this.page = "request";
            } else {
              alert("該当のユーザーは存在しません。")
            }
          }
          else {
            if (hit_rec[0].ShinseiKBN == 0) {
              this.openResultList(hit_rec[0]);
            }
            else {
              alert("該当のユーザーには既にフレンド申請済みです。")
            }
          }
        } else {
          alert("他のユーザーのIDを入力してください。")
        }
      } else {
        alert("ユーザーIDを入力してください。")
      }
      this.loading = false;
    },
    friendRequest: async function() {	// フレンド申請
      this.loading = true;
      // 該当するユーザーとのフレンドレコードが存在するか確認
      const friendData = await this.getRecordOfIndex(store.state.user.username);
      const matchFRec = _.filter(friendData, (item) => {return item.FriendID == this.friendUser.ID});
      
      let kNum = 0;
      // 存在しない場合は採番用レコードを確認
      if (matchFRec.length == 0) {
        // 組み合わせIDの採番用レコードを確認
        const kNumData = await API.graphql(graphqlOperation(getSerialNumber, {ID: "Numbering", DataType: "KumiawaseID"}));
        kNum = kNumData.data.getGolfHandicap.DataValue;
        // 自分のフレンドレコードを作成
        let handicap = -this.handicap;
        await this.createFriendData(store.state.user.username, this.username, handicap, "K01" + kNum, true);
        // 相手のフレンドレコードを作成
        handicap = this.handicap;
        await this.createFriendData(this.friendUser.ID, store.state.user.username, handicap, "K01" + kNum, false);
        // 採番用レコードを更新
        const new_kNum = Number(parseInt(kNum, 16) + 1).toString(16).padStart(8, '0');
        const input_data = {
          ID: "Numbering",
          DataType: "KumiawaseID",
          DataValue: new_kNum
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
      } else {  // 存在する場合は既存のレコードの組み合わせIDと紐づけ
        kNum = matchFRec[0].DataValue;
        let handicap = -this.handicap;
        await this.updateFriendRecordRequest(store.state.user.username, this.friendUser.ID, handicap, 1, this.rule);
        handicap = this.handicap;
        await this.updateFriendRecordRequest(this.friendUser.ID, store.state.user.username, handicap, 2, this.rule);
      }
      this.loading = false;
      this.page = "";
    },
    createFriendData: async function(userID, friendID, handicap, kNum, self) {
      // 組み合わせの採番用レコードを確認
      const sNumData = await API.graphql(graphqlOperation(getSerialNumber, {ID: userID, DataType: "KumiawaseSN"}));
      let sNum = "00000"
      // 採番用レコードが無い場合は作成
      if (sNumData.data.getGolfHandicap) {
        sNum = sNumData.data.getGolfHandicap.DataValue;
      } else {
        const sNumRec_Data = {
          ID: userID,
          DataType: "KumiawaseSN",
          DataValue: sNum
        }
        await API.graphql(graphqlOperation(createSerialNumberData, {input: sNumRec_Data}));
      }
      let hc_value = 1;
      let rule = this.rule;
      hc_value = this.hc_Value;
      if (this.handi == "nothing") {
        hc_value = 0;
        rule -= 1;
      }
      let shinseiKBN = 1;
      if (!self) {
        shinseiKBN = 2;
      }
      // 格納用のデータ
      const fri_Data = {
        ID: userID,
        DataType: "KumiawaseID" + sNum,
        DataValue: kNum,
        FriendID: friendID,
        FriendName: await this.getNickName(friendID),
        Handicap: handicap,
        HC_Value: hc_value,
        Rule: rule,
        Win: 0,
        Lose: 0,
        Drow: 0,
        ShinseiKBN: shinseiKBN
      }
      await API.graphql(graphqlOperation(createFriendData, {input: fri_Data}));
      let new_sNum = (parseInt(sNum) + 1).toString().padStart(5, '0');
      const input_data = {
        ID: userID,
        DataType: "KumiawaseSN",
        DataValue: new_sNum
      }
      await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
    },
    updateFriendRecordRequest: async function (self_id, friend_id, handi, shinseiKBN, rule) {  // 申請フラグ以外も更新
      const friRec = await this.getFriendRecord(self_id);
      const matchRec = _.filter(friRec, (item) => {return item.FriendID == friend_id});
      for (let i = 0; i < matchRec.length; i++) {
        let hc_value = 1;
        hc_value = this.hc_Value;
        const input_data = {
          ID: self_id,
          DataType: matchRec[i].DataType,
          Handicap: handi,
          HC_Value: hc_value,
          Rule: rule,
          ShinseiKBN: shinseiKBN
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
      }
    },
    deleteFriend: async function(id) {  // フレンド削除
      const friend_name = await this.getNickName(id);
      if (confirm(friend_name + " さんのフレンド登録を削除します。\nよろしいですか？")) {
        this.loading = true;
        try {
          await this.deleteFriendRecord(store.state.user.username, id);
          await this.deleteFriendRecord(id, store.state.user.username);
          await this.getMyFriendRecord();
          this.pageMove("");
        } catch (err) {
          console.log(err);
        }
        this.loading = false;
      }
    },
    deleteFriendRecord: async function(self_id, friend_id) {  // 自分と相手のIDから組み合わせレコード削除
      const friRec = await this.getFriendRecord(self_id);
      const matchRec = _.filter(friRec, (item) => {return item.FriendID == friend_id});
      for (let i = 0; i < matchRec.length; i++) {
        const input_data = {
          ID: self_id,
          DataType: matchRec[i].DataType,
          ShinseiKBN: 9
        }
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
      }
    },
    fixRequest: async function(record) {  // 組み合わせレコード更新
      this.loading = true;
      try {
        let handicap = -this.handicap;
        await this.fixFriendRecord(store.state.user.username, record.FriendID, handicap, this.hc_Value, this.rule);
        handicap = this.handicap;
        await this.fixFriendRecord(record.FriendID, store.state.user.username, handicap, this.hc_Value, this.rule);
        await this.getMyFriendRecord();
        this.pageMove("");
      } catch (err) {
          console.log(err);
      }
      this.loading = false;
    },
    fixFriendRecord: async function (user_id, friend_id, handi, hc_Value, rule) {
      const friRec = await this.getFriendRecord(user_id);
      const matchRec = _.filter(friRec, (item) => {return item.FriendID == friend_id});
      console.log(matchRec)
      for (let i = 0; i < matchRec.length; i++) {
        const input_data = {
          ID: user_id,
          DataType: matchRec[i].DataType,
          Handicap: handi,
          HC_Value: hc_Value,
          Rule: rule
        }
        console.log(input_data);
        await API.graphql(graphqlOperation(updateGolfHandicap, {input: input_data}));
      }
    },
    getDateString () {
      const date = new Date();
      const date_str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getHours() + "-" + date.getMinutes();
      return date_str;
    },
    options(limitValue) {  // プルダウン用
      let options = {};
      
      for (let i = 0; i < limitValue; i++) {
        options[i] = i.toString().padStart(1, '0');
      }
      return options;
    },
    optionsDate(minValue, maxValue, digit) {  // プルダウン用
      let options = {};
      
      for (let i = minValue; i <= maxValue; i++) {
        options[i] = i.toString().padStart(digit, '0');
      }
      return options;
    },
    getFilteredRecord() {
      return _.orderBy(_.filter(this.result_data, (item) => {return (parseInt(item.DataValue) >= parseInt(this.year.toString().padStart(4, '0') + this.month.toString().padStart(2, '0') + "00")) && (parseInt(item.DataValue) <= parseInt(this.year.toString().padStart(4, '0') + this.month.toString().padStart(2, '0') + "31"))}), "DataValue");
    },
    options(minValue, maxValue, digit) {  // プルダウン用
      let options = {};
      
      for (let i = minValue; i <= maxValue; i++) {
        options[i] = i.toString().padStart(digit, '0');
      }
      return options;
    },
    optionsHC(minValue, maxValue) {  // プルダウン用
      let options = {};
      let n = 0;
      for (let i = minValue; i < maxValue; i+=0.5) {
        options[n] = {
          id: n,
          value: i.toFixed(1).toString().padStart(4, '0')
        }
        n++;
      }
      return options;
    },
    confirmHamburger: function (menu) {
      switch(menu) {
        case "Date":   this.year = this.selected_year;
                       this.month = this.selected_month;
                       this.day = this.selected_day;
                       break;
        case "Golf":   this.golf_course = this.selected_gc;
                       if (this.selected_gc.GolfCourseYomigana == "そのた") {
                         this.golf_course.GolfCourseName = this.input_gc;
                       }
                       break;
      }
      this.hamburger[menu] = !this.hamburger[menu];
    },
    switchHamburger: function (menu) {
      this.hamburger[menu] = !this.hamburger[menu];
    },
    handleResize: function() {
      if (document.body.clientWidth <= 750) {
        this.vertical_layout = true;
      }
      else {
        this.vertical_layout = false;
      }
    },
    getWindowWidth() {
      return (document.body.clientWidth+20).toString(10);
    },
    resizedFontSize: function(text, maxSize, minCount, unitMinus) {
      let size = maxSize;
      let count = this.getStringLength(text);
      if (count > minCount)
      {
        size *= unitMinus ** (count - minCount);
      }
      
      let unit = "vw";
      if (this.windowWidth >= 750)
      {
        size *= 7.5;
        unit = "px"
      }
      
      console.log(size + unit);
      
      return size + unit;
    },
    updateWindowSize() {
      this.windowWidth = window.innerWidth;
    },
    getStringLength(str) {
      let count = 0;
      for (let i = 0; i < str.length; i++)
      {
        let charCode = str.charCodeAt(i);
        if (charCode >= 0x00 && charCode <= 0xFF) {
          count += 1;
        }
        else
        {
          count += 2;
        }
      }
      return count;
    }
  }
}
</script>

<style scoped>
  @import '../styles/result.css';
</style>
