<template>
  <div>
    <div v-if="loading">読み込み中...</div>
    <div class="friends_caption">
      <p><img src="@/assets/renewal/friend.png"></p>
      <h2 class="title_fonts">フレンド情報</h2>
    </div>
    <div class="friend_detail_info">
      <h1 class="title_fonts">{{ friend_Record.FriendNickName }}</h1>
      <h4 class="main_fonts">ユーザーID：{{ friend_Record.FriendUserID }}</h4>
      <div class="main_fonts">
        <p><span class="left_align">ＨＣ：</span><span class="half_chara left_align">{{ (friend_Record.Handicap == 0)? "0.0" : (((friend_Record.Handicap > 0) ? "-" : "")) }}{{ (friend_Record.Handicap == 0)? "" : (Math.abs(friend_Record.Handicap).toFixed(1)) }}/{{ (friend_Record.Rule == 0 || friend_Record.Rule == 1)?"18":"9" }}H</span></p>
        <p></p>
        <p><span class="right_align">増減：</span><span class="half_chara right_align">{{ friend_Record.HC_Value.toFixed(1) }}/{{ (friend_Record.Rule == 0 || friend_Record.Rule == 1)?"18":"9" }}H</span></p>
      </div>
    </div>
    <div class="friend_detail_relation main_fonts">
      <h3 v-if="rate >= 60">
        <img src="@/assets/renewal/rate_inferior_b.png"> お得意様
      </h3>
      <h3 v-else-if="40 >= rate">
        <img src="@/assets/renewal/rate_superior_b.png"> 強敵
      </h3>
      <h3 v-else>
        <img src="@/assets/renewal/rate_rival_b.png"> ライバル
      </h3>
      <div v-on:mouseup="pageMoveFriendEdit()">
        <img src="@/assets/renewal/edit.png">
        <p class="buttonicon_fonts">ルール</p>
      </div>
    </div>
    <div class="gray_line"></div>
    <div class="results_total main_fonts">
      <p class="results_total_label">全体の戦績</p>
      <div>
        <div class="results_total_counts">
          <p class="results_total_counts_count left_align">{{ friend_Record.Matches }} 戦</p>
          <p class="results_total_counts_win">{{ friend_Record.Win }} 勝</p>
          <p class="results_total_counts_lose">{{ friend_Record.Lose }} 敗</p>
          <p class="results_total_counts_draw right_align">{{ friend_Record.Draw }} 分</p>
        </div>
        <div>
          <p class="results_total_counts_rate_label left_align">勝率：</p>
          <p class="results_total_counts_rate_value half_chara" v-if="friend_Record.Win + friend_Record.Lose > 0">{{ (friend_Record.Win / (friend_Record.Win + friend_Record.Lose) * 100).toFixed(1) }}%</p>
          <p class="results_total_counts_rate_value half_chara" v-else>-</p>
          <p class="results_total_counts_sum_label">勝点：</p>
          <p class="results_total_counts_sum_value half_chara right_align">{{ friend_Record.Points }} pt</p>
        </div>
      </div>
    </div>
    <div class="results_total main_fonts" v-if="current_year == selected_year">
      <p class="results_total_label select_wrapper">
        <select class="main_fonts" v-model="selected_year" @change="openResultListYear(friend_Record, selected_year)">
          <option :value="current_year" v-text="current_year + '年戦績'"></option>
          <option v-for="result in result_year" :value="result.SensekiID.slice(3,7)" v-text="result.SensekiID.slice(3,7) + '年戦績'" v-bind:key="result.SensekiID"></option>
        </select>
      </p>
      <div>
        <div class="results_total_counts">
          <p class="results_total_counts_count left_align">{{ friend_Record.MatchesThisYear }} 戦</p>
          <p class="results_total_counts_win">{{ friend_Record.WinThisYear }} 勝</p>
          <p class="results_total_counts_lose">{{ friend_Record.LoseThisYear }} 敗</p>
          <p class="results_total_counts_draw right_align">{{ friend_Record.DrawThisYear }} 分</p>
        </div>
        <div>
          <p class="results_total_counts_rate_label left_align">勝率：</p>
          <p class="results_total_counts_rate_value half_chara" v-if="friend_Record.WinThisYear + friend_Record.LoseThisYear > 0">{{ (friend_Record.WinThisYear / (friend_Record.WinThisYear + friend_Record.LoseThisYear) * 100).toFixed(1) }}%</p>
          <p class="results_total_counts_rate_value half_chara" v-else>-</p>
          <p class="results_total_counts_sum_label">勝点：</p>
          <p class="results_total_counts_sum_value half_chara right_align">{{ friend_Record.PointsThisYear }} pt</p>
        </div>
      </div>
    </div>
    <div class="results_total main_fonts" v-else>
      <p class="results_total_label select_wrapper">
        <select class="main_fonts" v-model="selected_year" @change="openResultListYear(friend_Record, selected_year)">
          <option :value="current_year" v-text="current_year + '年戦績'"></option>
          <option v-for="result in result_year" :value="result.SensekiID.slice(3,7)" v-text="result.SensekiID.slice(3,7) + '年戦績'" v-bind:key="result.SensekiID"></option>
        </select>
      </p>
      <div>
        <div class="results_total_counts">
          <p class="results_total_counts_count left_align">{{ selected_year_result.Matches }} 戦</p>
          <p class="results_total_counts_win">{{ (selected_year_result.Player1UserID == userid) ? selected_year_result.Player1Win : selected_year_result.Player2Win }} 勝</p>
          <p class="results_total_counts_lose">{{ (selected_year_result.Player1UserID == userid) ? selected_year_result.Player1Lose : selected_year_result.Player2Lose }} 敗</p>
          <p class="results_total_counts_draw">{{ selected_year_result.Draw }} 分</p>
        </div>
        <div>
          <p class="results_total_counts_rate_label left_align">勝率：</p>
          <p class="results_total_counts_rate_value half_chara" v-if="selected_year_result.Player1Win + selected_year_result.Player1Lose > 0">{{ (((selected_year_result.Player1UserID == userid) ? selected_year_result.Player1Win : selected_year_result.Player2Win) / (selected_year_result.Player1Win + selected_year_result.Player1Lose) * 100).toFixed(1) }}%</p>
          <p class="results_total_counts_rate_value half_chara" v-else>-</p>
          <p class="results_total_counts_sum_label">勝点：</p>
          <p class="results_total_counts_sum_value half_chara right_align">{{ (selected_year_result.Player1UserID == userid) ? selected_year_result.Player1Points : selected_year_result.Player2Points }} pt</p>
        </div>
      </div>
    </div>
    <div class="gray_line"></div>
  <!--
    <div class="result_list_column main_fonts">
      <h4 class="result_list_date">日付</h4>
      <h4 class="result_list_course">ゴルフ場</h4>
      <h4 class="result_list_score_s">自スコア</h4>
      <h4 class="result_list_score_r">相手スコア</h4>
      <h4 class="result_list_handicap">HC</h4>
      <h4 class="result_list_judge">勝敗</h4>
    </div>
  -->
    <div class="result_list_line main_fonts" v-for="(result, TaisenID) in result_data" v-bind:key="TaisenID" v-on:mouseup="openResultDetail(result)">
      <div class="result_list_line_line">
        <h4 class="result_list_judge left_align half_chara" v-if="result.Result == 'Draw'">Draw</h4>
        <h4 class="result_list_judge win left_align half_chara" v-else-if="result.Result == 'Win'">Win</h4>
        <h4 class="result_list_judge lose left_align half_chara" v-else>Lose</h4>
        <h4 class="result_list_date half_chara">{{ result.Date.slice(5,7) }}/{{ result.Date.slice(8,10) }}</h4>
        <h4 class="result_list_course" :style="{ fontSize: resizedFontSize((result) ? result.GolfCourseName : '', 4.5, 21, 0.95)}">{{ result.GolfCourseName }}</h4>
      </div>
      <div class="result_list_line_line">
        <h4 class="result_list_score_s left_align">{{ result.MyTotalScore - ((result.Handicap>0)?result.Handicap:0)*((result.Rule >= 2) ? 2 : 1) }}{{ (0>=result.Handicap)?"":"(" + result.MyTotalScore + ")" }}</h4>
        <h4 class="result_list_vs">vs</h4>
        <h4 class="result_list_score_r">{{ result.FriendsTotalScore - ((0>result.Handicap)?-result.Handicap:0)*((result.Rule >= 2) ? 2 : 1) }}{{ (result.Handicap>=0)?"":"(" + result.FriendsTotalScore + ")" }}</h4>
        <h4></h4>
        <h4 class="result_list_handicap"><span class="result_list_handicap_label">HC:</span><span class="right_align">{{ (result.Handicap == 0)? "0.0" : ((result.Handicap > 0) ? "-" : "") }}{{ (result.Handicap == 0)? "" : (Math.abs(result.Handicap).toFixed(1)) }}/{{ (result.Rule == 0 || result.Rule == 1)?"18":"9" }}H</span></h4>
      </div>
    </div>
    <div class="result_friend_list_more button_fonts" v-on:mouseup="addResultList(friend_Record)" v-if="nextToken_result">
      <p>さらに表示</p>
    </div>
    <button class="result_back_button button_fonts" v-on:mouseup="pageMove('')">戻る</button>
    <button class="result_button_gray button_fonts" v-on:mouseup="pageMove('friend_delete', friend_Record)" v-bind:disabled="loading">フレンドを削除</button>
    <div class="bottom_padding"></div>
    <div class="advertise_space"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { API, graphqlOperation} from "aws-amplify"  // AppSyncとの通信用API

import { listKumiawaseRecord } from "@/graphql/queries"	// 組み合わせのレコードを取得するquery

import _ from 'lodash'  // 配列を扱うモジュール

import router from '@/router/index.js'  // ルーター
import store from '@/store/index.js'  // ストア

export default {
  name: 'Result_List',
  props: ['friend_Record', 'result_data', 'nextToken_result'],
  data() {
    return {
      userid: "",
      current_year: "2022",
      selected_year: "2022",
      rate: 0,
      result_year: [],
      selected_year_result: null,
      loading: false,
      windowWidth: window.innerWidth,
    }
  },
  mounted: async function () {
    this.userid = store.state.user.username;
    if (this.friend_Record.MatchesThisYear > 0) {
      this.rate = this.friend_Record.WinThisYear / (this.friend_Record.WinThisYear + this.friend_Record.LoseThisYear) * 100
    }
    else {
      this.rate = 50
    }
    const date = new Date();
    this.current_year = date.getFullYear();
    this.selected_year = date.getFullYear();
    const result = await this.getResultYear();
    console.log(result);
    this.result_year = _.filter(result, (item) => {return item.SensekiID.slice(3,7) != this.current_year});
    window.addEventListener('resize', this.updateWindowSize);
  },
  methods: {
    pageMove: function(pagename) {
      this.$emit('pageMove', pagename);
    },
    addResultList : function(friend) {
      this.$emit('addResultList', friend);
    },
    openResultListYear : function(friend, year) {
      this.$emit('openResultListYear', friend, year);
      this.selected_year_result = _.filter(this.result_year, (item) => {return item.SensekiID.slice(3,7) == this.selected_year})[0];
      console.log(this.selected_year_result);
    },
    addResultListYear : function(friend, year) {
      this.$emit('addResultListYear', friend, year);
    },
    openResultDetail : function(result) {
      this.$emit('openResultDetail', result);
    },
    pageMoveFriendEdit : function() {
      this.$emit('pageMoveFriendEdit');
    },
    getResultYear: async function () {
      let result = null;
      let recordsArr = null;
      let nextToken = "";
      const searchData = {
        HashKey: {
          eq: this.friend_Record.KumiawaseID,  // 完全一致
        },
        RangeKey: {
          beginsWith: "S01"  // 前方一致
        }
      }
      try {
        do {
          if (nextToken == "") {
            result = await API.graphql(graphqlOperation(listKumiawaseRecord, {query: searchData}));
            recordsArr = result.data.listGolfHandicapTableRenewalsReverse.items;
          }
          else {
            result = await API.graphql(graphqlOperation(listKumiawaseRecord, {query: searchData, nextToken: nextToken}));
            recordsArr = recordsArr.concat(result.data.listGolfHandicapTableRenewalsReverse.items);
          }
          nextToken = result.data.listGolfHandicapTableRenewalsReverse.nextToken;
        } while (nextToken != null);
      }catch(err) {
        console.log(err);
      }
      return recordsArr;
    },
    resizedFontSize: function(text, maxSize, minCount, unitMinus) {
      let size = maxSize;
      let count = this.getStringLength(text);
      if (count > minCount)
      {
        size *= unitMinus ** (count - minCount);
      }
      
      size *= 1.375 ** (((count - minCount) - ((count - minCount) % 10))/10);
      
      let unit = "vw";
      if (this.windowWidth >= 750)
      {
        size *= 7.5;
        unit = "px"
      }
      
      console.log(size + unit);
      
      return size + unit;
    },
    updateWindowSize() {
      this.windowWidth = window.innerWidth;
    },
    getStringLength(str) {
      let count = 0;
      for (let i = 0; i < str.length; i++)
      {
        let charCode = str.charCodeAt(i);
        if (charCode >= 0x00 && charCode <= 0xFF) {
          count += 1;
        }
        else
        {
          count += 2;
        }
      }
      return count;
    }
  }
}
</script>