import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    userDB: null,
    nickname: "",
    email: "",
    friend: null,
    taisen: null,
    path: "",
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setUserDB(state, userDB) {
      state.userDB = userDB;
    },
    setNickname(state, nickname) {
      state.nickname = nickname;
    },
    setMailAdress(state, email) {
      state.email = email;
    },
    setFriend(state, friend) {
      state.friend = friend;
    },
    setTaisen(state, taisen) {
      state.taisen = taisen;
    },
    setPath(state, path) {
      state.path = path;
    },
  },
  actions: {
  },
  modules: {
  }
})
