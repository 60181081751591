/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


// 開発環境
const develop = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:d922d1f5-32c8-46ca-af5b-af6be7ab3b17",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_MRnduPVpf",
    "aws_user_pools_web_client_id": "6v1pncvl7gjp8fgevu84teadbp",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://kcgbv2qe2bakbjh3ibtdsbxicq.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

// 本番環境
const product = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:799d9986-7f8c-4990-b79d-02df5421d0f2",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_1rLZ0Sh2J",
    "aws_user_pools_web_client_id": "28eb9v8210bbmsfmeneopvlhml",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://uh4sletxfvdhbfyaoolmkbf66e.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

//const awsmobile = develop;
const awsmobile = product;

export default awsmobile;

